import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Container } from "react-bootstrap";

import styles from "./Indicadores.module.css";
import { LateralMenu } from "./components/LateralMenu";
import { ListaIndicadores } from "./components/ListaIndicadores";
import { ordenarArrayLibros } from "../../../../helpers/ordenarArray";
import { getCollectionData } from "../../../../helpers/getCollectionData";
import { Paginacion } from "../../../shared/Paginacion/Paginacion";
import { aleatorizarArray } from "../../../../helpers/aleatorizarArray";
import { useWindowsSize } from "../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../types/WindowSizeType";
import { ordenamientos } from "../../../../types/ordenamientos";
import { useTitle } from "../../../../hooks/useTitle";
import usePageTracking from "../../../../hooks/usePageTracking";
import { Rutas } from "../../../../routes/Routes";

export const Indicadores = () => {
	const [indicadoresState, setIndicadoresState] = useState([]);
	const [ordenamientoSeleccionado, setOrdenamientoSeleccionado] = useState(
		ordenamientos.MasReciente
	);
	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadIndicadoresAMostrar, setCantidadIndicadoresAMostrar] =
		useState(9);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [mostrarMasActivado, setMostrarMasActivado] = useState(false);
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		OrdenLabel: "",
		MostrarMasLabel: "",
		TextoResumen: "",
	});

	useTitle(datosPagina.TituloLabel);
	usePageTracking();
	const currentSize = useWindowsSize();

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { TituloLabel, OrderLabel, MostrarMasLabel, TextoResumen } = (
					await getCollectionData("pagina-indicadors", "", estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					OrdenLabel: OrderLabel,
					MostrarMasLabel: MostrarMasLabel,
					TextoResumen: TextoResumen,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté

		const obtenerIndicadores = async () => {
			const ordenamiento = ordenarArrayLibros(ordenamientoSeleccionado);
			const qs = require("qs");
			const query = qs.stringify({
				sort: [ordenamiento],
				pagination: {
					page: paginaActual,
					pageSize: cantidadIndicadoresAMostrar,
				},
				populate: "Portada",
				fields: ["Fecha"],
			});
			const { data, paginas } = await getCollectionData(
				"indicadors",
				query,
				estadoIdioma
			);

			if (ordenamientoSeleccionado === ordenamientos.Aleatorio)
				setIndicadoresState(aleatorizarArray(data));
			else setIndicadoresState(data);

			if (!mostrarMasActivado) setCantidadPaginas(paginas);
		};
		obtenerIndicadores();
	}, [
		ordenamientoSeleccionado,
		paginaActual,
		cantidadIndicadoresAMostrar,
		estadoIdioma,
		mostrarMasActivado,
	]);

	const mostrarMasResultados = () => {
		setCantidadIndicadoresAMostrar(cantidadIndicadoresAMostrar + 9);
		setMostrarMasActivado(true);
	};

	const cambiarPaginaActual = (pagina) => {
		if (mostrarMasActivado) {
			setCantidadIndicadoresAMostrar(9);
			setMostrarMasActivado(false);
		}
		setPaginaActual(pagina);
	};

	return (
		<div className={`${styles.contenedor_general}`}>
			{currentSize === WindowSizeType.xs ? (
				<>
					<Container fluid='sm'>
						<div>
							<Link to='/' className='link-component '>
								Inicio
							</Link>
							{" > "}
							<Link to={Rutas.Indicadores} className='link-component'>
								{datosPagina.TituloLabel}
							</Link>
						</div>

						<h2 className='title-mobile'>{datosPagina.TituloLabel}</h2>
						<p className={`p-unstyled my-4 ${styles.texto_resumen}`}>
							{datosPagina.TextoResumen}
						</p>
						<hr className='hr-main-layout' />

						<LateralMenu
							setOrdenamiento={setOrdenamientoSeleccionado}
							subtitulo={datosPagina.OrdenLabel}
							AfichesLabel={datosPagina.AfichesLabel}
							PremioNacionalArtesania={datosPagina.PremioNacionalArtesania}
							tituloDropdown={datosPagina.TituloLabel}
							ordenamientoSeleccionado={ordenamientoSeleccionado}
						/>

						<ListaIndicadores indicadores={indicadoresState} />
					</Container>
					<Paginacion
						cantidadPaginas={cantidadPaginas}
						mostrarMasResultados={mostrarMasResultados}
						setPaginaActual={cambiarPaginaActual}
						paginaActual={paginaActual}
						mostrarMasLabel={datosPagina.MostrarMasLabel}
					/>
				</>
			) : (
				<Container fluid='sm'>
					<div>
						<Link to={Rutas.Home} className='link-component '>
							Inicio
						</Link>
						{" > "}
						<Link to={Rutas.Indicadores} className='link-component'>
							{datosPagina.TituloLabel}
						</Link>
					</div>

					<h2 className='title'>{datosPagina.TituloLabel}</h2>

					<div className={`wrapper ${styles.container}`}>
						<Col sm={3} className='lateral-menu'>
							<LateralMenu
								setOrdenamiento={setOrdenamientoSeleccionado}
								subtitulo={datosPagina.OrdenLabel}
								AfichesLabel={datosPagina.AfichesLabel}
								PremioNacionalArtesania={datosPagina.PremioNacionalArtesania}
								ordenamientoSeleccionado={ordenamientoSeleccionado}
							/>
						</Col>

						<Col sm={9} className='main-content'>
							<p className={`col-9 p-unstyled mb-4 ${styles.texto_resumen}`}>
								{datosPagina.TextoResumen}
							</p>

							<ListaIndicadores indicadores={indicadoresState} />
						</Col>
					</div>
					<Paginacion
						cantidadPaginas={cantidadPaginas}
						mostrarMasResultados={mostrarMasResultados}
						setPaginaActual={cambiarPaginaActual}
						paginaActual={paginaActual}
						mostrarMasLabel={datosPagina.MostrarMasLabel}
					/>
				</Container>
			)}
		</div>
	);
};
