import { getCollectionData } from "../../../../helpers/getCollectionData";

export const obtenerHistoriasPorCategoria = async (nombreCategoria = "", idioma) => {
	const qs = require("qs");
	const query = qs.stringify({
		sort: ["createdAt:desc"],
		filters: {
			Categoria: { $containsi: nombreCategoria },
		},
		populate: ["historias", "historias.Portada", "historias.Portada.Imagen"],
	});

	const { data } = await getCollectionData(
		"categorias-historias",
		query,
		idioma
	);

	return {
		data,
	};
};
