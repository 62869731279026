import React, { useState, useMemo, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "../Results.css";
import { Paginacion } from "../../shared/Paginacion/Paginacion";
import { getCollectionData } from "../../../helpers/getCollectionData";
import { ListaResultados } from "./ListaResultados";
import { useTitle } from "../../../hooks/useTitle";
import usePageTracking from "../../../hooks/usePageTracking";

export const BusquedaPorTag = () => {
	const { busqueda } = useParams();

	useTitle(`Busqueda - ${busqueda}`);
	usePageTracking();

	const [resultsState, setResultsState] = useState();

	const [cantidadResultados, setcantidadResultados] = useState(5);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [paginaActual, setPaginaActual] = useState(1);
	const [mostrarMasActivado, setMostrarMasActivado] = useState(false);

	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		MostrarMasLabel: "",
	});

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { TituloLabel, MostrarMasLabel } = (
					await getCollectionData(
						"pagina-resultados-busquedas",
						"",
						estadoIdioma,
						1
					)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					MostrarMasLabel: MostrarMasLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	const mostrarMasResultados = () => {
		setcantidadResultados(cantidadResultados + 5);
		setMostrarMasActivado(true);
	};

	const cambiarPaginaActual = (pagina) => {
		if (mostrarMasActivado) {
			setcantidadResultados(5);
			setMostrarMasActivado(false);
		}
		setPaginaActual(pagina);
		window.scrollTo(0, 0);
	};

	const procesarTextoBusqueda = (texto = "") => {
		return texto.length > 20 ? texto.substring(0, 20).concat("...") : texto;
	};

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté
		let isActive = true;

		if (isActive) {
			const obtenerResultados = async () => {
				const qs = require("qs");
				const query = qs.stringify({
					sort: ["createdAt:desc"],
					pagination: {
						page: 1,
						pageSize: cantidadResultados,
					},
					filters: {
						Nombre: { $containsi: busqueda },
					},
					populate: [
						"artesanos",
						"historias",
						"noticias",
						"artesanos.Imagen",
						"artesanos.Imagen.Imagen",
						"historias.Portada.Imagen",
						"noticias.Portada.Imagen",
					],
				});
				const { data } = await getCollectionData("tags", query, estadoIdioma);
				setResultsState(data);
				const pags = Math.ceil(
					(Number(data[0].attributes.artesanos.data.length) +
						Number(data[0].attributes.historias.data.length) +
						Number(data[0].attributes.noticias.data.length)) /
						5
				);
				setCantidadPaginas(pags);
			};
			obtenerResultados();
		}
		return () => (isActive = false);
	}, [estadoIdioma, busqueda, cantidadResultados]);

	return (
		<>
			<Container fluid='sm' className='links-container'>
				<Link to='/' className='link-component'>
					Inicio
				</Link>
				{" > "}
				<Link to={`/resultadosPorTag/${busqueda}`} className='link-component'>
					Resultados
				</Link>{" "}
				{" > "}
				<Link to={`/resultadosPorTag/${busqueda}`} className='link-component'>
					"{procesarTextoBusqueda(busqueda)}"
				</Link>
				<div className='title'>
					<span>{datosPagina.TituloLabel}</span>
				</div>
				<hr className='hr-main-layout' />
				<div className='results'>
					<ListaResultados
						Results={resultsState}
						pagina={paginaActual}
						cantidadResultados={cantidadResultados}
					/>
				</div>
			</Container>
			<Paginacion
				mostrarMasResultados={mostrarMasResultados}
				setPaginaActual={cambiarPaginaActual}
				paginaActual={paginaActual}
				cantidadPaginas={cantidadPaginas}
				mostrarMasLabel={datosPagina.MostrarMasLabel}
			/>
		</>
	);
};
