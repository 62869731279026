export const aleatorizarArray = (array = []) => {
  fisherYatesShuffle(array);
  return array;
};

//Codigo extraido de https://www.delftstack.com/es/howto/javascript/shuffle-array-javascript/
function fisherYatesShuffle(arr) {
  for (var i = arr.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1)); //random index
    [arr[i], arr[j]] = [arr[j], arr[i]]; // swap
  }
}
