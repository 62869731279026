export const InstitucionalTypes = {
	ActaDeComision: "acta-de-comisiones",
	ArtesaniasALaCancha: "artesanias-a-la-canchas",
	CasasDeArtesania: "casas-de-artesanias",
	Dinapyme: "dinapymes",
	PlanEstrategico: "plan-estrategicos",
	PreguntasFrecuentes: "preguntas-frecuentes",
	FeriasYEventos: "ferias-y-eventos",
	Postulaciones: "postulaciones",
	RegistroNacional: "registro-nacionals",
	ParticipacionInternacional: "participacion-internacionals",
	AcercaDeAU: "acerca-de-aus",
};
