import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetchinstitucional = (institucion = "") => {
  const [state, setState] = useState({
    data: {},
    loading: true,
  });
  const base_url = `${process.env.REACT_APP_BASE_URL}`;
  const qs = require("qs");
  const query = qs.stringify({
    populate: "*",
  });

  const idiomaState = useSelector((state) => state.idioma);
  useEffect(() => {
    let isActive = true;
    if (isActive) {
      const obtener = async () => {
        const urlCompleta = `${base_url}/api/${institucion}?${query}&locale=${idiomaState.code}`;
        const resp = await fetch(urlCompleta);
        const dataRest = await resp.json();
        if (dataRest.data) setState({ data: dataRest.data, loading: false });
        else setState({ loading: true });
      };
      obtener();
    }
    return () => (isActive = false);
    // eslint-disable-next-line
  }, [idiomaState, institucion]);

  return state;
};
