import React, { useEffect } from "react";

import "./AficheGrande.css";

import closeBtn from "../../../../../assets/icons/close-button-black/close-button-black.svg";

const closeButtonImage = <img src={closeBtn} alt='botón cerrar' />;

export const AficheGrande = ({
	handleCerrarAfiche,
	aficheExpandido = false,
	portadaState,
}) => {
	const expandirAfiche = (flag) => {
		let ag = document.getElementById("aficheGrande");
		flag ? (ag.style.display = "block") : (ag.style.display = "none");
	};
	useEffect(() => {
		aficheExpandido ? expandirAfiche(true) : expandirAfiche(false);
	}, [aficheExpandido]);

	return (
		<div id='aficheGrande'>
			<div className='aficheGrandeShadow'></div>
			<button
				className='botones-pdf-grande boton-cerrar-pdf-grande'
				onClick={handleCerrarAfiche}
			>
				{closeButtonImage}
			</button>

			<img
				className='imagen-afiche-grande'
				src={`${process.env.REACT_APP_BASE_URL}${portadaState.url}`}
				alt='portada'
			/>
		</div>
	);
};
