import { useEffect, useState } from "react";
import { WindowSizeType } from "../types/WindowSizeType";

//Codigo extraido de https://usehooks.com/useWindowSize/
export const useWindowsSize = () => {
	const [windowSize, setWindowSize] = useState(undefined);

	useEffect(() => {
		const handleResize = () => {
			let size = "";
			if (window.innerWidth >= 992) size = WindowSizeType.lg;
			else if (window.innerWidth >= 768) size = WindowSizeType.md;
			else if (window.innerWidth >= 576) size = WindowSizeType.sm;
			else size = WindowSizeType.xs;
			setWindowSize(size);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};
