import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CheckDropdownItem } from "./CheckboxItem";
import { CheckboxMenu } from "./CheckboxMenu";
import "./CheckboxMenu.css";

export const CheckDropdownCategoriaArtesano = ({
	items = [],
	title = "",
	handleInputChange,
}) => {
	const handleChecked = (e, item) => {
		e.stopPropagation();
		handleInputChange(item);
	};

	const [flechaDropdown, setFlechaDropdown] = useState(false);

	return (
		<Dropdown onToggle={(nextToggle, e) => setFlechaDropdown(nextToggle)}>
			<Dropdown.Toggle className='todo-width'>
				<>
					<span>{title}</span>
					<span
						id='programasIcon'
						className={` ${
							flechaDropdown ? "" : "arrow-flip-drop"
						} flecha-algo`}
					>
						^
					</span>
				</>
			</Dropdown.Toggle>

			<Dropdown.Menu as={CheckboxMenu} className='col-6'>
				{items.map((categoria) => (
					<Dropdown.Item
						key={categoria.id}
						as={CheckDropdownItem}
						id={categoria.id}
						onClick={(e) => handleChecked(e, categoria)}
					>
						{categoria.attributes.Categoria}{" "}
						{`(${categoria.attributes.artesanos.data.length})`}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
