import React, { useEffect } from "react";
import { Carousel, Container } from "react-bootstrap";
import HeroControls from "./HeroControls";

export const HeroMobile = ({
	onPrevClick,
	onNextClick,
	ActiveIndex,
	handleSelect,
	destacadasState,
	navegarDestacada,
}) => {
	useEffect(() => {
		const intervalId = setInterval(() => {
			onNextClick();
		}, 3000); // Ajusta según tus preferencias

		return () => clearInterval(intervalId);
	}, [onNextClick]);

	return (
		<div className='heroDiv'>
			<Carousel
				activeIndex={ActiveIndex}
				onSelect={handleSelect}
				fade={false}
				slide={false}
				indicators={false}
				controls={false}
				style={{ height: "100dvh" }}
			>
				{destacadasState.map((destacada) => {
					const { Portada, Titulo, Subtitulo, PortadaMobile, demo } = destacada;

					const imagenPortada =
						PortadaMobile !== null ? PortadaMobile : Portada;

					const imageUrl = demo
						? imagenPortada.data.attributes.url
						: `${process.env.REACT_APP_BASE_URL}${imagenPortada.data.attributes.url}`;
					return (
						<Carousel.Item
							// interval={3000}
							onClick={() => navegarDestacada(destacada)}
							key={destacada.id}
							style={{ height: "100%" }}
						>
							<img
								className='hero-imagen-destacada'
								src={imageUrl}
								alt='Portada'
								style={{ width: "100%", height: "100%", objectFit: "cover" }}
							/>
							<Container fluid='sm'>
								<Carousel.Caption className='hero-contenedor-titulos-mobile'>
									<h1 className='carousel-title'>{Titulo} </h1>
									<h2 className='carousel-subtitle'>{Subtitulo}</h2>
								</Carousel.Caption>
							</Container>
						</Carousel.Item>
					);
				})}
			</Carousel>
			<div style={{ height: 0 }} className='container-sm'>
				<HeroControls
					handleClickPrev={onPrevClick}
					handleClickNext={onNextClick}
				/>
			</div>
		</div>
	);
};
