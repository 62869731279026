import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

import "./PerfilAfiche.css";
import { AficheGrande } from "./AficheGrande";
import { AficheChico } from "./AficheChico";
import { getCollectionData } from "../../../../../helpers/getCollectionData";
import { useTitle } from "../../../../../hooks/useTitle";
import usePageTracking from "../../../../../hooks/usePageTracking";
import { getAficheById } from "./helpers/getAficheById";

export const PerfilAfiche = () => {
	const { aficheId } = useParams();
	const [portadaState, setPortadaState] = useState("");
	const [descripcionState, setDescripcionState] = useState("");
	const [aficheExpandido, setAficheExpandido] = useState(false);
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		DescargarLabel: "",
		SobreAutorLabel: "",
	});
	const navigate = useNavigate();

	useTitle(datosPagina.TituloLabel);
	usePageTracking();

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { TituloLabel, DescargarLabel, SobreAutorLabel } = (
					await getCollectionData("pagina-perfil-afiches", "", estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					DescargarLabel: DescargarLabel,
					SobreAutorLabel: SobreAutorLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		const obtenerAfiche = async () => {
			const afiche = await getAficheById(aficheId, ["Portada"], estadoIdioma);
			if (afiche.otherLanguage) {
				navigate(
					`/programas/PremioNacionalArtesanias/Afiches/${Number(aficheId) + 1}`
				);
			} else if (afiche.data !== null) {
				setPortadaState(afiche.Portada?.data.attributes);
				setDescripcionState(afiche.Descripcion);
			} else {
				//Get last post on DB

				const navigateToWorkingPost = async () => {
					const getPostId = async ({ last = false }) => {
						const qs = require("qs");

						const query = qs.stringify({
							sort: [last ? "Fecha:desc" : "Fecha:asc"],
							pagination: {
								page: 1,
								pageSize: 1,
							},
							fields: ["id"],
						});
						const { data } = await getCollectionData(
							"afiches",
							query,
							estadoIdioma
						);

						return data[0].id;
					};

					const lastPostId = await getPostId({ last: true });

					if (aficheId < lastPostId) {
						navigate(
							`/programas/PremioNacionalArtesanias/Afiches/${lastPostId}`,
							{
								replace: true,
							}
						);
					} else {
						navigate(
							`/programas/PremioNacionalArtesanias/Afiches/${await getPostId({
								last: false,
							})}`,
							{
								replace: true,
							}
						);
					}
				};

				navigateToWorkingPost();
			}
		};
		obtenerAfiche();
		// eslint-disable-next-line
	}, [aficheId, estadoIdioma]);

	function previousPage() {
		navigate(
			`/programas/PremioNacionalArtesanias/Afiches/${Number(aficheId) - 1}`
		);
	}

	function nextPage() {
		navigate(
			`/programas/PremioNacionalArtesanias/Afiches/${Number(aficheId) + 1}`
		);
	}

	const descargarAfiche = () => {
		saveAs(
			`${process.env.REACT_APP_BASE_URL}${portadaState.url}`,

			`${portadaState.alternativeText}`
		);
	};

	const handleCerrarAfiche = () => {
		navigate("/programas/PremioNacionalArtesanias/Afiches");
	};

	return (
		<div style={{ zIndex: 1 }}>
			<AficheChico
				descargarAfiche={descargarAfiche}
				previousPage={previousPage}
				nextPage={nextPage}
				handleCerrarAfiche={handleCerrarAfiche}
				descripcionState={descripcionState}
				portadaState={portadaState}
				setAficheExpandido={setAficheExpandido}
				datosPagina={datosPagina}
			/>

			<AficheGrande
				aficheExpandido={aficheExpandido}
				previousPage={previousPage}
				nextPage={nextPage}
				handleCerrarAfiche={() => setAficheExpandido(false)}
				portadaState={portadaState}
			/>
		</div>
	);
};
