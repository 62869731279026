import React, { Suspense } from "react";
import { useLazyLoad } from "../../../hooks/useLazyLoad";

const SugerirNoticias = React.lazy(() => import("./SugerirNoticias"));

export const SugerirNoticiasLazy = ({ noticiaId, idioma }) => {
	const { isNear, elementRef } = useLazyLoad();
	return (
		<div ref={elementRef}>
			<Suspense fallback={null}>
				{isNear ? (
					<SugerirNoticias noticiaId={noticiaId} idioma={idioma} />
				) : null}
			</Suspense>
		</div>
	);
};
