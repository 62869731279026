import React from "react";

import { AficheItem } from "./AficheItem";

export const ListaAfiches = ({ afiches = [] }) => {
	return (
		<div className='row'>
			{afiches.map((afiche) => (
				<div className='col-lg-4 col-md-6 col-sm-6 col-6' key={afiche.id}>
					<AficheItem afiche={afiche} />
				</div>
			))}
		</div>
	);
};
