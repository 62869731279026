import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";

import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { VideoCard } from "../../shared/VideoPlayer/VideoCard";
import "./HistoriaItemView.css";

export const HistoriaItem = ({ historia = {} }) => {
	const {
		Autor_Autora,
		categorias_historia,
		Portada,
		Contenido,
		Descripcion,
		Fecha,
	} = historia?.attributes;

	let datoPortada = "";
	let esVideo = false;
	if (
		Portada.LinkVideo !== null &&
		Portada.LinkVideo !== undefined &&
		Portada.LinkVideo.trim() !== ""
	) {
		datoPortada = Portada.LinkVideo;
		esVideo = true;
	} else {
		datoPortada = `${process.env.REACT_APP_BASE_URL}${Portada.Imagen.data.attributes.url}`;
	}

	const navigate = useNavigate();
	const cortarContenido = (contenido = "") => {
		if (contenido.length > 50) return contenido.substring(0, 80).concat("...");
		return contenido;
	};

	const navegarHistoria = () => {
		window.scrollTo(0, 0);
		navigate(`/historias/${historia.id}`);
	};

	const currentSize = useWindowsSize();

	return (
		<div>
			{currentSize === WindowSizeType.xs ? (
				<Card className='historia-card'>
					{esVideo ? (
						<Card.Header className='video-historia'>
							<VideoCard className='video-historia' url={datoPortada} />
						</Card.Header>
					) : (
						<Card.Img
							src={datoPortada}
							className={`card-img-top ${
								Portada.Horizontal
									? "imagen-historia-horizontal-mobile"
									: "imagen-historia-vertical-mobile"
							}`}
							alt='...'
							onClick={navegarHistoria}
						/>
					)}

					<Card.Body className='historia-description' onClick={navegarHistoria}>
						<Card.Title className='titulo-card'>{Autor_Autora}</Card.Title>
						<Card.Text>
							<label className='texto-historia-view'>
								{cortarContenido(Descripcion ?? Contenido)}
							</label>
						</Card.Text>
						<Card.Text>
							{categorias_historia.data.slice(0, 2).map((categoria) => (
								<label key={categoria.id} className='categoria-historia'>
									{categoria.attributes.Categoria}
								</label>
							))}
							{Fecha ? (
								<label
									className='categoria-historia'
									key={`${historia.id} ${Fecha}`}
								>
									{moment(Fecha).format("DD-MM-YYYY")}
								</label>
							) : null}
						</Card.Text>
					</Card.Body>
				</Card>
			) : (
				<Card className='historia-card'>
					{esVideo ? (
						<Card.Header className='video-historia'>
							<VideoCard className='video-historia' url={datoPortada} />
						</Card.Header>
					) : (
						<Card.Img
							src={datoPortada}
							className={`card-img-top imagen-historia`}
							alt='...'
							onClick={navegarHistoria}
						/>
					)}

					<Card.Body className='historia-description'>
						<Card.Title className='titulo-card'>{Autor_Autora}</Card.Title>
						<Card.Text>
							<label className='texto-historia-view'>
								{cortarContenido(Descripcion ?? Contenido)}
							</label>
						</Card.Text>
						<Card.Text>
							{categorias_historia.data.map((categoria) => (
								<label key={categoria.id} className='categoria-historia'>
									<Link
										to={`/resultadosPorCategoria/${categoria.attributes.Categoria}`}
									>
										{categoria.attributes.Categoria}
									</Link>
								</label>
							))}
							{Fecha ? (
								<label
									className='categoria-historia'
									key={`${historia.id} ${Fecha}`}
								>
									{moment(Fecha).format("DD-MM-YYYY")}
								</label>
							) : null}
						</Card.Text>
					</Card.Body>
				</Card>
			)}
		</div>
	);
};
