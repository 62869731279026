import "../PerfilArtesano.css";

export const GaleriaArtesano = ({ Imagen = [] }) => {
	return (
		<div className='row'>
			{Imagen.map((img, i) => {
				return (
					<div
						className={`img-container-artesanos ${
							img.Horizontal
								? "col-lg-8 col-md-12 col-sm-12 col-12"
								: "col-lg-4 col-md-6 col-sm-6 col-6"
						}`}
						key={img.id}
					>
						<img
							alt={`Imagen ${i}`}
							src={`${process.env.REACT_APP_BASE_URL}${img.Imagen.data.attributes.url}`}
							className={`${
								img.Horizontal
									? "imagen-perfil-artesano-horizontal"
									: "imagen-perfil-artesano-vertical"
							}`}
						/>
					</div>
				);
			})}
		</div>
	);
};
