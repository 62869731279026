import React, { useState } from "react";
import { useEffect } from "react";
import search from "../../../assets/icons/search/search-black-thin.svg";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import "./SearchNoticias.css";

export const SearchNoticias = ({ setTituloNoticia, currentValue }) => {
	const currentSize = useWindowsSize();

	const preventSearchClose = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const [query, setQuery] = useState("");

	useEffect(() => {
		setQuery(currentValue);
	}, [currentValue]);

	const handleSearch = (searchString) => {
		setTituloNoticia(searchString);
	};

	const handleEnter = (e) => {
		if (e.key === "Enter") {
			handleSearch(query);
		}
	};

	return (
		<>
			{currentSize !== WindowSizeType.xs && (
				<div className='component-box col-12'>
					<div className='search-bar-container-noticias'>
						<div className='search-bar-noticias'>
							<div className='search-bar-icon-noticias'>
								<img
									src={search}
									alt='search icon'
									width='24px'
									height='24px'
									onClick={preventSearchClose}
								/>
							</div>
							<input
								className='search-input-noticias'
								name='search-input'
								onKeyDown={handleEnter}
								onClick={preventSearchClose}
								placeholder=''
								value={query}
								onChange={(event) => setQuery(event.target.value)}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
