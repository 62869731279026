import { ordenamientos } from "../types/ordenamientos";

export const ordenarArray = (ordenSeleccionado, campoAlfabetico = "") => {
  switch (ordenSeleccionado) {
    case ordenamientos.MenosRecientes:
      return "publishedAt:asc";
    case ordenamientos.MasReciente:
      return "publishedAt:desc";
    case ordenamientos.Alfabetico:
      return `${campoAlfabetico}:asc`;
    default:
      return "publishedAt:desc";
  }
};

export const obtenerOrdenamientoNoticias = (ordenSeleccionado, campoAlfabetico = "") => {
  switch (ordenSeleccionado) {
    case ordenamientos.MenosRecientes:
      return "Fecha:asc";
    case ordenamientos.MasReciente:
      return "Fecha:desc";
    case ordenamientos.Alfabetico:
      return `${campoAlfabetico}:asc`;
    default:
      return "Fecha:desc";
  }
};

export const ordenarArrayLibros = (ordenSeleccionado) => {
  switch (ordenSeleccionado) {
    case ordenamientos.MenosRecientes:
      return "Fecha:asc";
    case ordenamientos.MasReciente:
      return "Fecha:desc";
    default:
      return "Fecha:desc";
  }
};

export const ordenarArrayArtesanos = (ordenSeleccionado, array = []) => {
  switch (ordenSeleccionado) {
    case ordenamientos.MenosRecientes: {
      return array.sort((a, b) =>
        a.attributes.publishedAt.localeCompare(b.attributes.publishedAt)
      );
    }
    case ordenamientos.MasReciente:
      return array.sort((a, b) =>
        b.attributes.publishedAt.localeCompare(a.attributes.publishedAt)
      );
    case ordenamientos.Alfabetico:
      return array.sort((a, b) =>
        a.attributes.Nombre.localeCompare(b.attributes.Nombre)
      );
    default:
      return array;
  }
};
export const ordenarArrayHistorias = (ordenSeleccionado, array = []) => {
  switch (ordenSeleccionado) {
    case ordenamientos.MenosRecientes: {
      return array.sort((a, b) =>
        a.attributes.publishedAt.localeCompare(b.attributes.publishedAt)
      );
    }
    case ordenamientos.MasReciente:
      return array.sort((a, b) =>
        b.attributes.publishedAt.localeCompare(a.attributes.publishedAt)
      );
    case ordenamientos.Alfabetico:
      return array.sort((a, b) =>
        a.attributes.Titulo.localeCompare(b.attributes.Titulo)
      );
    default:
      return array;
  }
};
export const ordenarArrayNoticias = (ordenSeleccionado, array = []) => {
  switch (ordenSeleccionado) {
    case ordenamientos.MenosRecientes: {
      return array.sort((a, b) =>
        a.attributes.publishedAt.localeCompare(b.attributes.publishedAt)
      );
    }
    case ordenamientos.MasReciente:
      return array.sort((a, b) =>
        b.attributes.publishedAt.localeCompare(a.attributes.publishedAt)
      );
    case ordenamientos.Alfabetico:
      return array.sort((a, b) =>
        a.attributes.Titulo.localeCompare(b.attributes.Titulo)
      );
    default:
      return array;
  }
};
