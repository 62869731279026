import React, { useContext } from "react";

import { useWindowsSize } from "../../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../../types/WindowSizeType";
import { CategoriasSeleccionadoContext } from "../AfichesContext";
import { CheckboxDropdown } from "../components/CheckboxDropdown";
import { useFetchCategoriasArtesanos } from "../hooks/useFetchCategoriasArtesanos";

export const CategoriasAfiche = ({ subtitulo = "Filtrar por" }) => {
	const { categoriasSeleccionadas, setCategoriasSeleccionadas } = useContext(
		CategoriasSeleccionadoContext
	);

	const { data, loading } = useFetchCategoriasArtesanos();
	const windowSize = useWindowsSize();

	const handleInputChange = (e, cat) => {
		if (e.target.checked) {
			setCategoriasSeleccionadas([...categoriasSeleccionadas, cat.id]);
		} else {
			setCategoriasSeleccionadas(
				categoriasSeleccionadas.filter((cate) => cate !== cat.id)
			);
		}
	};
	return (
		<>
			{windowSize === WindowSizeType.xs ? (
				<div>
					<CheckboxDropdown
						items={data}
						title={subtitulo}
						handleInputChange={handleInputChange}
					/>
				</div>
			) : (
				<div>
					<h6 className='box-title'>{subtitulo}</h6>
					{loading ? (
						<p>Loading</p>
					) : (
						data?.map((categoria) => (
							<div key={categoria.id} className='mb-2'>
								<input
									type='checkbox'
									onClick={(e) => handleInputChange(e, categoria)}
								/>
								<label className='input-label'>
									{categoria.attributes.Categoria}
								</label>
							</div>
						))
					)}
				</div>
			)}
		</>
	);
};
