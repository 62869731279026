import React from "react";
import { Form } from "react-bootstrap";

export const SimpleItem = React.forwardRef(({ children, id, onClick }, ref) => {
	return (
		<Form.Group
			ref={ref}
			className='dropdown-item mb-2 texto-simple-mobile dropdown-item-simple-menu'
			controlId={id}
			onClick={onClick}
		>
			<label className='input-label'>{children}</label>
		</Form.Group>
	);
});
