import React, { useEffect, useState } from "react";

import "./ModalListaImagenes.css";
import styles from "./GaleriaModal.module.css";
import rightArrow from "../../../assets/icons/arrow-black-right/arrow-black-right.svg";
import leftArrow from "../../../assets/icons/arrow-black-left/arrow-black-left.svg";
import closeBtn from "../../../assets/icons/close-button-black/close-button-black.svg";

const nI = <img src={rightArrow} alt='...' />;
const pI = <img src={leftArrow} alt='...' />;
const closeButtonImage = <img src={closeBtn} alt='...' />;

export const ModalListaImagenes = ({
	mostrarModal = false,
	listaImagenes = [],
	setMostrarModal,
}) => {
	const expandirPdf = (flag) => {
		let pdf = document.getElementById("imagenTallerista");
		flag ? (pdf.style.display = "block") : (pdf.style.display = "none");
	};
	useEffect(() => {
		mostrarModal ? expandirPdf(true) : expandirPdf(false);
	}, [mostrarModal, listaImagenes]);

	const [indiceArrayImagen, setIndiceArrayImagen] = useState(0);

	const nextImagen = (e) => {
		setIndiceArrayImagen(indiceArrayImagen + 1);
	};

	const previousImagen = () => {
		setIndiceArrayImagen(indiceArrayImagen - 1);
	};

	const cerrarModal = () => {
		setMostrarModal({ mostrar: false, data: [] });
	};

	return (
		<div className='container-sm' id='imagenTallerista'>
			<div className='pdfGrandeShadow' onClick={cerrarModal}></div>
			<div className={`${styles["contenedor-general"]} row`}>
				{/* div flecha izquierda */}
				<div className={`col-1 ${styles["contenedor-botones"]}`}>
					<div className={`${styles["div-fantasma"]}`}></div>

					<button
						disabled={indiceArrayImagen <= 0}
						onClick={previousImagen}
						className='botones-imagen-tallerista '
					>
						{pI}
					</button>
					<div className={`${styles["div-fantasma"]}`}></div>
				</div>
				{/* div que contiene imagen  */}
				<div className={`col-10 fit-image`}>
					<div className={`${styles["contenedor-imagen-total"]}`}>
						<img
							className={`${
								listaImagenes[indiceArrayImagen].Horizontal
									? "imagen-tallerista-horizontal"
									: "imagen-tallerista-vertical"
							}`}
							src={`${process.env.REACT_APP_BASE_URL}${listaImagenes[indiceArrayImagen].Imagen.data.attributes.url}`}
							alt='...'
						/>
					</div>
				</div>
				{/* div que contiene flechas de cerrar y derecha  */}

				<div className={`col-1 ${styles["contenedor-botones"]} `}>
					<button className={`botones-imagen-tallerista`} onClick={cerrarModal}>
						{closeButtonImage}
					</button>
					<button
						disabled={indiceArrayImagen >= listaImagenes.length - 1}
						onClick={(event) => {
							event.stopPropagation();
							event.preventDefault();
							nextImagen(event);
						}}
						className={`botones-imagen-tallerista `}
					>
						{nI}
					</button>
					<div className={`${styles["div-fantasma"]}`}></div>
				</div>
			</div>
		</div>
	);
};
