import React from "react";
import { Card } from "react-bootstrap";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import "./ResultItem.css";

export const ResultadoItem = ({ resultado }) => {
	const navigate = useNavigate();

	return (
		<Card className='resultado-card' onClick={() => navigate(resultado.link)}>
			<div className='resultadoRow'>
				<div className='resultadoCol'>
					<Card.Img
						src={resultado.img}
						className='card-img-top imagen-resultado'
						alt='...'
					/>
				</div>
				<Card.Body className='resultado-description'>
					<div className='result-text-container'>
						<div>
							<Card.Title className='resultado-titulo-card'>
								{resultado.title}
							</Card.Title>

							<Card.Text className='texto-resultado'>
								{resultado.descripcion}
							</Card.Text>
						</div>
						<Card.Text className='fecha-resultado'>
							<label className='resultado-fecha'>
								{format(new Date(resultado.date), "d MMM yyyy")
									.split(" ")
									.slice(0, 3)
									.join(" ")}
							</label>
						</Card.Text>
					</div>
				</Card.Body>
			</div>
		</Card>
	);
};
