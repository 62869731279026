import { eliminaRepetidosArray } from "../../../../helpers/eliminaRepetidosArray";
import { obtenerArtesanosPorCategoria } from "../../BusquedaCatagoriasHome/helpers/obtenerArtesanosPorCategoria";
import { obtenerHistoriasPorCategoria } from "../../BusquedaCatagoriasHome/helpers/obtenerHistoriasPorCategoria";
import { obtenerNoticiasPorCategoria } from "../../BusquedaCatagoriasHome/helpers/obtenerNoticiasPorCategoria";
import { obtenerArtesanos } from "./obtenerArtesanos";
import { obtenerHistorias } from "./obtenerHistorias";
import { obtenerNoticias } from "./obtenerNoticias";

export const obtenerResultadosDeBusqueda = async (
  paginaActual,
  cantidadResultados,
  busqueda,
  idioma
) => {
  let dataArtesanosCompleto = [];
  let dataHistoriasCompleto = [];
  let dataNoticiasCompleto = [];

  // let paginaABuscar = 2;
  let paginaABuscar = paginaActual;
  let resultadosABuscar = cantidadResultados;

  //Busco los artesanos
  const { dataArtesanos, pageCountArtesanos } = await obtenerArtesanos(
    paginaABuscar,
    resultadosABuscar,
    busqueda,
    idioma
  );

  dataArtesanosCompleto = [...dataArtesanos];

  //Busco los artesanos por categorias
  if (dataArtesanos.length < resultadosABuscar) {
    resultadosABuscar = resultadosABuscar - dataArtesanos.length;
    paginaABuscar = paginaABuscar - pageCountArtesanos;

    const categoriasDeArtesano = await obtenerArtesanosPorCategoria(
      busqueda,
      idioma
    );
    const artesanoCategoria =
      categoriasDeArtesano.data.length !== 0
        ? categoriasDeArtesano.data[0].attributes.artesanos.data
        : [];

    const cantidadpaginasCategoriasArtesanos =
      Number(artesanoCategoria.length) / cantidadResultados;

    dataArtesanosCompleto = [...dataArtesanosCompleto, ...artesanoCategoria];

    //Busco Historias
    if (artesanoCategoria.length < resultadosABuscar) {
      resultadosABuscar = resultadosABuscar - artesanoCategoria.length;
      paginaABuscar = paginaABuscar - cantidadpaginasCategoriasArtesanos;
      //Si me falta seguir llenando la pagina, sigo buscando datos
      const {
        dataHistorias,
        pageCountHistorias,
      } = await obtenerHistorias(
        paginaABuscar,
        resultadosABuscar,
        busqueda,
        idioma
      );

      dataHistoriasCompleto = [...dataHistorias];

      //Busco historias por categorias
      if (dataHistorias.length < resultadosABuscar) {
        resultadosABuscar = resultadosABuscar - dataHistorias.length;
        paginaABuscar = paginaABuscar - pageCountHistorias;

        const categoriasDeHistoria = await obtenerHistoriasPorCategoria(
          busqueda,
          idioma
        );
        const historiaPorCategoria =
          categoriasDeHistoria.data.length !== 0
            ? categoriasDeHistoria.data[0].attributes.historias.data
            : [];

        const cantidadpaginasCategoriasHistorias =
          Number(historiaPorCategoria.length) / cantidadResultados;

        dataHistoriasCompleto = [
          ...dataHistoriasCompleto,
          ...historiaPorCategoria,
        ];

        //Busco noticias
        if (historiaPorCategoria.length < resultadosABuscar) {
          resultadosABuscar = resultadosABuscar - historiaPorCategoria.length;
          paginaABuscar = paginaABuscar - cantidadpaginasCategoriasHistorias;

          const {
            dataNoticias,
            pageCountNoticias,
          } = await obtenerNoticias(
            paginaABuscar,
            resultadosABuscar,
            busqueda,
            idioma
          );

          dataNoticiasCompleto = [...dataNoticias];

          //Busco noticias por categoria
          if (dataNoticias.length < resultadosABuscar) {
            resultadosABuscar = resultadosABuscar - dataNoticias.length;
            paginaABuscar = paginaABuscar - pageCountNoticias;

            const categoriasDeNoticias = await obtenerNoticiasPorCategoria(
              busqueda,
              idioma
            );

            const noticiasPorCategoria =
              categoriasDeNoticias.data.length !== 0
                ? categoriasDeNoticias.data[0].attributes.noticias.data
                : [];

            dataNoticiasCompleto = [
              ...dataNoticiasCompleto,
              ...noticiasPorCategoria,
            ];
          }
        }
      }
    }
  }

  dataArtesanosCompleto = eliminaRepetidosArray(dataArtesanosCompleto);
  dataHistoriasCompleto = eliminaRepetidosArray(dataHistoriasCompleto);
  dataNoticiasCompleto = eliminaRepetidosArray(dataNoticiasCompleto);
  return { dataArtesanosCompleto, dataHistoriasCompleto, dataNoticiasCompleto };
};
