export const getEntityById = async (
	collection,
	id,
	toPopulateData = [],
	idioma
) => {
	const base_url = `${process.env.REACT_APP_BASE_URL}`;
	const qs = require("qs");
	let localizations = obtenerQueryLocalizada(toPopulateData);

	const query = qs.stringify({
		populate: [...toPopulateData, ...localizations],
	});

	const urlCompleta = `${base_url}/api/${collection}/${id}?${query}`;
	const resp = await fetch(urlCompleta);
	const dataRest = await resp.json();

	if (idioma && dataRest.data) {
		if (
			dataRest.data.attributes.locale === idioma.code ||
			dataRest.data.attributes.localizations.data.length === 0
		) {
			const data = dataRest.data.attributes;
			return data;
		} else {
			const dataLocalizada = dataRest.data.attributes.localizations.data.find(
				(x) => x.attributes.locale === idioma.code
			);
			return dataLocalizada.attributes;
		}
	}

	return dataRest;
};

const obtenerQueryLocalizada = (toPopulateData = []) => {
	let localizations = ["localizations"];
	for (let index = 0; index < toPopulateData.length; index++) {
		const element = toPopulateData[index];
		localizations = [...localizations, `localizations.${element}`];
	}
	return localizations;
};
