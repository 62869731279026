import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import { agregarUrlAContenido } from "../../helpers/agregarUrlAContenido";
import { useWindowsSize } from "../../hooks/useWindowsSize";
import { WindowSizeType } from "../../types/WindowSizeType";
import { useFetchinstitucional } from "../../hooks/useFetchInstitucional";
import { useGetLabelsInstitucionales } from "../../hooks/useGetLabelsInstitucionales";
import { CarrouselLabels } from "../shared/CarrouselLabelInstitucional/CarrouselLabels";
import { useTitle } from "../../hooks/useTitle";
import usePageTracking from "../../hooks/usePageTracking";

export const SeccionProgramas = ({ tipoInstitucion = "" }) => {
	const location = useLocation();

	const { seccionesInstitucionales } = useGetLabelsInstitucionales();

	const currentSize = useWindowsSize();

	const { data, loading } = useFetchinstitucional(tipoInstitucion);

	const { Titulo, Contenido, Fondo } = loading ? {} : data[0].attributes;

	const direccionFondo = loading
		? ""
		: `${process.env.REACT_APP_BASE_URL}${Fondo.data.attributes.url}`;

	useTitle(Titulo ?? "");
	usePageTracking();

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='contendor-general-inst'>
					<div
						className='institucional-imagen-fondo-mobile'
						style={{ backgroundImage: `url(${direccionFondo})` }}
					></div>
					<Container fluid='sm'>
						<div className='contenido-seccion'>
							<div className='links-container links-institucionales'>
								<Link to='/' className='link-component links-institucionales'>
									Inicio
								</Link>
								{" > "}
								<Link
									to={`/programas/${location.pathname.split("/")[1]}`}
									className='link-component links-institucionales'
								>
									{Titulo}
								</Link>
							</div>

							<div>
								<h1 className='title-mobile titulo-institucional-mobile'>
									{Titulo}
								</h1>
								<CarrouselLabels listaLabel={seccionesInstitucionales} />

								<div className='wrapper institucional-row-mobile institucional-contenedor-texto-mobil'>
									<div>
										<div className='main-text-institucional'>
											<div className='markdown-general'>
												<ReactMarkdown linkTarget='_blank'>
													{agregarUrlAContenido(Contenido)}
												</ReactMarkdown>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
			) : (
				<div className='contendor-general-inst'>
					<div
						className='imagen-fondo'
						style={{ backgroundImage: `url(${direccionFondo})` }}
					></div>

					<div className='contenido-seccion'>
						<Container
							className='links-container links-institucionales'
							fluid='sm'
						>
							<Link to='/' className='link-component links-institucionales'>
								Inicio
							</Link>
							{" > "}
							<Link
								to={`/programas/${location.pathname.split("/")[1]}`}
								className='link-component links-institucionales'
							>
								{Titulo}
							</Link>
						</Container>

						<Container fluid='sm'>
							<h1 className='titulo-institucional'>{Titulo}</h1>
							<div className='wrapper'>
								<Col sm={3} className='lateral-menu'>
									{seccionesInstitucionales.map((seccion, i) => (
										<div className='lateral-items-institucional' key={i}>
											<span className='lateral_intitucional_blanco'>
												<Link className='enlace-lateral' to={seccion.Ruta}>
													{seccion.Titulo}
												</Link>
											</span>
										</div>
									))}
								</Col>

								<Col sm={7} className='main-content'>
									<div className='main-text-institucional'>
										<div className='markdown-general'>
											<ReactMarkdown linkTarget='_blank'>
												{agregarUrlAContenido(Contenido)}
											</ReactMarkdown>
										</div>
									</div>
								</Col>
							</div>
						</Container>
					</div>
				</div>
			)}
		</>
	);
};
