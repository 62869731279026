import { IdiomaTypes } from "../types/idiomaTypes";

const initialState = {
  code: "es-ES",
};

export const idiomaReducer = (state = initialState, action) => {
  switch (action.type) {
    case IdiomaTypes.CambiarIdioma:
      return {
        code: action.payload.code,
      };
    default:
      return state;
  }
};
