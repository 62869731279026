import React from "react";
import { useState } from "react";
import { Calendar } from "../Components/Calendar";
import moment from "moment";
import { useEffect } from "react";
import QueryString from "qs";
import { getCollectionData } from "../../../helpers/getCollectionData";
import { useSelector } from "react-redux";

export const CalendarNoticias = ({ setSearchByDate, selectedDay }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState(moment());
	const estadoIdioma = useSelector((state) => state.idioma);

	const [markedDays, setMarkedDays] = useState([]);

	useEffect(() => {
		setIsLoading(true);

		const query = QueryString.stringify({
			filters: {
				$or: [
					{
						$and: [
							{
								Fecha: {
									$gte: value.startOf("month").format("YYYY-MM-DD"),
								},
							},
							{
								Fecha: {
									$lte: value.endOf("month").format("YYYY-MM-DD"),
								},
							},
						],
					},
					{
						$and: [
							{
								FechaFinalizacion: {
									$gte: value.startOf("month").format("YYYY-MM-DD"),
								},
							},
							{
								FechaFinalizacion: {
									$lte: value.endOf("month").format("YYYY-MM-DD"),
								},
							},
						],
					},
				],
			},
			fields: ["Fecha", "FechaFinalizacion"],
		});

		const getNoticiasEnMes = async () => {
			const { data } = await getCollectionData("noticias", query, estadoIdioma);

			let selectedDays = [];
			data.forEach((item) => {
				// Caso donde tiene fecha inicio y fin
				if (item.attributes.FechaFinalizacion) {
					// EL filtro entró por la fecha inicio
					if (moment(item.attributes.Fecha).month() === moment(value).month()) {
						selectedDays.push(
							moment(item.attributes.Fecha).format("D").toString()
						);
					}
					// Filtro entró por al fecha fin
					if (
						moment(item.attributes.FechaFinalizacion).month() ===
						moment(value).month()
					)
						selectedDays.push(
							moment(item.attributes.FechaFinalizacion).format("D").toString()
						);
				} else {
					selectedDays.push(
						moment(item.attributes.Fecha).format("D").toString()
					);
				}
			});
			setMarkedDays(selectedDays);
			setIsLoading(false);
		};

		getNoticiasEnMes();
	}, [value, estadoIdioma]);

	return (
		<Calendar
			isLoading={isLoading}
			value={value}
			setValue={setValue}
			markedDays={markedDays}
			selectedDay={selectedDay}
			onElementClick={setSearchByDate}
		/>
	);
};
