import React, { useContext } from "react";
import { useFetchTiposArtesanos } from "../../../hooks/useFetchTiposArtesanos";
import { CategoriaSeleccionadasContext } from "../ArtesanosContext";
import "./TipoArtesanos.css";

export const TiposArtesanos = ({ subtitulo = "" }) => {
	const { tiposArtesanosSeleccionados, setTiposArtesanosSeleccionados } =
		useContext(CategoriaSeleccionadasContext);

	const { data, loading } = useFetchTiposArtesanos();

	const handleInputChange = (tipo) => {
		if (!tiposArtesanosSeleccionados.includes(tipo.id)) {
			setTiposArtesanosSeleccionados([...tiposArtesanosSeleccionados, tipo.id]);
		} else {
			setTiposArtesanosSeleccionados(
				tiposArtesanosSeleccionados.filter((tip) => tipo.id !== tip)
			);
		}
	};

	return (
		<div className='component-box col-12'>
			<h6 className='box-title'>{subtitulo}</h6>
			{loading ? (
				<p>Loading</p>
			) : (
				<div className='row contenedor-tipos-artesanos'>
					{data.map((tipo) => (
						<div
							key={tipo.attributes.Tipo + tipo.id}
							className='checkbox-checkbox col-6 col-sm-12 mb-2'
							onClick={() => handleInputChange(tipo)}
						>
							<input
								type='checkbox'
								checked={tiposArtesanosSeleccionados.includes(tipo.id)}
								onChange={() => null}
							/>
							<label className='input-label'>
								{tipo.attributes.Tipo}
								{tipo.attributes.artesanos.data.length > 0
									? ` (${tipo.attributes.artesanos.data.length})`
									: ""}
							</label>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
