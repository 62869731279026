import { IdiomaTypes } from "../types/idiomaTypes";

export const cambiarIdiomaActual = (idioma = "") => {
  return (dispatch) =>
    dispatch({
      type: IdiomaTypes.CambiarIdioma,
      payload: {
        code: idioma,
      },
    });
};
