import React, { Suspense } from "react";
import { useLazyLoad } from "../../../hooks/useLazyLoad";

const SugerirHistorias = React.lazy(() => import("./SugerirHistorias"));

export const SugerirHistoriasLazy = ({ historiaId, idioma }) => {
	const { isNear, elementRef } = useLazyLoad();
	return (
		<div ref={elementRef}>
			<Suspense fallback={null}>
				{isNear && <SugerirHistorias historiaId={historiaId} idioma={idioma} />}
			</Suspense>
		</div>
	);
};
