import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { AppRoutes } from "./AppRoutes";
import Footer from "./components/Footer/Footer";
import { store } from "./store/store";
import Navigationbar from "./components/Navbar/Navigationbar";

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<header>
					<Navigationbar />
				</header>

				<body>
					<AppRoutes />
				</body>

				<footer>
					<Footer />
				</footer>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
