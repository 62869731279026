import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Container } from "react-bootstrap";

import { LateralMenu } from "./LateralMenu";
import { ListaLibros } from "./ListaLibros";
import { ordenarArrayLibros } from "../../../../helpers/ordenarArray";
import { getCollectionData } from "../../../../helpers/getCollectionData";
import { Paginacion } from "../../../shared/Paginacion/Paginacion";
import { aleatorizarArray } from "../../../../helpers/aleatorizarArray";
import { useWindowsSize } from "../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../types/WindowSizeType";
import { ordenamientos } from "../../../../types/ordenamientos";
import { useTitle } from "../../../../hooks/useTitle";
import usePageTracking from "../../../../hooks/usePageTracking";

export const EdicionesLibros = () => {
	const [librosState, setLibrosState] = useState([]);
	const [ordenamientoSeleccionado, setOrdenamientoSeleccionado] = useState(
		ordenamientos.MasReciente
	);
	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadLibrosAMostrar, setCantidadLibrosAMostrar] = useState(9);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [mostrarMasActivado, setMostrarMasActivado] = useState(false);
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		OrdenLabel: "",
		AfichesLabel: "",
		PremioNacionalArtesania: "",
		MostrarMasLabel: "",
		TextoResumen: "",
	});

	useTitle(datosPagina.TituloLabel);
	usePageTracking();

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const {
					TituloLabel,
					OrdenLabel,
					AfichesLabel,
					PremioNacionalArtesania,
					MostrarMasLabel,
					TextoResumen,
				} = (
					await getCollectionData("pagina-edicion-libros", "", estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					OrdenLabel: OrdenLabel,
					AfichesLabel: AfichesLabel,
					PremioNacionalArtesania: PremioNacionalArtesania,
					MostrarMasLabel: MostrarMasLabel,
					TextoResumen: TextoResumen,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté

		const obtenerLibros = async () => {
			const ordenamiento = ordenarArrayLibros(ordenamientoSeleccionado);
			const qs = require("qs");
			const query = qs.stringify({
				sort: [ordenamiento],
				pagination: {
					page: paginaActual,
					pageSize: cantidadLibrosAMostrar,
				},
				populate: "Portada",
				fields: ["Fecha"],
			});
			const { data, paginas } = await getCollectionData(
				"edicion-libros",
				query,
				estadoIdioma
			);

			if (ordenamientoSeleccionado === ordenamientos.Aleatorio)
				setLibrosState(aleatorizarArray(data));
			else setLibrosState(data);

			if (!mostrarMasActivado) setCantidadPaginas(paginas);
		};
		obtenerLibros();
	}, [
		ordenamientoSeleccionado,
		paginaActual,
		cantidadLibrosAMostrar,
		estadoIdioma,
		mostrarMasActivado,
	]);

	const mostrarMasResultados = () => {
		setCantidadLibrosAMostrar(cantidadLibrosAMostrar + 9);
		setMostrarMasActivado(true);
	};

	const cambiarPaginaActual = (pagina) => {
		if (mostrarMasActivado) {
			setCantidadLibrosAMostrar(9);
			setMostrarMasActivado(false);
		}
		setPaginaActual(pagina);
	};

	const currentSize = useWindowsSize();

	return (
		<div className='contenedor-general-libros'>
			{currentSize === WindowSizeType.xs ? (
				<>
					<Container fluid='sm'>
						<div>
							<Link to='/' className='link-component '>
								Inicio
							</Link>
							{" > "}
							<Link
								to='/programas/PremioNacionalArtesanias'
								className='link-component'
							>
								Premio Nac...
							</Link>
							{" > "}
							<Link
								to='/programas/PremioNacionalArtesanias/EdicionesLibros'
								className='link-component'
							>
								{datosPagina.TituloLabel}
							</Link>
						</div>

						<h2 className='title-mobile'>{datosPagina.TituloLabel}</h2>
						<p className='p-unstyled my-4 texto-resumen-libro'>
							{datosPagina.TextoResumen}
						</p>
						<hr className='hr-main-layout' />

						<LateralMenu
							setOrdenamiento={setOrdenamientoSeleccionado}
							subtitulo={datosPagina.OrdenLabel}
							AfichesLabel={datosPagina.AfichesLabel}
							PremioNacionalArtesania={datosPagina.PremioNacionalArtesania}
							tituloDropdown={datosPagina.TituloLabel}
							ordenamientoSeleccionado={ordenamientoSeleccionado}
						/>

						<ListaLibros libros={librosState} />
					</Container>
					<Paginacion
						cantidadPaginas={cantidadPaginas}
						mostrarMasResultados={mostrarMasResultados}
						setPaginaActual={cambiarPaginaActual}
						paginaActual={paginaActual}
						mostrarMasLabel={datosPagina.MostrarMasLabel}
					/>
				</>
			) : (
				<Container fluid='sm'>
					<div>
						<Link to='/' className='link-component '>
							Inicio
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias'
							className='link-component'
						>
							Premio Nacional de Artesanía
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias/EdicionesLibros'
							className='link-component'
						>
							{datosPagina.TituloLabel}
						</Link>
					</div>

					<h2 className='title'>{datosPagina.TituloLabel}</h2>

					<div className='wrapper contenedor-libros'>
						<Col sm={3} className='lateral-menu'>
							<LateralMenu
								setOrdenamiento={setOrdenamientoSeleccionado}
								subtitulo={datosPagina.OrdenLabel}
								AfichesLabel={datosPagina.AfichesLabel}
								PremioNacionalArtesania={datosPagina.PremioNacionalArtesania}
								ordenamientoSeleccionado={ordenamientoSeleccionado}
							/>
						</Col>

						<Col sm={9} className='main-content'>
							<p className='col-9 p-unstyled mb-4 texto-resumen-libro'>
								{datosPagina.TextoResumen}
							</p>

							<ListaLibros libros={librosState} />
						</Col>
					</div>
					<Paginacion
						cantidadPaginas={cantidadPaginas}
						mostrarMasResultados={mostrarMasResultados}
						setPaginaActual={cambiarPaginaActual}
						paginaActual={paginaActual}
						mostrarMasLabel={datosPagina.MostrarMasLabel}
					/>
				</Container>
			)}
		</div>
	);
};
