import { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { SocialIcon } from "react-social-icons";

import { getEntityById } from "../../helpers/getEntityById";
import "./PerfilHistoria.css";
import { getCollectionData } from "../../helpers/getCollectionData";
import { agregarUrlAContenido } from "../../helpers/agregarUrlAContenido";
import usePageTracking from "../../hooks/usePageTracking";
import { useTitle } from "../../hooks/useTitle";
import { SugerirDatos } from "../shared/SugerirDatos/SugerirDatos";
import { SugerirHistoriasLazy } from "./components/SugerirHistorias.lazy";

export const PerfilHistoria = () => {

  usePageTracking();
  const { historiaId } = useParams();

  const [historia, setHistoria] = useState({});
  const estadoIdioma = useSelector((state) => state.idioma);
  const [datosPagina, setDatosPagina] = useState({
    MasHistoriasLabel: "",
    CompartirLabel: "",
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const obtenerDatos = async () => {
        const { MasHistoriasLabel, CompartirLabel } = (
          await getCollectionData(
            "pagina-perfil-historias",
            "",
            estadoIdioma,
            1
          )
        ).data[0].attributes;

        setDatosPagina({
          MasHistoriasLabel: MasHistoriasLabel,
          CompartirLabel: CompartirLabel,
        });
      };
      obtenerDatos();
    }
    return () => (isMounted = false);
  }, [estadoIdioma]);

  useMemo(() => {
    const obtenerHistoria = async () => {
      const historiaBD = await getEntityById(
        "historias",
        historiaId,
        [],
        estadoIdioma
      );
      setHistoria(historiaBD);
    };

    obtenerHistoria();
  }, [historiaId, estadoIdioma]);


  const acotarTitulo = (titulo = "") => {
    if (titulo.length > 50) return titulo.substring(0, 50).concat("... ");
    return titulo;
  };

  const { Titulo, Contenido } = historia ?? {};

  useTitle(Titulo ?? "");


  return (
    <>
      <Container fluid="sm" className="links-container">
        <Link to="/" className="link-component">
          Inicio
        </Link>
        {" > "}
        <Link to="/historias" className="link-component">
          Historias
        </Link>
        {" > "}
        <Link to={`/historias/${Titulo}`} className="link-component">
          {acotarTitulo(Titulo)}
        </Link>
      </Container>
      <Container fuild="sm">
        <div className="clase-rara-historia">
          <div className="text-container-historia">
            <h1 className="titulo-historia">{Titulo}</h1>

            <ReactMarkdown linkTarget="_blank">
              {agregarUrlAContenido(Contenido)}
            </ReactMarkdown>
            <div>
              <p className="titulo-compartir">{datosPagina.CompartirLabel}</p>
              <div className="socialIcon-container">
                <SocialIcon
                  url={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  bgColor={"black"}
                  style={{ height: 24, width: 24, marginRight: 11 }}
                />
                <SocialIcon
                  url={`https://twitter.com/intent/tweet?text=${Titulo}&url=${window.location.href}`}
                  bgColor={"black"}
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 11,
                  }}
                />
                <SocialIcon
                  url={`https://api.whatsapp.com/send?text=${Titulo}%20-->${window.location.href}`}
                  bgColor={"black"}
                  style={{
                    height: 24,
                    width: 24,
                    marginRight: 11,
                  }}
                />
                {/* <BotonCopiarEnlace /> */}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <SugerirDatos title={datosPagina.MasHistoriasLabel}>
        <SugerirHistoriasLazy historiaId={historiaId} idioma={estadoIdioma} />
      </SugerirDatos>

    </>
  );
};
