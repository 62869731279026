import React, { useState } from "react";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

const responsive = {
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
	},
};

export const CarouselTallerista = ({
	Nombre,
	Imagenes,
	handleMostrarImagenes,
	tallerista,
}) => {
	const [hoverActivado, setHoverActivado] = useState(false);

	return (
		<div
			className='col-6 col-md-4 card tallerista-card'
			onMouseEnter={() => setHoverActivado(true)}
			onMouseLeave={() => setHoverActivado(false)}
		>
			<Carousel
				partialVisible={false}
				responsive={responsive}
				arrows={hoverActivado}
				infinite={true}
				autoPlay={false}
				shouldResetAutoplay={false}
				removeArrowOnDeviceType={["tablet", "mobile"]}
			>
				{Imagenes.map((imagen, index2) => {
					return (
						<div
							key={index2}
							onClick={() => handleMostrarImagenes(tallerista.id)}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<img
								src={`${process.env.REACT_APP_BASE_URL}${imagen.Imagen.data.attributes.url}`}
								alt='...'
								className='card-img-top tallerista-portada'
							/>
						</div>
					);
				})}
			</Carousel>
			<div className='card-title'>
				<p className=' nombre-tallerista'>{Nombre}</p>
			</div>
		</div>
	);
};
