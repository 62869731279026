import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

import { getEntityById } from "../../../../helpers/getEntityById";
import { IndicadorChico } from "./components/IndicadorChico";
import { IndicadorGrande } from "./components/IndicadorGrande";
import { getCollectionData } from "../../../../helpers/getCollectionData";
import { useTitle } from "../../../../hooks/useTitle";
import usePageTracking from "../../../../hooks/usePageTracking";
import { Rutas } from "../../../../routes/Routes";

export const PerfilIndicador = () => {
	const { indicadorId } = useParams();
	const [pdfState, setPdfState] = useState("");
	const [pdfExpandido, setPdfExpandido] = useState(false);
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		DescargarLabel: "",
	});

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { Titulo, DescargarLabel } = (
					await getCollectionData(
						"pagina-perfil-indicadors",
						"",
						estadoIdioma,
						1
					)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: Titulo,
					DescargarLabel: DescargarLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		const obtenerLibro = async () => {
			const indicador = await getEntityById(
				"indicadors",
				indicadorId,
				["Pdf"],
				estadoIdioma
			);

			setPdfState(indicador.Pdf?.data.attributes);
		};
		obtenerLibro();
	}, [indicadorId, estadoIdioma]);

	const sampblePDF = `${process.env.REACT_APP_BASE_URL}${pdfState.url}`;

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

	useTitle(pdfState ? pdfState.name : "");
	usePageTracking();

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	function changePage(offset) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	function previousPage(e) {
		e.preventDefault();
		e.stopPropagation();
		changePage(-1);
	}

	function nextPage(e) {
		e.preventDefault();
		e.stopPropagation();

		changePage(1);
	}

	const descargarPDF = () => {
		saveAs(sampblePDF, pdfState.name);
	};

	const navigate = useNavigate();
	const handleCerrarLibro = () => {
		navigate(-1);
	};
	const getPdfSize = () => {
		let size = (window.innerWidth * 40) / 100 - 5.5;
		// const size = window.innerWidth;
		return size;
	};

	const getPdfSizeGrande = () => {
		let size = window.width;
		return size;
	};

	return (
		<>
			<div className='container-sm'>
				<div className='contenedor-seccion-pdf'>
					<div>
						<Link to={Rutas.Home} className='link-component '>
							Inicio
						</Link>

						{" > "}

						<Link to={Rutas.Indicadores} className='link-component'>
							Indicadores
						</Link>
					</div>
					<div className='title'>{datosPagina.TituloLabel}</div>
				</div>
			</div>

			{pdfExpandido ? (
				<IndicadorGrande
					descargarPDF={descargarPDF}
					previousPage={previousPage}
					nextPage={nextPage}
					pageNumber={pageNumber}
					numPages={numPages}
					sampblePDF={sampblePDF}
					handleCerrarLibro={() => setPdfExpandido(false)}
					onDocumentLoadSuccess={onDocumentLoadSuccess}
					getPdfSize={getPdfSizeGrande}
					pdfExpandido={pdfExpandido}
				/>
			) : (
				<div className='container-sm'>
					<div className='contenedor-seccion-pdf'>
						<IndicadorChico
							descargarPDF={descargarPDF}
							previousPage={previousPage}
							nextPage={nextPage}
							pageNumber={pageNumber}
							numPages={numPages}
							sampblePDF={sampblePDF}
							handleCerrarLibro={handleCerrarLibro}
							onDocumentLoadSuccess={onDocumentLoadSuccess}
							getPdfSize={getPdfSize}
							setPdfExpandido={setPdfExpandido}
							DescargarLabel={datosPagina.DescargarLabel}
						/>
					</div>
				</div>
			)}
		</>
	);
};
