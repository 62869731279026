import React, { useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import rightArrow from "../../../../../assets/icons/arrow-black-right/arrow-black-right.svg";
import leftArrow from "../../../../../assets/icons/arrow-black-left/arrow-black-left.svg";
import closeBtn from "../../../../../assets/icons/close-button-black/close-button-black.svg";

const nI = <img src={rightArrow} alt='flecha siguiente' />;
const pI = <img src={leftArrow} alt='flecha anterior' />;
const closeButtonImage = <img src={closeBtn} alt='boton cerrar' />;

export const IndicadorGrande = ({
	descargarPDF,
	previousPage,
	nextPage,
	pageNumber,
	numPages,
	sampblePDF,
	handleCerrarLibro,
	onDocumentLoadSuccess,
	getPdfSize,
	pdfExpandido,
}) => {
	const expandirPdf = (flag) => {
		let pdf = document.getElementById("pdfGrande");
		flag ? (pdf.style.display = "block") : (pdf.style.display = "none");
	};
	useEffect(() => {
		pdfExpandido ? expandirPdf(true) : expandirPdf(false);
	}, [pdfExpandido]);

	return (
		<div id='pdfGrande'>
			<div className='pdfGrandeShadow'></div>

			<div className='contenedor-pdf-grande'>
				<div className='botonesPDF'>
					<div className='flechasPdf'>
						<button
							disabled={pageNumber <= 1}
							onClick={previousPage}
							className='botones-pdf-grande boton-flechas-pdf-grande-left'
						>
							{pI}
						</button>
						<button
							disabled={pageNumber >= numPages}
							onClick={(event) => {
								event.stopPropagation();
								event.preventDefault();
								nextPage(event);
							}}
							className='botones-pdf-grande boton-flechas-pdf-grande-right'
						>
							{nI}
						</button>
					</div>
					<button
						className='botones-pdf-grande boton-cerrar-libro-pdf-grande'
						onClick={handleCerrarLibro}
					>
						{closeButtonImage}
					</button>
				</div>
				<div>
					<Document
						className=' PDF-document-grande'
						file={sampblePDF}
						options={{ workerSrc: "/pdf.worker.js" }}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							pageNumber={pageNumber}
							height={1000}
							className='pdf-grande-page'
						/>
					</Document>
				</div>
			</div>
		</div>
	);
};
