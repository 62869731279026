import React, { useContext } from "react";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { ordenamientos } from "../../../types/ordenamientos";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { RadioButtonDropdown } from "../../shared/DropdownsItems/radioButton/RadioButtonDropdown";
import { OrdenamientoSeleccionadoContext } from "../HistoriasContext";

export const OrdenHistorias = ({ subtitulo = "" }) => {
	const ordenar = [
		ordenamientos.MasReciente,
		ordenamientos.MenosRecientes,
		ordenamientos.Alfabetico,
	];
	const { setOrdenamientoSeleccionado, ordenamientoSeleccionado } = useContext(
		OrdenamientoSeleccionadoContext
	);

	const currentSize = useWindowsSize();

	const handleInputChange = (e, ord = "") => {
		setOrdenamientoSeleccionado(ord);
	};

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='col-6'>
					<RadioButtonDropdown
						items={ordenar}
						title={subtitulo}
						handleInputChange={handleInputChange}
						currentValue={ordenamientoSeleccionado}
					/>
				</div>
			) : (
				<div className='component-box col-sm-12 col-6'>
					<h6 className='box-title'>{subtitulo}</h6>
					{ordenar.map((ord, i) => (
						<div key={i} className='mb-1'>
							<input
								type='radio'
								value={ord}
								name='orden'
								onChange={(e) => handleInputChange(e, ord)}
								checked={ordenamientoSeleccionado === ord}
							/>
							<label className='input-label'>{ord}</label>
						</div>
					))}
				</div>
			)}
		</>
	);
};
