import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { SocialIcon } from "react-social-icons";
import { useSelector } from "react-redux";

import "./PerfilNoticia.css";
import { getCollectionData } from "../../helpers/getCollectionData";
import { agregarUrlAContenido } from "../../helpers/agregarUrlAContenido";
import usePageTracking from "../../hooks/usePageTracking";
import { useTitle } from "../../hooks/useTitle";
import { SugerirDatos } from "../shared/SugerirDatos/SugerirDatos";
import { SugerirNoticiasLazy } from "./components/SugerirNoticias.lazy";
import { getEntityBySlug } from "../../helpers/getEntityBySlug";
import { getEntityById } from "../../helpers/getEntityById";

export const PerfilNoticia = () => {
	const { noticiaId } = useParams();

	const [noticia, setNoticia] = useState({});
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		MasNoticiasLabel: "",
		CompartirLabel: "",
	});

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { MasNoticiasLabel, CompartirLabel } = (
					await getCollectionData("pagina-perfil-noticias", "", estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					MasNoticiasLabel: MasNoticiasLabel,
					CompartirLabel: CompartirLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		const obtenerNoticia = async () => {
			const noticiaBD = isNaN(noticiaId)
				? await getEntityBySlug("noticias", noticiaId, [], estadoIdioma)
				: await getEntityById("noticias", noticiaId, [], estadoIdioma);
			setNoticia(noticiaBD);
		};

		obtenerNoticia();
	}, [noticiaId, estadoIdioma]);

	const acotarTitulo = (titulo = "") => {
		if (titulo.length > 50) return titulo.substring(0, 50).concat("... ");
		return titulo;
	};

	const { Titulo, Contenido } = noticia ?? {};

	useTitle(Titulo ?? "");
	usePageTracking();
	return (
		<>
			<Container fluid='sm' className='links-container'>
				<Link to='/' className='link-component'>
					Inicio
				</Link>
				{" > "}
				<Link to='/noticias' className='link-component'>
					Noticias
				</Link>
				{" > "}
				<Link to={`/noticias/${noticiaId}`} className='link-component'>
					{acotarTitulo(Titulo)}
				</Link>
			</Container>

			<Container fuild='sm'>
				<div className='clase-rara-noticia contenedor_markdown'>
					<div className='text-container-noticia'>
						<h1 className='titulo-noticia'>{Titulo}</h1>
						<ReactMarkdown linkTarget='_blank'>
							{agregarUrlAContenido(Contenido)}
						</ReactMarkdown>
						<div>
							<p className='titulo-compartir'>{datosPagina.CompartirLabel}</p>
							<div className='socialIcon-container'>
								<SocialIcon
									url={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}
									bgColor={"black"}
									style={{ height: 24, width: 24, marginRight: 11 }}
								/>
								<SocialIcon
									url={`https://twitter.com/intent/tweet?text=${Titulo}&url=${window.location}`}
									bgColor={"black"}
									style={{
										height: 24,
										width: 24,
										marginRight: 11,
									}}
								/>
								<SocialIcon
									url={`https://api.whatsapp.com/send?text=${Titulo}%20-->${window.location.href}`}
									bgColor={"black"}
									style={{
										height: 24,
										width: 24,
										marginRight: 11,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Container>

			<SugerirDatos title={datosPagina.MasNoticiasLabel}>
				<SugerirNoticiasLazy noticiaId={noticiaId} idioma={estadoIdioma} />
			</SugerirDatos>
		</>
	);
};
