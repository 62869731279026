import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { parseISO } from "date-fns";
import { format } from "date-fns/esm";
import { es } from "date-fns/locale";
import { Link, useNavigate } from "react-router-dom";

import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { AccordionNoticia } from "../Components/AccordionNoticia";
import { AccordionToogle } from "../Components/AccordionToogle";
import "./NoticiaViewItem.css";

export const NoticiaItem = ({ noticia = {} }) => {
	const {
		Titulo,
		Fecha,
		Portada,
		categorias_noticia,
		Contenido,
		FechaFinalizacion,
		TextoFecha,
		slug,
	} = noticia?.attributes;
	const rutaImagen = `${process.env.REACT_APP_BASE_URL}${Portada.Imagen.data.attributes.url}`;

	const navigate = useNavigate();
	const fechaProcesada = format(parseISO(Fecha), "d MMM yyyy", {
		locale: es,
	});

	const fechaFinProcesada = FechaFinalizacion
		? format(parseISO(FechaFinalizacion), "d MMM yyyy", {
				locale: es,
		  })
		: null;

	const currentSize = useWindowsSize();

	const procesarTitulo = (texto = "") => {
		let response = texto;
		switch (currentSize) {
			case WindowSizeType.xs:
				response =
					texto.length > 40 ? texto.substring(0, 40).concat("...") : texto;
				break;
			case WindowSizeType.sm:
				response =
					texto.length > 30 ? texto.substring(0, 30).concat("...") : texto;
				break;

			case WindowSizeType.lg:
				response =
					texto.length > 100 ? texto.substring(0, 100).concat("...") : texto;
				break;
			case WindowSizeType.md:
				response =
					texto.length > 50 ? texto.substring(0, 50).concat("...") : texto;
				break;
			default:
				response =
					texto.length > 60 ? texto.substring(0, 60).concat("...") : texto;
				break;
		}

		return response;
	};

	return (
		<>
			<div className='col-lg-1'></div>
			<Accordion className='col-12 col-lg-11'>
				<Card
					style={{
						display: "flex",
						flexDirection: "column",
						border: "1px solid black",
						marginBottom: "20px",
						cursor: "pointer",
					}}
				>
					<AccordionToogle eventKey='0' className='noticia-card'>
						<div className='noticiaRow'>
							<Card.Img
								src={rutaImagen}
								className='card-img-top imagen-noticia'
								alt='...'
							/>
							<Card.Body className='noticia-description'>
								<div className='noticiaRow'>
									<div className='col noticiaCol title_categories'>
										<Card.Title className='noticia-titulo-card'>
											{procesarTitulo(Titulo)}
										</Card.Title>

										<Card.Text>
											{categorias_noticia.data.map((categoria) => (
												<label key={categoria.id} className='categoria-noticia'>
													<Link
														to={`/resultadosPorCategoria/${categoria.attributes.Categoria}`}
													>
														{categoria.attributes.Categoria}
													</Link>
												</label>
											))}
										</Card.Text>
									</div>
									<div className='col-4 col-lg-3 col-xl-2 noticiaCol'>
										<Card.Text className='noticia-fecha'>
											<div style={{ display: "flex", flexDirection: "column" }}>
												<label className='texto_plazo'>
													{TextoFecha ?? "Fecha:"}
												</label>
												<label className='texto-noticia'>
													{fechaProcesada.split(" ").slice(0, 2).join(" ")}
													<br />
													{fechaProcesada.split(" ").slice(2, 3).join(" ")}
												</label>
												<label className='texto-noticia'>
													{fechaFinProcesada ? (
														<>
															{fechaFinProcesada
																.split(" ")
																.slice(0, 2)
																.join(" ")}
															<br />
															{fechaFinProcesada
																.split(" ")
																.slice(2, 3)
																.join(" ")}
														</>
													) : null}
												</label>
											</div>

											<p
												style={{
													fontSize: "12px",
													fontWeight: "normal",
													fontFamily: "GothicMainBook",
												}}
												onClick={() =>
													navigate(`/noticias/${slug ?? noticia.id}`)
												}
											>
												+ Info
											</p>
										</Card.Text>
									</div>
								</div>
							</Card.Body>
						</div>
					</AccordionToogle>

					<Accordion.Collapse
						eventKey='0'
						style={{ borderTop: "1px solid black" }}
					>
						<AccordionNoticia
							contenido={Contenido}
							Titulo={Titulo}
							eventKey='0'
							slug={slug}
						/>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</>
	);
};
