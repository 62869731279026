import React from "react";
import { useNavigate } from "react-router-dom";

import "./ArtesanoItem.css";

export const ArtesanoItem = ({
	artesano = {},
	horizontalStyle = "",
	verticalStyle = "",
}) => {
	const {
		Nombre,
		categorias_artesano = { data: [] },
		Imagen,
	} = artesano?.attributes;
	const rutaImagen = `${process.env.REACT_APP_BASE_URL}${Imagen[0].Imagen.data?.attributes.url}`;

	const navigate = useNavigate();

	const navegarArtesano = () => {
		navigate(`/artesanos/${artesano.id}`);
	};
	return (
		<div
			className={`${Imagen[0].Horizontal ? horizontalStyle : verticalStyle}`}
			style={{ marginBottom: 20 }}
		>
			<div className='card artesano-card' onClick={navegarArtesano}>
				<img
					fluid='true'
					src={rutaImagen}
					className={`card-img-top imagen-artesano-view`}
					alt='...'
				/>
				<div className='card-body artesano-description pb-0'>
					<h5 className='card-title titulo-card'>{Nombre}</h5>
					<p className='card-text'>
						{categorias_artesano.data.slice(0, 2).map((categoria) => (
							<label key={categoria.id} className='categoria-artesano'>
								{categoria.attributes.Categoria}
							</label>
						))}
					</p>
				</div>
			</div>
		</div>
	);
};
