import React from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { format } from "date-fns";

import styles from "./IndicadorItem.module.css";
import { useWindowsSize } from "../../../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../../../types/WindowSizeType";
import { obtenerPdfParaDescargar } from "../../../helpers/obtenerPdfParaDescargar";
import { Rutas } from "../../../../../../routes/Routes";

export const IndicadorItem = ({ indicador = {} }) => {
	const { Portada, Fecha } = indicador.attributes;

	const rutaImagen = `${process.env.REACT_APP_BASE_URL}${Portada.data.attributes.url}`;

	const navigate = useNavigate();

	const navegarIndicador = () => {
		navigate(Rutas.PerfilIndicador + indicador.id);
	};

	const descargarPDF = async () => {
		const { Pdf } = (await obtenerPdfParaDescargar(indicador.id)).data
			.attributes;
		saveAs(
			`${process.env.REACT_APP_BASE_URL}${Pdf.data.attributes.url}`,
			`${Pdf.data.attributes.alternativeText}`
		);
	};
	const currentSize = useWindowsSize();

	return (
		<div className={`card ${styles.card_indicador}`}>
			<div className={`${styles.image_container}`}>
				{currentSize === WindowSizeType.xs ? (
					<img
						fluid='true'
						src={rutaImagen}
						className={`card-img-top ${styles.img_portada}`}
						alt='...'
						onClick={descargarPDF}
					/>
				) : (
					<img
						fluid='true'
						src={rutaImagen}
						className={`card-img-top ${styles.img_portada}`}
						alt='...'
						onClick={navegarIndicador}
					/>
				)}
			</div>

			<div className='card-body'>
				<div className='d-flex justify-content-between'>
					<span className={`card-text card-items ${styles.fecha_text}`}>
						{format(new Date(Fecha), "d MMM yyyy")
							.split(" ")
							.slice(2, 3)
							.join(" ")}
					</span>
					<button
						className={`card-text card-items ${styles.boton_pdf}`}
						onClick={descargarPDF}
					>
						PDF
					</button>
				</div>
			</div>
		</div>
	);
};
