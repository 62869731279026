import React from "react";
import { useNavigate } from "react-router-dom";
import "./Afiches.css";

export const AficheItem = ({ afiche = {} }) => {
	const { Portada, Titulo = "Afiche" } = afiche.attributes;

	const rutaImagen = `${process.env.REACT_APP_BASE_URL}${Portada.data.attributes.url}`;

	const navigate = useNavigate();

	const navegarAfiche = () => {
		navigate(`/programas/PremioNacionalArtesanias/Afiches/${afiche.id}`);
	};

	return (
		<>
			<div className='afiche-card'>
				<img
					fluid='true'
					src={rutaImagen}
					className='afiche-img'
					alt={Titulo}
					onClick={navegarAfiche}
				/>
				<div className='card-title'>
					<p className=' nombre-tallerista'>{Titulo}</p>
				</div>
			</div>
		</>
	);
};
