import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetchPremioNacionalArtesania = () => {
  const [state, setState] = useState({
    data: {},
    loading: true,
  });
  const estadoIdioma = useSelector((state) => state.idioma);



  useEffect(() => {
    let isActive = true;

    const base_url = `${process.env.REACT_APP_BASE_URL}`;
    const qs = require("qs");
    const query = qs.stringify({
      populate: "*",
    });

    if (isActive) {
      const request = async () => {
        const urlCompleta = `${base_url}/api/premio-nacional-artesanias?${query}&locale=${estadoIdioma.code}`;
        const resp = await fetch(urlCompleta);
        const dataRest = await resp.json();
        setState({ data: dataRest.data, loading: false });
      }

      request();
    }
    return () => (isActive = false);
  }, [estadoIdioma]);

  return state;
};
