import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./SearchBox.module.css";
import search from "../../../../assets/icons/search/search-white.svg";

export const SearchBar = ({
	handleKeyDown,
	placeholder,
	handleClick,
	handleFocus,
	handleBlur,
	mobile = false,
	cerrarMenu = false,
}) => {
	const preventSearchClose = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const [query, setQuery] = useState("");
	const navigate = useNavigate();

	const navigateToResults = (searchString, e) => {
		if (searchString === "") preventSearchClose(e);
		else {
			if (cerrarMenu) cerrarMenu();
			navigate(`/resultadosSimple/${searchString}`);
		}
	};

	const handleEnter = (e) => {
		if (e.key === "Enter") {
			navigateToResults(query, e);
			setQuery("");
			handleKeyDown();
		}
		if (e.key === " " || e.key === "Spacebar") {
			setQuery(e.target.value.concat(" "));
		}
	};

	return (
		<>
			{!mobile ? (
				<div className={styles.search_bar_container} onClick={handleClick}>
					<div className={styles.search_bar}>
						<div className={styles.search_bar_icon}>
							<img
								src={search}
								alt='search icon'
								width='24px'
								height='24px'
								onClick={preventSearchClose}
							/>
						</div>
						<input
							autoFocus
							type='search'
							name='search-input'
							onKeyDown={handleEnter}
							onClick={preventSearchClose}
							placeholder={placeholder}
							value={query}
							onChange={(event) => setQuery(event.target.value)}
						/>
					</div>
				</div>
			) : (
				<div className={styles.search_bar_container} onClick={handleClick}>
					<div className={styles.search_bar}>
						<input
							autoFocus
							type='search'
							name='search-input'
							onKeyDown={handleEnter}
							onClick={preventSearchClose}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder={placeholder}
							value={query}
							onChange={(event) => setQuery(event.target.value)}
							className={styles.texto_buscador}
						/>
						<div className={styles.search_bar_ir}>
							<span
								className={styles.ir}
								onClick={(event) => {
									preventSearchClose(event);
									navigateToResults(query, event);
								}}
							>
								ir
							</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export const SearchIcon = ({ handleClick, img }) => {
	return (
		<div className={styles.search_icon}>
			<img id='searchIcon' src={img} alt='search icon' onClick={handleClick} />
		</div>
	);
};

export const MobileSearchIcon = ({ handleClick, img, BuscarMobile }) => {
	return (
		<div onClick={handleClick} className={styles.search_icon}>
			<img src={img} alt='search icon' className='me-2' />
			<span className={styles.texto_buscador}>{BuscarMobile}</span>
		</div>
	);
};
