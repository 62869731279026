import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import "./CheckboxMenu.css";
import { CheckDropdownItem } from "./CheckboxItem";
import { CheckboxMenu } from "./CheckboxMenu";

export const CheckDropdownCategoriaHistorias = ({
	items = [],
	title = "",
	handleInputChange,
}) => {
	const handleChecked = (e, item) => {
		e.stopPropagation();
		handleInputChange(e, item);
	};
	const [flechaDropdown, setFlechaDropdown] = useState(false);

	return (
		<Dropdown onToggle={(nextToggle, e) => setFlechaDropdown(nextToggle)}>
			<Dropdown.Toggle className='todo-width'>
				<>
					<span>{title}</span>
					<span
						id='programasIcon'
						className={`${flechaDropdown ? "" : "arrow-flip-drop"} flecha-algo`}
					>
						^
					</span>
				</>
			</Dropdown.Toggle>

			<Dropdown.Menu as={CheckboxMenu}>
				{items.map((i) => (
					<Dropdown.Item
						key={i.id}
						as={CheckDropdownItem}
						id={i.id}
						onClick={(e) => handleChecked(e, i)}
					>
						{i.attributes.Categoria} {`(${i.attributes.historias.data.length})`}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
