import React from "react";

export const LateralMenu = ({ listaComponentes = [] }) => {
	return (
		<div className='lateral-menu row'>
			{listaComponentes.map((comp, i) => (
				<React.Fragment key={i}>{comp}</React.Fragment>
			))}
		</div>
	);
};
