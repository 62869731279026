import React from "react";

import { HistoriaItem } from "./HistoriaItem";

export const ListaHistorias = ({ Historias = [] }) => {
	return (
		<div className='row'>
			{Historias.map((historia) => {
				let Portada = historia?.attributes.Portada;
				let esVideo = false;
				if (
					Portada.LinkVideo !== null &&
					Portada.LinkVideo !== undefined &&
					Portada.LinkVideo.trim() !== ""
				) {
					esVideo = true;
				}
				return (
					<div
						className={`${
							!esVideo && !Portada.Horizontal
								? "col-lg-4 col-md-6"
								: "col-lg-8 col-md-12"
						} `}
						key={historia.id}
					>
						<HistoriaItem historia={historia} />
					</div>
				);
			})}
		</div>
	);
};
