import React from "react";

export const SimpleMenu = React.forwardRef(
	({ children, style, className }, ref) => {
		return (
			<div ref={ref} style={style} className={`${className} SimpleMenu`}>
				<div
					className='d-flex flex-column'
					style={{ maxHeight: "calc(100vh)", overflow: "none" }}
				>
					<ul
						className='list-unstyled flex-shrink mb-0'
						style={{ overflow: "auto" }}
					>
						{children}
					</ul>
				</div>
			</div>
		);
	}
);
