import React, { useContext, useMemo } from "react";

import { useFetchCategoriasArtesanosConCantidad } from "../../../hooks/useFetchCategoriasArtesanosConCantidad";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { CheckDropdownCategoriaArtesano } from "../../shared/DropdownsItems/Checkbox/CheckDropdownCategoriaArtesano";
import { CategoriaSeleccionadasContext } from "../ArtesanosContext";

export const CategoriasArtesano = ({ subtitulo = "" }) => {
	const { categoriasSeleccionadas, setCategoriasSeleccionadas } = useContext(
		CategoriaSeleccionadasContext
	);

	const { data, loading } = useFetchCategoriasArtesanosConCantidad();
	const currentSize = useWindowsSize();

	const categorias = useMemo(
		() =>
			loading ? [] : data.filter((x) => x.attributes.artesanos.data.length > 0),
		[loading, data]
	);

	const handleInputChange = (cat) => {
		if (!categoriasSeleccionadas.includes(cat.id)) {
			setCategoriasSeleccionadas([...categoriasSeleccionadas, cat.id]);
		} else {
			setCategoriasSeleccionadas(
				categoriasSeleccionadas.filter((cate) => cate !== cat.id)
			);
		}
	};
	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='col-6'>
					<CheckDropdownCategoriaArtesano
						items={categorias}
						title={subtitulo}
						handleInputChange={handleInputChange}
					/>
				</div>
			) : (
				<div className='component-box col-12'>
					<h6 className='box-title'>{subtitulo}</h6>
					{loading ? (
						<p>Loading</p>
					) : (
						categorias.map((categoria) => (
							<div
								key={categoria.id}
								onClick={() => handleInputChange(categoria)}
								className='mb-2'
							>
								<input
									type='checkbox'
									checked={categoriasSeleccionadas.includes(categoria.id)}
									onChange={() => null}
								/>
								<label className='input-label'>
									{`${categoria.attributes.Categoria} (${categoria.attributes.artesanos.data.length})`}
								</label>
							</div>
						))
					)}
				</div>
			)}
		</>
	);
};
