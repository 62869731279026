import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import QueryString from "qs";
import { useSelector } from "react-redux";

import { getCollectionData } from "../../helpers/getCollectionData";
import { obtenerOrdenamientoNoticias } from "../../helpers/ordenarArray";
import { MainLayout } from "../Layouts/MainLayout";
import { Paginacion } from "../shared/Paginacion/Paginacion";
import {
	CategoriaSeleccionadasContext,
	OrdenamientoSeleccionadoContext,
} from "./NoticiasContext";
import { ListaNoticias } from "./MainContent/ListaNoticias";
import { ordenamientos } from "../../types/ordenamientos";
import { useTitle } from "../../hooks/useTitle";
import usePageTracking from "../../hooks/usePageTracking";
import {
	CalendarNoticias,
	LimpiarFiltrosButton,
	SearchNoticias,
	CategoriasNoticias,
	OrdenNoticias,
} from "./LateralMenu";

export const NoticiasView = () => {
	const datosPorPagina = 6;
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		CategoriaLabel: "",
		OrdenLabel: "",
		MostrarMasLabel: "",
	});

	useTitle(datosPagina.TituloLabel);
	usePageTracking();

	const [noticiasState, setNoticiasState] = useState([]);
	const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState([]);
	const [ordenamientoSeleccionado, setOrdenamientoSeleccionado] = useState(
		ordenamientos.MasReciente
	);
	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadRegistrosAMostrar, setCantidadRegistrosAMostrar] =
		useState(datosPorPagina);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [tituloNoticiaBuscado, setTituloNoticiaBuscado] = useState("");
	const [searchByDate, setSearchByDate] = useState(null);

	const [cantidadMostrarMas, setCantidadMostrarMas] = useState(0);

	const estadoIdioma = useSelector((state) => state.idioma);

	const createSearchFilters = useCallback(() => {
		const filters = [];
		if (searchByDate) {
			filters.push({
				$or: [
					{
						Fecha: {
							$eq: searchByDate.format("YYYY-MM-DD"),
						},
					},
					{
						FechaFinalizacion: {
							$eq: searchByDate.format("YYYY-MM-DD"),
						},
					},
				],
			});
		}
		filters.push({
			categorias_noticia: {
				id: {
					$in: categoriasSeleccionadas,
				},
			},
		});
		filters.push({
			Titulo: {
				$contains: tituloNoticiaBuscado,
			},
		});

		return filters;
	}, [categoriasSeleccionadas, tituloNoticiaBuscado, searchByDate]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setCantidadRegistrosAMostrar(datosPorPagina);
			setPaginaActual(1);
			setCantidadMostrarMas(0);
		}
		return () => (isMounted = false);
	}, [categoriasSeleccionadas, ordenamientoSeleccionado]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { TituloLabel, CategoriaLabel, OrdenLabel, MostrarMasLabel } = (
					await getCollectionData("pagina-noticias", "", estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					CategoriaLabel: CategoriaLabel,
					OrdenLabel: OrdenLabel,
					MostrarMasLabel: MostrarMasLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	//* Usamos un useMeme para que la consulta no se ahga cada vez que se reenderiza la página, sino cuando cambian los valores de filtros
	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté
		const ordenamiento = obtenerOrdenamientoNoticias(
			ordenamientoSeleccionado,
			"Titulo"
		);

		const query = QueryString.stringify({
			sort: [ordenamiento],
			filters: {
				$and: createSearchFilters(),
			},
			pagination: {
				page: paginaActual,
				pageSize: cantidadRegistrosAMostrar,
			},
			populate: ["Portada", "Portada.Imagen", "categorias_noticia"],
			fields: [
				"Titulo",
				"Contenido",
				"Fecha",
				"FechaFinalizacion",
				"TextoFecha",
				"slug",
			],
		});

		const obtenerNoticias = async () => {
			const { data, paginas } = await getCollectionData(
				"noticias",
				query,
				estadoIdioma,
				datosPorPagina
			);

			setNoticiasState(data);
			if (cantidadMostrarMas === 0) setCantidadPaginas(paginas);
		};
		obtenerNoticias();
	}, [
		ordenamientoSeleccionado,
		paginaActual,
		cantidadRegistrosAMostrar,
		estadoIdioma,
		cantidadMostrarMas,
		createSearchFilters,
	]);

	const mostrarMasResultados = () => {
		setCantidadRegistrosAMostrar(cantidadRegistrosAMostrar + 6);
		setCantidadMostrarMas(cantidadMostrarMas + 1);
	};

	const cambiarPaginaActual = (pagina) => {
		setCantidadRegistrosAMostrar(6);
		setPaginaActual(pagina);
	};

	const limpiarFiltros = () => {
		setSearchByDate(null);
		setTituloNoticiaBuscado("");
	};

	return (
		<CategoriaSeleccionadasContext.Provider
			value={{
				categoriasSeleccionadas,
				setCategoriasSeleccionadas,
			}}
		>
			<OrdenamientoSeleccionadoContext.Provider
				value={{ ordenamientoSeleccionado, setOrdenamientoSeleccionado }}
			>
				<Container fluid='sm' className='links-container'>
					<Link to='/' className='link-component'>
						Inicio
					</Link>
					{" > "}
					<Link to='/noticias' className='link-component'>
						Noticias
					</Link>
				</Container>
				<MainLayout
					title={datosPagina.TituloLabel}
					componentesLaterales={[
						<CalendarNoticias
							setSearchByDate={setSearchByDate}
							selectedDay={searchByDate}
						/>,
						<LimpiarFiltrosButton limpiarFiltros={limpiarFiltros} />,
						<OrdenNoticias subtitulo={datosPagina.OrdenLabel} />,
						<CategoriasNoticias subtitulo={datosPagina.CategoriaLabel} />,
						<SearchNoticias
							setTituloNoticia={setTituloNoticiaBuscado}
							currentValue={tituloNoticiaBuscado}
						/>,
					]}
					MainContent={<ListaNoticias Noticias={noticiasState} />}
				/>
				<Paginacion
					mostrarMasResultados={mostrarMasResultados}
					setPaginaActual={cambiarPaginaActual}
					paginaActual={paginaActual}
					cantidadPaginas={cantidadPaginas}
					mostrarMasLabel={datosPagina.MostrarMasLabel}
				/>
			</OrdenamientoSeleccionadoContext.Provider>
		</CategoriaSeleccionadasContext.Provider>
	);
};
