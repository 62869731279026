import React, { useState } from "react";
import { Form, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

import { SearchBox } from "../SearchBox/SearchBox";
import search from "../../../../assets/icons/search/search-white.svg";

import styles from "./styles.module.css";

export const SideMenu = ({
	expand,
	datosNavbar,
	show,
	toggleShow,
	flipArrow,
	setActivateDropdownInstituciones,
	activateDropdownInstituciones,
}) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<Navbar.Offcanvas
			// show={show}
			id={`offcanvasNavbar-expand-${expand}`}
			aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
			placement='end'
			variant='dark'
			className={`text-bg-dark ${styles.side_menu}`}
			scroll
		>
			<Offcanvas.Header
				closeButton
				closeVariant='white'
				className='justify-content-end flex pb-0'
			></Offcanvas.Header>
			<Offcanvas.Body className={styles.offcanvas_body_container}>
				<Nav className='justify-content-end pe-3'>
					<Nav.Link
						to='/artesanos'
						as={Link}
						className={[styles.big_nav_item, styles.border_bottom]}
						onClick={toggleShow}
					>
						<span>{datosNavbar.ArtesanosLabel}</span>
					</Nav.Link>
					<Nav.Link
						to='/historias'
						as={Link}
						className={[styles.big_nav_item, styles.border_bottom]}
						onClick={toggleShow}
					>
						<span>{datosNavbar.HistoriaLabel}</span>
					</Nav.Link>
					<Nav.Link
						to='/noticias'
						as={Link}
						className={[styles.big_nav_item, styles.border_bottom]}
						onClick={toggleShow}
					>
						<span>{datosNavbar.NoticiaLabel}</span>
					</Nav.Link>

					<NavDropdown
						className={[styles.big_nav_item, styles.programas_sub_menu]}
						title={
							<>
								<span>{datosNavbar.ProgramasLabel}</span>
								<span
									className={isShown ? styles.arrow_no_flip : styles.arrow_flip}
								>
									^
								</span>
							</>
						}
						onToggle={(nextValue) => setIsShown(nextValue)}
					>
						<NavDropdown.Item
							className={[styles.sub_menu_item, "TextoEnlinea"]}
							as={Link}
							to='/programas/PremioNacionalArtesanias'
							onClick={toggleShow}
						>
							{datosNavbar.PremioNacionalArtesaniaLabel}
						</NavDropdown.Item>
						<NavDropdown.Item
							className={[styles.sub_menu_item, "TextoEnlinea"]}
							to='/programas/ArtesaniasALaCancha'
							as={Link}
							onClick={toggleShow}
						>
							{datosNavbar.ArtesaniasCanchasLabel}
						</NavDropdown.Item>
						<NavDropdown.Item
							className={[styles.sub_menu_item, "TextoEnlinea"]}
							to='/programas/CasasDeArtesania'
							as={Link}
							onClick={toggleShow}
						>
							{datosNavbar.CasasArtesaniaLabel}
						</NavDropdown.Item>
						<NavDropdown.Item
							className={[styles.sub_menu_item, "TextoEnlinea"]}
							to='/programas/TalleresDisenio'
							as={Link}
							onClick={toggleShow}
						>
							{datosNavbar.TalleresDisenioLabel}
						</NavDropdown.Item>
					</NavDropdown>

					<NavDropdown.Item
						as={Link}
						to='/RegistroNacional'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.RegistroNacionalLabel}
					</NavDropdown.Item>
					<NavDropdown.Item
						as={Link}
						to='/ParticipacionInternacional'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.ParticipacionInternacional}
					</NavDropdown.Item>
					<NavDropdown.Item
						as={Link}
						to='/Indicadores'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.Indicadores}
					</NavDropdown.Item>

					<NavDropdown.Item
						as={Link}
						to='/Postulaciones'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.PostulacionesLabel}
					</NavDropdown.Item>
					<NavDropdown.Item
						as={Link}
						to='/FeriasYEventos'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.FeriasYEventosLabel}
					</NavDropdown.Item>
					<NavDropdown.Item
						as={Link}
						to='/PlanEstrategico'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.PlanEntrategicoLabel}
					</NavDropdown.Item>

					<NavDropdown.Item
						as={Link}
						to='/ActaDeComisiones'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.ActaComisionesLabel}
					</NavDropdown.Item>
					<NavDropdown.Item
						as={Link}
						to='/Dinapyme'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.DinapymeLabel}
					</NavDropdown.Item>
					<NavDropdown.Item
						as={Link}
						to='/PreguntasFrecuentes'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.PreguntasFrecuentesLabel}
					</NavDropdown.Item>
					<NavDropdown.Item
						as={Link}
						to='/AcercaDeAU'
						className='mb-2'
						onClick={toggleShow}
					>
						{datosNavbar.AcercaDeAU}
					</NavDropdown.Item>
				</Nav>
				<Form className='d-flex'>
					<SearchBox
						img={search}
						placeholder={datosNavbar.BuscadorTexto}
						BuscarMobile={datosNavbar.BuscarMobile}
						closeMenu={toggleShow}
					/>
				</Form>
			</Offcanvas.Body>
		</Navbar.Offcanvas>
	);
};
