import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCollectionData } from "../helpers/getCollectionData";
import { Rutas } from "../routes/Routes";

export const useGetLabelsInstitucionales = () => {
  const [seccionesInstitucionales, setSeccionesInstitucionales] = useState([]);
  const estadoIdioma = useSelector((state) => state.idioma);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const obtenerDatos = async () => {
        const {
          PremioNacionalArtesania,
          ArtesaniaCancha,
          CasasArtesania,
          PlanEstrategico,
          ActaComisiones,
          Dinapyme,
          PreguntasFrecuentes,
          TalleresDisenioLabel,
          PostulacionesLabel,
          FeriasYEventosLabel,
          RegistroNacionalLabel,
          ParticipacionInternacional,
          AcercaDeAU,
          Indicadores
        } = (
          await getCollectionData("labels-institucionals", "", estadoIdioma, 1)
        ).data[0].attributes;

        setSeccionesInstitucionales([
          {
            Titulo: PremioNacionalArtesania,
            Ruta: "/programas/PremioNacionalArtesanias",
          },
          {
            Titulo: ArtesaniaCancha,
            Ruta: "/programas/ArtesaniasALaCancha",
          },
          {
            Titulo: CasasArtesania,
            Ruta: "/programas/CasasDeArtesania",
          },
          {
            Titulo: PlanEstrategico,
            Ruta: "/PlanEstrategico",
          },
          {
            Titulo: ActaComisiones,
            Ruta: "/ActaDeComisiones",
          },
          {
            Titulo: Dinapyme,
            Ruta: "/Dinapyme",
          },
          {
            Titulo: PreguntasFrecuentes,
            Ruta: "/PreguntasFrecuentes",
          },
          {
            Titulo: TalleresDisenioLabel,
            Ruta: "/programas/TalleresDisenio",
          },
          {
            Titulo: PostulacionesLabel,
            Ruta: "/Postulaciones",
          },
          {
            Titulo: FeriasYEventosLabel,
            Ruta: "/FeriasYEventos",
          },
          {
            Titulo: RegistroNacionalLabel,
            Ruta: "/RegistroNacional",
          },
          {
            Titulo: ParticipacionInternacional,
            Ruta: "/ParticipacionInternacional",
          },
          {
            Titulo: Indicadores,
            Ruta: Rutas.Indicadores,
          },
          {
            Titulo: AcercaDeAU,
            Ruta: "/AcercaDeAU",
          },
        ]);
      };
      obtenerDatos();
    }
    return () => (isMounted = false);
  }, [estadoIdioma]);

  return { seccionesInstitucionales };
};
