import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";

import "./Navbar.css";

import logoWhite from "../../assets/logo-au_2022-02-12/logo-au.svg";
import searchBlack from "../../assets/icons/search/search-black.svg";
import logoBlack from "../../assets/logo-au-black_2022-02-12/logo-au-black.svg";

import search from "../../assets/icons/search/search-white.svg";
import { cambiarIdiomaActual } from "../../actions/Idioma";
import { SideMenu, SearchBox } from "./components";
import { useWindowsSize } from "../../hooks/useWindowsSize";
import { WindowSizeType } from "../../types/WindowSizeType";
import useIdiomas from "./hooks/useIdiomas";
import { Rutas } from "../../routes/Routes";

const DesktopNavbar = ({
	isWhiteNavbar,
	datosNavbar = {
		ArtesanosLabel: "",
		HistoriaLabel: "",
		NoticiaLabel: "",
		Institucional: "",
		ProgramasLabel: "",
		ArtesaniasCanchasLabel: "",
		CasasArtesaniaLabel: "",
		PlanEntrategicoLabel: "",
		ActaComisionesLabel: "",
		DinapymeLabel: "",
		PostulacionesLabel: "",
		PreguntasFrecuentesLabel: "",
		PremioNacionalArtesaniaLabel: "",
		BuscadorTexto: "",
		TalleresDisenioLabel: "",
		FeriasYEventosLabel: "",
		BuscarMobile: "",
		RegistroNacionalLabel: "",
		ParticipacionInternacional: "",
		AcercaDeAU: "",
		Indicadores: "",
	},
}) => {
	const [show, toggleShow] = useState(false);

	const [activateDropdownLanguage, setActivateDropdownLanguage] =
		useState(false);

	const [activateDropdownInstituciones, setActivateDropdownInstituciones] =
		useState(false);

	const dispatch = useDispatch();

	const idiomaSeleccionado = useSelector((state) => state.idioma);

	const { idiomasDisponibles } = useIdiomas();

	const flipArrow = (direction) => {
		let arrow = document.getElementById("programasIcon");
		if (direction === "up") {
			arrow.classList.remove("arrow-flip");
		} else {
			arrow.classList.toggle("arrow-flip");
		}
	};

	const procesarNombreIdioma = (codigoIdioma = "") => {
		return codigoIdioma
			.charAt(0)
			.toUpperCase()
			.concat(codigoIdioma.slice(1, 2));
	};

	const currentSize = useWindowsSize();

	const expand = "lg";

	const toggleRef = useRef(null);

	const closeMenu = () => {
		toggleRef.current.click();
	};

	return (
		<Navbar
			key={expand}
			expand={expand}
			className={`mb-3 ${
				isWhiteNavbar && currentSize !== WindowSizeType.lg
					? "border-bottom-black"
					: ""
			}`}
			fixed='top'
			bg={isWhiteNavbar ? "white" : "transparent"}
			variant={isWhiteNavbar ? "dark" : "light"}
		>
			<Container fluid='sm'>
				<Navbar.Brand href='#'>
					<Link to='/'>
						<img
							id='navLogo'
							src={isWhiteNavbar ? logoBlack : logoWhite}
							alt='AU Logo'
							className={"navBarLogo imagen-logo-navbar"}
						/>
					</Link>
				</Navbar.Brand>

				{currentSize === WindowSizeType.md ||
				currentSize === WindowSizeType.sm ||
				currentSize === WindowSizeType.xs ? (
					<>
						<div className='d-flex'>
							<NavDropdown
								className='languages'
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									color: isWhiteNavbar ? "black" : "white",
								}}
								title={
									idiomaSeleccionado
										? `${procesarNombreIdioma(idiomaSeleccionado.code)}`
										: "-"
								}
								id='navDropdown-Lang'
								eventkey={2}
								show={activateDropdownLanguage}
								onMouseEnter={() => setActivateDropdownLanguage(true)}
								onMouseLeave={() => setActivateDropdownLanguage(false)}
							>
								{idiomasDisponibles.map((idioma, i) => (
									<NavDropdown.Item
										key={i}
										onClick={() => dispatch(cambiarIdiomaActual(idioma.code))}
										style={{ color: "white" }}
									>
										{procesarNombreIdioma(idioma.code)}
									</NavDropdown.Item>
								))}
							</NavDropdown>
							<Navbar.Toggle
								aria-controls={`offcanvasNavbar-expand-${expand}`}
								ref={toggleRef}
							/>
						</div>
						<SideMenu
							toggleRef={toggleRef}
							datosNavbar={datosNavbar}
							activateDropdownInstituciones={activateDropdownInstituciones}
							expand={expand}
							flipArrow={flipArrow}
							setActivateDropdownInstituciones={
								setActivateDropdownInstituciones
							}
							show={show}
							toggleShow={closeMenu}
							className='justify-content-end flex'
						/>
					</>
				) : (
					<Nav className='justify-content-end flex'>
						<>
							<Nav.Link
								to='/artesanos'
								as={Link}
								className={`link-navbar ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
								active={false}
							>
								<span>{datosNavbar.ArtesanosLabel}</span>
							</Nav.Link>
							<div
								className={`link-navbar ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
							>
								|
							</div>
							<Nav.Link
								to='/historias'
								as={Link}
								className={`link-navbar ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
								active={false}
							>
								<span>{datosNavbar.HistoriaLabel}</span>
							</Nav.Link>
							<div
								className={`link-navbar ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
							>
								|
							</div>

							<Nav.Link
								to='/noticias'
								as={Link}
								className={`link-navbar ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
								active={false}
							>
								<span>{datosNavbar.NoticiaLabel}</span>
							</Nav.Link>
							<div
								className={`link-navbar ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
							>
								|
							</div>

							<NavDropdown
								className={`subMenu_toggle ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
								title={datosNavbar.Institucional}
								eventkey={0}
								show={show}
								onMouseEnter={() => {
									toggleShow(true);
								}}
								onMouseLeave={() => {
									flipArrow("up");
									toggleShow(false);
									setActivateDropdownInstituciones(false);
								}}
							>
								<NavDropdown
									title={
										<span>
											<span style={{ color: "white" }}>
												{datosNavbar.ProgramasLabel}
											</span>
											<span
												style={{ color: "white", marginLeft: "5px" }}
												id='programasIcon'
											>
												^
											</span>
										</span>
									}
									id='navDropdown-Progs'
									className='subMenu'
									eventkey={1}
									show={activateDropdownInstituciones}
									onClick={() => {
										setActivateDropdownInstituciones(
											!activateDropdownInstituciones
										);
										flipArrow();
									}}
								>
									<NavDropdown.Item
										className='TextoEnlinea'
										as={Link}
										to='/programas/PremioNacionalArtesanias'
									>
										{datosNavbar.PremioNacionalArtesaniaLabel}
									</NavDropdown.Item>
									<NavDropdown.Item
										className='TextoEnlinea'
										to='/programas/ArtesaniasALaCancha'
										as={Link}
									>
										{datosNavbar.ArtesaniasCanchasLabel}
									</NavDropdown.Item>
									<NavDropdown.Item
										className='TextoEnlinea'
										to='/programas/CasasDeArtesania'
										as={Link}
									>
										{datosNavbar.CasasArtesaniaLabel}
									</NavDropdown.Item>
									<NavDropdown.Item
										className='TextoEnlinea'
										to='/programas/TalleresDisenio'
										as={Link}
									>
										{datosNavbar.TalleresDisenioLabel}
									</NavDropdown.Item>
								</NavDropdown>

								<NavDropdown.Item as={Link} to='/RegistroNacional'>
									{datosNavbar.RegistroNacionalLabel}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/ParticipacionInternacional'>
									{datosNavbar.ParticipacionInternacional}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to={Rutas.Indicadores}>
									{datosNavbar.Indicadores}
								</NavDropdown.Item>

								<NavDropdown.Item as={Link} to='/Postulaciones'>
									{datosNavbar.PostulacionesLabel}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/FeriasYEventos'>
									{datosNavbar.FeriasYEventosLabel}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/PlanEstrategico'>
									{datosNavbar.PlanEntrategicoLabel}
								</NavDropdown.Item>

								<NavDropdown.Item as={Link} to='/ActaDeComisiones'>
									{datosNavbar.ActaComisionesLabel}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/Dinapyme'>
									{datosNavbar.DinapymeLabel}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/PreguntasFrecuentes'>
									{datosNavbar.PreguntasFrecuentesLabel}
								</NavDropdown.Item>
								<NavDropdown.Item as={Link} to='/AcercaDeAU'>
									{datosNavbar.AcercaDeAU}
								</NavDropdown.Item>
							</NavDropdown>
							<div
								className={`link-navbar ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
							>
								|
							</div>

							<NavDropdown
								className={`languages subMenu_toggle ${
									isWhiteNavbar ? "black-nav" : "white-nav"
								}`}
								title={
									idiomaSeleccionado
										? `${procesarNombreIdioma(idiomaSeleccionado.code)}`
										: "-"
								}
								id='navDropdown-Lang'
								eventkey={2}
								show={activateDropdownLanguage}
								onMouseEnter={() => setActivateDropdownLanguage(true)}
								onMouseLeave={() => setActivateDropdownLanguage(false)}
							>
								{idiomasDisponibles.map((idioma, i) => (
									<NavDropdown.Item
										key={i}
										onClick={() => dispatch(cambiarIdiomaActual(idioma.code))}
									>
										{procesarNombreIdioma(idioma.code)}
									</NavDropdown.Item>
								))}
							</NavDropdown>
							<Nav.Link>
								<SearchBox
									img={isWhiteNavbar ? searchBlack : search}
									placeholder={datosNavbar.BuscadorTexto}
								/>
							</Nav.Link>
						</>
					</Nav>
				)}
			</Container>
		</Navbar>
	);
};

export default DesktopNavbar;
