import { Form } from "react-bootstrap";
import React from "react";

export const CheckDropdownItem = React.forwardRef(
	({ children, id, onClick }, ref) => {
		return (
			<Form.Group
				ref={ref}
				className='dropdown-item mb-2 texto-check-mobile boton-check-mobile dropdown-item-checkbox-menu'
				controlId={id}
			>
				<input
					type='checkbox'
					onClick={onClick}
					// style={{ backgroundColor: "#333" }}
				/>
				<label className='input-label'>{children}</label>
			</Form.Group>
		);
	}
);
