import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import rightArrow from "../../../../../assets/icons/arrow-black-right/arrow-black-right.svg";
import leftArrow from "../../../../../assets/icons/arrow-black-left/arrow-black-left.svg";
import dwldBtn from "../../../../../assets/icons/arrow-black-right/arrow-black-right.svg";
import closeBtn from "../../../../../assets/icons/close-button-black/close-button-black.svg";
import expandBtn from "../../../../../assets/icons/cuadrado-expandir/cuadrado-expandir.svg";

import { useWindowsSize } from "../../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../../types/WindowSizeType";

const nI = <img src={rightArrow} alt='flecha siguiente' />;
const pI = <img src={leftArrow} alt='flecha anterior' />;
const DownArrow = (
	<img className='flecha-descargar' src={dwldBtn} alt='flecha descargar' />
);
const closeButtonImage = <img src={closeBtn} alt='botón cerrar' />;
const cuadradoExpandir = <img src={expandBtn} alt='botón expandir' />;

export const AficheChico = ({
	descargarAfiche,
	previousPage,
	nextPage,
	handleCerrarAfiche,
	descripcionState,
	portadaState,
	setAficheExpandido,
	datosPagina = {},
}) => {
	const ExpandirImagen = () => {
		setAficheExpandido(true);
	};

	const currentSize = useWindowsSize();

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<>
					<Container fluid='sm' className='links-container'>
						<Link to='/' className='link-component '>
							Inicio
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias'
							className='link-component'
						>
							Premio Nac...
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias/Afiches'
							className='link-component'
						>
							Afiches
						</Link>

						<div className='title'>{datosPagina.TituloLabel}</div>
						<hr className='hr-main-layout' />

						<div className='row contenedor-flechas-pdf'>
							<div className='col-6'>
								<div>
									<button
										className='botones-pdf float-start'
										onClick={descargarAfiche}
										style={{ display: "flex", alignItems: "stretch" }}
									>
										<div style={{ display: "flex", marginRight: 10 }}>
											{DownArrow}
										</div>
										<p style={{ margin: 0, textAlign: "start" }}>
											{datosPagina.DescargarLabel}
										</p>
									</button>
									<div></div>
								</div>
							</div>
							<div className='col-6'>
								<div className='float-end'>
									<button
										onClick={previousPage}
										className='botones-pdf botones-flechas-pdf'
									>
										{pI}
									</button>
									<button
										onClick={nextPage}
										className='botones-pdf botones-flechas-pdf'
									>
										{nI}
									</button>
								</div>
							</div>
						</div>
						<div>
							<img
								className='imagen-afiche col mt-4'
								src={`${process.env.REACT_APP_BASE_URL}${portadaState.url}`}
								alt='portada'
							/>
							<div className='contenido-lateral-afiche'>
								<h5 className='box-title'>{datosPagina.SobreAutorLabel}</h5>
								<p className='contenido-autor-afiche'>{descripcionState}</p>
							</div>
						</div>
					</Container>
				</>
			) : (
				<>
					<div className='links-container container-sm'>
						<Link to='/' className='link-component '>
							Inicio
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias'
							className='link-component'
						>
							Premio Nacional de Artesanía
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias/Afiches'
							className='link-component'
						>
							Concurso de afiches
						</Link>

						<div className='title'>{datosPagina.TituloLabel}</div>
						<div style={{ zIndex: 1 }}>
							<div className='row contenedor-flechas-pdf'>
								<div className='col-6'>
									<button className='botones-pdf' onClick={descargarAfiche}>
										{DownArrow}
										{datosPagina.DescargarLabel}
									</button>
								</div>
								<div className='col-6'>
									<div className='float-end'>
										<button
											onClick={previousPage}
											className='botones-pdf botones-flechas-pdf'
										>
											{pI}
										</button>
										<button
											onClick={nextPage}
											className='botones-pdf botones-flechas-pdf'
										>
											{nI}
										</button>
										<button
											className='botones-pdf boton-expandir'
											onClick={ExpandirImagen}
										>
											{cuadradoExpandir}
										</button>
										<button
											className='botones-pdf boton-cerrar-pdf'
											onClick={handleCerrarAfiche}
										>
											{closeButtonImage}
										</button>
									</div>
								</div>
							</div>
							<div className='wrapper'>
								<div className='col-3 contenido-lateral-afiche'>
									<h5 className='box-title'>{datosPagina.SobreAutorLabel}</h5>
									<p className='contenido-autor-afiche'>{descripcionState}</p>
								</div>
								<div className='col-1'></div>
								<div className='col'>
									<img
										className='imagen-afiche'
										src={`${process.env.REACT_APP_BASE_URL}${portadaState.url}`}
										alt='portada'
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};
