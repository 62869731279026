import { eliminaRepetidosArray } from "../../../../helpers/eliminaRepetidosArray";
import { searchData } from "./searchData";

export const obtenerArtesanos = async (
  paginaActual = 1,
  cantidadResultados = 5,
  nombreBuscado = "",
  idioma
) => {
  const qs = require("qs");
  const query = qs.stringify({
    sort: ["createdAt:desc"],
    pagination: {
      page: paginaActual,
      pageSize: cantidadResultados,
    },
    filters: {
      Nombre: { $containsi: nombreBuscado },
    },
    populate: ["Imagen", "Imagen.Imagen"],
    fields: ["Descripcion", "Nombre", "publishedAt"],
  });

  const { data } = await searchData(
    "artesanos",
    query,
    idioma
  );

  const ArtesanosTotalSinRepetidos = eliminaRepetidosArray(data);

  return {
    dataArtesanos: ArtesanosTotalSinRepetidos,
    pageCountArtesanos: ArtesanosTotalSinRepetidos.length / cantidadResultados,
  };
};
