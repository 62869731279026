import React from "react";

import { IndicadorItem } from "../IndicadorItem";

export const ListaIndicadores = ({ indicadores = [] }) => {
	return (
		<div className='row'>
			{indicadores.map((indicador) => (
				<div className='col-lg-4 col-md-6 col-sm-6 col-6' key={indicador.id}>
					<IndicadorItem indicador={indicador} />
				</div>
			))}
		</div>
	);
};
