import { eliminaRepetidosArray } from "../../../../helpers/eliminaRepetidosArray";
import { searchData } from "./searchData";

export const obtenerNoticias = async (
  paginaActual = 1,
  cantidadResultados = 5,
  tituloBuscado = "",
  idioma
) => {
  // const categoriasDeNoticias = await obtenerNoticiasPorCategoria(tituloBuscado, idioma);
  // let noticiaPorCategoria = categoriasDeNoticias.data.length !== 0 ? categoriasDeNoticias.data[0].attributes.noticias.data : [];

  const qs = require("qs");
  const query = qs.stringify({
    sort: ["createdAt:desc"],
    pagination: {
      page: paginaActual,
      pageSize: cantidadResultados,
    },
    filters: {
      Titulo: { $containsi: tituloBuscado },
    },
    populate: ["Portada.Imagen"],
    fields: ["Contenido", "Fecha", "Titulo", 'slug', 'Resumen'],
  });

  const { data } = await searchData(
    "noticias",
    query,
    idioma
  );

  const noticiaTotalSinRepetidos = eliminaRepetidosArray(data);

  return {
    dataNoticias: noticiaTotalSinRepetidos,
    pageCountNoticias: noticiaTotalSinRepetidos.length / cantidadResultados,
  };
};
