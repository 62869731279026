import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetchTiposArtesanos = () => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  const idiomaState = useSelector((state) => state.idioma);

  useEffect(() => {
    let isActive = true;

    const base_url = `${process.env.REACT_APP_BASE_URL}`;
    const qs = require("qs");
    const query = qs.stringify({
      populate: ["artesanos"],
    });

    if (isActive) {

      const request = async () => {
        const urlCompleta = `${base_url}/api/tipos-artesanos?${query}&locale=${idiomaState.code}`;
        const resp = await fetch(urlCompleta);
        const dataRest = await resp.json();
        setState({ data: dataRest.data, loading: false });
      }
      request();
    }
    return () => (isActive = false);
  }, [idiomaState]);

  return state;
};
