import React, { useEffect, useState } from "react";
import styles from "./Calendar.module.css";
import LoadingSpinner from "../../shared/LoadingSpinner/LoadingSpinner";

const monthNames = [
	"Enero",
	"Febrero",
	"Marzo",
	"Abril",
	"Mayo",
	"Junio",
	"Julio",
	"Agosto",
	"Septiembre",
	"Octubre",
	"Noviembre",
	"Diciembre",
];

export const Calendar = ({
	isLoading = false,
	value,
	setValue,
	markedDays,
	onElementClick,
	selectedDay,
}) => {
	const [calendar, setCalendar] = useState([]);

	useEffect(() => {
		const startDay = value.clone().startOf("month").startOf("week");
		const endDay = value.clone().endOf("month").endOf("week");
		const day = startDay.clone().subtract(1, "day");

		const tempCalendar = [];

		while (day.isBefore(endDay, "day")) {
			tempCalendar.push(
				Array(7)
					.fill(0)
					.map(() => day.add(1, "day").clone())
			);
		}

		setCalendar(tempCalendar);
	}, [value]);

	const modifyMonth = (num) => {
		const newValue = value.clone().subtract(num, "month");
		setValue(newValue);
	};

	return (
		<div className='component-box col-12'>
			<div className={`${styles.container}`}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: 10,
						marginTop: 10,
					}}
				>
					<div style={{ cursor: "pointer" }} onClick={() => modifyMonth(1)}>
						{"<"}
					</div>
					<div className={`${styles.texto_mes}`}>{`${
						monthNames[value.month()]
					} ${value.year()}`}</div>
					<div style={{ cursor: "pointer" }} onClick={() => modifyMonth(-1)}>
						{">"}
					</div>
				</div>
				<div className={`${styles.row}`}>
					{["D", "L", "M", "M", "J", "V", "S"].map((day, i) => (
						<div key={i + day} className={`${styles.element_container}`}>
							{day}
						</div>
					))}
				</div>
				{isLoading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flex: 1,
						}}
					>
						<LoadingSpinner />
					</div>
				) : (
					<>
						{calendar.map((week, i) => (
							<div key={i + week} className={`${styles.row}`}>
								{week.map((day, index) => (
									<div key={index} className={`${styles.element_container}`}>
										{day.month() === value.month() && (
											<div
												className={`${styles.dia_container} ${
													selectedDay === day
														? styles.dia_seleccionado
														: markedDays.includes(day.format("D").toString())
														? styles.dia_negro
														: styles.dia_blanco
												}`}
												onClick={() =>
													onElementClick(selectedDay === day ? null : day)
												}
											>
												{day.format("D").toString()}
											</div>
										)}
									</div>
								))}
							</div>
						))}
					</>
				)}
			</div>
		</div>
	);
};
