import React from "react";
import { Routes, Route } from "react-router-dom";

import { ArtesanosView } from "./components/Artesanos/ArtesanosView";
import { HistoriasView } from "./components/Historias/HistoriasView";
import { NoticiasView } from "./components/Noticias/NoticiasView";
import { PerfilArtesano } from "./components/PerfilArtesano/PerfilArtesano";
import { PerfilHistoria } from "./components/PerfilHistoria/PerfilHistoria";
import { PerfilNoticia } from "./components/PerfilNoticia/PerfilNoticia";
import { Afiches } from "./components/Programas/PremioNacionalArtesania/Afiches/Afiches";
import { PerfilAfiche } from "./components/Programas/PremioNacionalArtesania/Afiches/PerfilAfiche/PerfilAfiche";
import { EdicionesLibros } from "./components/Programas/PremioNacionalArtesania/EdicionesLibros/EdicionesLibros";
import { PerfilLibro } from "./components/Programas/PremioNacionalArtesania/EdicionesLibros/PerfilLibro/PerfilLibro";
import { PremioNacionalArtesania } from "./components/Programas/PremioNacionalArtesania/PremioNacionalArtesania";
import Home from "./Home/Home";
import { InstitucionalTypes } from "./types/InstitucionalTypes";
import { SeccionProgramas } from "./components/Programas/SeccionProgramas";
import { ScrollToTop } from "./helpers/scrollToTop";
import { BusquedaPorTag } from "./components/Resultados/BusquedaPorTags/BusquedaPorTag";
import { BusquedaSimple } from "./components/Resultados/BusquedaSimple/BusquedaSimple";
import { DescargarArchivo } from "./components/DescargarArchivos/DescargarArchivo";
import { BusquedaCategoriasHome } from "./components/Resultados/BusquedaCatagoriasHome/BusquedaCategoriasHome";
import { Institucional } from "./components/shared/Institucional/Institucional";
import { Page404 } from "./components/shared/Page404/Page404";
import { TalleresDeDisenio } from "./components/Programas/TalleresDisenio/TalleresDeDisenio";
import { Rutas } from "./routes/Routes";
import { Indicadores } from "./components/Programas/Indicadores/GrillaIndicadores";
import { PerfilIndicador } from "./components/Programas/Indicadores/PerfilIndicador";

export const AppRoutes = () => {
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route path='*' element={<Page404 />} />

				<Route path='/artesanos' element={<ArtesanosView />} />
				<Route path='/historias' element={<HistoriasView />} />
				<Route path='/noticias' element={<NoticiasView />} />
				<Route path='/programas' element={<NoticiasView />} />
				<Route
					path='/programas/PremioNacionalArtesanias'
					element={<PremioNacionalArtesania />}
				/>
				<Route
					path='/programas/PremioNacionalArtesanias/Afiches'
					element={<Afiches />}
				/>
				<Route
					path='/programas/PremioNacionalArtesanias/Afiches/:aficheId'
					element={<PerfilAfiche />}
				/>
				<Route
					path='/programas/PremioNacionalArtesanias/EdicionesLibros'
					element={<EdicionesLibros />}
				/>
				<Route
					path='/programas/PremioNacionalArtesanias/EdicionesLibros/:libroId'
					element={<PerfilLibro />}
				/>
				<Route
					path='/resultadosSimple/:busqueda'
					element={<BusquedaSimple />}
				/>
				<Route
					path='/resultadosPorTag/:busqueda'
					element={<BusquedaPorTag />}
				/>
				<Route
					path='/resultadosPorCategoria/:busqueda'
					element={<BusquedaCategoriasHome />}
				/>

				<Route
					path='/programas/ArtesaniasALaCancha'
					element={
						<SeccionProgramas
							tipoInstitucion={InstitucionalTypes.ArtesaniasALaCancha}
						/>
					}
				/>
				<Route
					path='/programas/CasasDeArtesania'
					element={
						<SeccionProgramas
							tipoInstitucion={InstitucionalTypes.CasasDeArtesania}
						/>
					}
				/>
				<Route
					path='/programas/TalleresDisenio'
					element={<TalleresDeDisenio />}
				/>
				<Route
					path='/ActaDeComisiones'
					element={
						<Institucional
							tipoInstitucion={InstitucionalTypes.ActaDeComision}
						/>
					}
				/>
				<Route
					path='/Dinapyme'
					element={
						<Institucional tipoInstitucion={InstitucionalTypes.Dinapyme} />
					}
				/>
				<Route
					path='/Postulaciones'
					element={
						<Institucional tipoInstitucion={InstitucionalTypes.Postulaciones} />
					}
				/>
				<Route
					path='/PlanEstrategico'
					element={
						<Institucional
							tipoInstitucion={InstitucionalTypes.PlanEstrategico}
						/>
					}
				/>
				<Route
					path='/PreguntasFrecuentes'
					element={
						<Institucional
							tipoInstitucion={InstitucionalTypes.PreguntasFrecuentes}
						/>
					}
				/>
				<Route
					path='/FeriasYEventos'
					element={
						<Institucional
							tipoInstitucion={InstitucionalTypes.FeriasYEventos}
						/>
					}
				/>
				<Route
					path='/RegistroNacional'
					element={
						<Institucional
							tipoInstitucion={InstitucionalTypes.RegistroNacional}
						/>
					}
				/>
				<Route
					path='/ParticipacionInternacional'
					element={
						<Institucional
							tipoInstitucion={InstitucionalTypes.ParticipacionInternacional}
						/>
					}
				/>

				<Route
					path={Rutas.Indicadores}
					element={
						<Indicadores
							tipoInstitucion={InstitucionalTypes.ParticipacionInternacional}
						/>
					}
				/>

				<Route
					path={Rutas.PerfilIndicador + ":indicadorId"}
					element={<PerfilIndicador />}
				/>
				<Route
					path='/AcercaDeAU'
					element={
						<Institucional tipoInstitucion={InstitucionalTypes.AcercaDeAU} />
					}
				/>

				<Route path='/artesanos/:artesanoId' element={<PerfilArtesano />} />
				<Route path='/historias/:historiaId' element={<PerfilHistoria />} />
				<Route path='/noticias/:noticiaId' element={<PerfilNoticia />} />

				<Route
					path='/descargar/:nombreArchivo'
					element={<DescargarArchivo />}
				/>
				<Route
					path='/programas/descargar/:nombreArchivo'
					element={<DescargarArchivo />}
				/>
			</Routes>
		</>
	);
};
