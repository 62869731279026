import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

export const useFetchTalleresDisenio = () => {
    const [state, setState] = useState({
        data: {},
        loading: true,
    });
    const qs = require("qs");
    const query = qs.stringify({
        populate: ["Fondo"],
    });

    const idiomaState = useSelector((state) => state.idioma);
    useEffect(() => {
        let isActive = true;
        const base_url = `${process.env.REACT_APP_BASE_URL}`;
        if (isActive) {
            const obtener = async () => {
                const urlCompleta = `${base_url}/api/pagina-taller-disenios?${query}&locale=${idiomaState.code}`;
                const resp = await fetch(urlCompleta);
                const dataRest = await resp.json();
                if (dataRest.data) setState({ data: dataRest.data[0], loading: false });
                else setState({ loading: true });
            };
            obtener();
        }
        return () => (isActive = false);
    }, [idiomaState, query]);

    return state;
};
