import React from "react";
import { useNavigate } from "react-router-dom";

import { useWindowsSize } from "../../../../../hooks/useWindowsSize";
import { ordenamientos } from "../../../../../types/ordenamientos";
import { WindowSizeType } from "../../../../../types/WindowSizeType";
import { RadioButtonDropdown } from "../../../../shared/DropdownsItems/radioButton/RadioButtonDropdown";
import { SimpleDropdown } from "../../../../shared/DropdownsItems/Simple/SimpleDropdown";
import { CategoriasAfiche } from "./CategoriasAfiche";

export const LateralMenu = ({
	setOrdenamiento,
	subtitulo = "",
	EdicionesLibrosLabel = "",
	PremioNacionalArtesania = "",
	tituloDropdown = "",
	ordenamientoSeleccionado = "",
}) => {
	const navigate = useNavigate();
	const navigatePremioNacionalArtesania = () => {
		navigate("/programas/PremioNacionalArtesanias");
	};
	const navigateEdicionesLibros = () => {
		navigate("/programas/PremioNacionalArtesanias/EdicionesLibros");
	};

	const ordenar = [
		ordenamientos.MasReciente,
		ordenamientos.MenosRecientes,
		ordenamientos.Aleatorio,
	];

	const elementosLaterales = [
		{
			texto: PremioNacionalArtesania,
			onClick: navigatePremioNacionalArtesania,
		},
		{ texto: EdicionesLibrosLabel, onClick: navigateEdicionesLibros },
	];

	const handleInputChange = (e, ord) => {
		setOrdenamiento(ord);
	};

	const currentSize = useWindowsSize();

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='mb-4'>
					<div className='row mb-2'>
						<div className='col-6'>
							<SimpleDropdown
								items={elementosLaterales}
								title={tituloDropdown}
								funcionNavigate={navigatePremioNacionalArtesania}
							/>
						</div>
						<div className='col-6'>
							<RadioButtonDropdown
								handleInputChange={handleInputChange}
								items={ordenar}
								title={subtitulo}
								currentValue={ordenamientoSeleccionado}
							/>
						</div>
					</div>
					<div className='row mb-2'>
						<div className='col-6'>
							<CategoriasAfiche />
						</div>
					</div>
				</div>
			) : (
				<div className='lateral-menu'>
					<div>
						{elementosLaterales.map((elemento, i) => (
							<div className='lateral-items-libros' key={i}>
								<span onClick={elemento.onClick}>{elemento.texto}</span>
							</div>
						))}
					</div>
					<div className='component-box'>
						<h6 className='box-title'>{subtitulo}</h6>
						{ordenar.map((ord, i) => (
							<div key={i} className='mb-2'>
								<input
									type='radio'
									value={ord}
									name='orden'
									onChange={(e) => handleInputChange(e, ord)}
									checked={ord === ordenamientoSeleccionado}
								/>
								<label className='input-label'>{ord}</label>
							</div>
						))}
					</div>
					<div className='component-box'>
						<CategoriasAfiche />
					</div>
				</div>
			)}
		</>
	);
};
