import React from "react";
import "../PerfilArtesano.css";
import { SocialIcon } from "react-social-icons";
import ReactMarkdown from "react-markdown";
import { Container } from "react-bootstrap";

export const ArtesanoInfo = ({
	Rubro = "Rubro",
	Descripcion = "Descripción",
	InfoContacto = "Información de contacto",
	RedesSociales = [],
	NombreArtesano = "",
	RubroLabel = "",
	SobreAutoLabel = "",
	ContactoLabel = "",
}) => {
	return (
		<Container fluid='sm'>
			<div className='contenedor-info-lateral md-4'>
				<h5 className='subtitulo-lateral'>{RubroLabel}</h5>
				<p className='texto-lateral'>{Rubro}</p>
			</div>
			<div className='contenedor-info-lateral'>
				<h5 className='subtitulo-lateral'>{SobreAutoLabel}</h5>
				<p className='texto-lateral'>{Descripcion}</p>
			</div>
			<div className='contenedor-info-lateral'>
				<h5 className='subtitulo-lateral'>{ContactoLabel}</h5>
				<ReactMarkdown linkTarget='_blank' className='texto-lateral'>
					{InfoContacto}
				</ReactMarkdown>
			</div>
			<div className='contenedor-info-lateral socials socialIcon-container'>
				{RedesSociales.map((red) => (
					<SocialIcon
						target='_blank'
						key={red.id}
						url={red.Enlace}
						bgColor={"black"}
						style={{
							height: 24,
							width: 24,
							marginRight: 10,
						}}
					/>
				))}
				<SocialIcon
					target='_blank'
					url={`https://api.whatsapp.com/send?text=Visita el perfil de ${NombreArtesano}%20--> ${window.location.href}`}
					bgColor={"black"}
					style={{
						height: 24,
						width: 24,
						marginRight: 11,
					}}
				/>
			</div>
		</Container>
	);
};
