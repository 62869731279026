import React from "react";
import { useContext } from "react";

import { ordenamientos } from "../../../types/ordenamientos";
import {
	CategoriaSeleccionadasContext,
	OrdenamientoSeleccionadoContext,
} from "../NoticiasContext";
import styles from "./LimpiarFiltros.module.css";

export const LimpiarFiltrosButton = ({ limpiarFiltros }) => {
	const { setOrdenamientoSeleccionado } = useContext(
		OrdenamientoSeleccionadoContext
	);

	const { setCategoriasSeleccionadas } = useContext(
		CategoriaSeleccionadasContext
	);

	const borrarFiltros = () => {
		limpiarFiltros();
		setOrdenamientoSeleccionado(ordenamientos.MasReciente);
		setCategoriasSeleccionadas([]);
	};
	return (
		<div className={`col-12 ${styles.contenedor}`}>
			<span className={styles.limpiar_button} onClick={borrarFiltros}>
				Borrar filtros
			</span>
		</div>
	);
};
