import { useEffect } from "react";
import ReactGA from "react-ga4";

const usePageTracking = () => {

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_CODE);
        ReactGA.send({ hitType: "pageview", page: document.title });
    }, []);
};

export default usePageTracking;