import React from "react";

export const CheckboxMenu = React.forwardRef(
	(
		{
			children,
			style,
			className,
			// "aria-labelledby": labeledBy,
			// onSelectAll,
			// onSelectNone,
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				style={style}
				className={`${className} CheckboxMenu`}
			// aria-labelledby={labeledBy}
			>
				<div
					className="d-flex flex-column"
					style={{ maxHeight: "calc(100vh)", overflow: "none" }}
				>
					<ul
						className="list-unstyled flex-shrink mb-0"
						style={{ overflow: "auto" }}
					>
						{children}
					</ul>
				</div>
			</div>
		);
	}
);
