import QueryString from "qs";
import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCollectionData } from "../../helpers/getCollectionData";
import { obtenerOrdenamientoNoticias } from "../../helpers/ordenarArray";
import { ordenarArrayHistoriasPorImagenes } from "../../helpers/ordenarArrayHistoriasPorImagenes";
import usePageTracking from "../../hooks/usePageTracking";
import { useTitle } from "../../hooks/useTitle";
import { useWindowsSize } from "../../hooks/useWindowsSize";
import { ordenamientos } from "../../types/ordenamientos";
import { WindowSizeType } from "../../types/WindowSizeType";
import { MainLayout } from "../Layouts/MainLayout";
import { Paginacion } from "../shared/Paginacion/Paginacion";
import {
	CategoriaSeleccionadasContext,
	OrdenamientoSeleccionadoContext,
} from "./HistoriasContext";
import { CategoriasHistorias, OrdenHistorias } from "./LateralMenu";
import { ListaHistorias } from "./MainContent/ListaHistorias";

export const HistoriasView = () => {
	const datosPorPagina = 9;
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		CategoriasLabel: "",
		OrdenLabel: "",
		MostrarMasLabel: "Mostrar más",
	});

	useTitle(datosPagina.TituloLabel);
	usePageTracking();

	const [historiasState, setHistoriasState] = useState([]);
	const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState([]);
	const [ordenamientoSeleccionado, setOrdenamientoSeleccionado] = useState(
		ordenamientos.MasReciente
	);
	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadRegistrosAMostrar, setCantidadRegistrosAMostrar] =
		useState(datosPorPagina);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [cantidadFilasAMostrar, setCantidadFilasAMostrar] = useState(3);

	const [cantidadMostrarMas, setCantidadMostrarMas] = useState(0);

	const estadoIdioma = useSelector((state) => state.idioma);

	const windowSize = useWindowsSize();

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { TituloLabel, CategoriasLabel, OrdenLabel, MostrarMasLabel } = (
					await getCollectionData("pagina-historias", "", estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					CategoriasLabel: CategoriasLabel,
					OrdenLabel: OrdenLabel,
					MostrarMasLabel: MostrarMasLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	//* Usamos un useEffect para que la consulta no se haga cada vez que se reenderiza la página, sino cuando cambian los valores de filtros

	useEffect(() => {
		setCantidadFilasAMostrar(3);
		setCantidadRegistrosAMostrar(datosPorPagina);
		setPaginaActual(1);
		setCantidadMostrarMas(0);
	}, [categoriasSeleccionadas, ordenamientoSeleccionado]);

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté
		const ordenamiento = obtenerOrdenamientoNoticias(
			ordenamientoSeleccionado,
			"Autor_Autora"
		);

		const query = QueryString.stringify({
			sort: [ordenamiento],
			filters: {
				categorias_historia: {
					id: {
						$in: categoriasSeleccionadas,
					},
				},
			},
			pagination: {
				page: paginaActual,
				pageSize: cantidadRegistrosAMostrar,
			},
			populate: ["Portada", "Portada.Imagen", "categorias_historia"],
		});

		const obtenerHistoriasDB = async () => {
			const { data, paginas } = await getCollectionData(
				"historias",
				query,
				estadoIdioma
			);

			let cantidadColumnasPorFila = 3;
			switch (windowSize) {
				case WindowSizeType.xs:
					cantidadColumnasPorFila = 2;
					break;
				case WindowSizeType.md:
					cantidadColumnasPorFila = 2;
					break;
				case WindowSizeType.sm:
					cantidadColumnasPorFila = 2;
					break;
				default:
					cantidadColumnasPorFila = 3;
					break;
			}

			const historialFinal =
				ordenamientoSeleccionado === ""
					? ordenarArrayHistoriasPorImagenes(data, cantidadColumnasPorFila)
					: data;

			setHistoriasState(historialFinal);

			if (cantidadMostrarMas === 0) setCantidadPaginas(paginas);
		};
		obtenerHistoriasDB();
	}, [
		categoriasSeleccionadas,
		ordenamientoSeleccionado,
		paginaActual,
		estadoIdioma,
		windowSize,
		cantidadRegistrosAMostrar,
		cantidadMostrarMas,
	]);

	const mostrarMasResultados = () => {
		setCantidadRegistrosAMostrar(cantidadRegistrosAMostrar + datosPorPagina);
		setCantidadFilasAMostrar(cantidadFilasAMostrar + 3);
		setCantidadMostrarMas(cantidadMostrarMas + 1);
	};

	const cambiarPaginaActual = (pagina) => {
		setCantidadFilasAMostrar(3);
		setCantidadRegistrosAMostrar(datosPorPagina);
		setPaginaActual(pagina);
		setCantidadMostrarMas(0);
	};

	return (
		<>
			<CategoriaSeleccionadasContext.Provider
				value={{
					categoriasSeleccionadas,
					setCategoriasSeleccionadas,
				}}
			>
				<OrdenamientoSeleccionadoContext.Provider
					value={{ ordenamientoSeleccionado, setOrdenamientoSeleccionado }}
				>
					<Container fluid='sm' className='links-container'>
						<Link to='/' className='link-component'>
							Inicio
						</Link>
						{" > "}
						<Link to='/historias' className='link-component'>
							Historias
						</Link>
					</Container>
					<MainLayout
						title={datosPagina.TituloLabel}
						componentesLaterales={[
							<OrdenHistorias subtitulo={datosPagina.OrdenLabel} />,
							<CategoriasHistorias subtitulo={datosPagina.CategoriasLabel} />,
						]}
						MainContent={<ListaHistorias Historias={historiasState} />}
					/>
					<Paginacion
						mostrarMasResultados={mostrarMasResultados}
						setPaginaActual={cambiarPaginaActual}
						paginaActual={paginaActual}
						cantidadPaginas={cantidadPaginas}
						mostrarMasLabel={datosPagina.MostrarMasLabel}
					/>
				</OrdenamientoSeleccionadoContext.Provider>
			</CategoriaSeleccionadasContext.Provider>
		</>
	);
};
