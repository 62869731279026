import QueryString from "qs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCollectionData } from "../helpers/getCollectionData";

export const useFetchFooter = () => {
  const [state, setState] = useState({
    data: {
      InicioLabel: "",
      ArtesanosLabel: "",
      HistoriasLabel: "",
      NoticiasLabel: "",
      PremioNacionalArtesania: "",
      ArtesaniasCancha: "",
      CasasArtesania: "",
      PlanEstrategico: "",
      ActaComisiones: "",
      Dinapyme: "",
      Postulaciones: "",
      PreguntasFrecuentes: "",
      English: "",
      Espaniol: "",
      IngreseARegistroLabel: "",
      IrAlRegistro: "",
      TalleresDisenioLabel: "",
      DireccionYContacto: "",
      FeriasYEventos: "",
      Apoya: "",
      RedesSociales: [],
      EnlaceMiem: {},
      EnlaceUnirseArtesano: {},
      ApoyaIcons: [],
      RegistroNacional: '',
      ParticipacionInternacional: '',
      AcercaDeAU: '',
      Indicadores: ''
    },
    loading: true,
  });

  const estadoIdioma = useSelector((state) => state.idioma);

  useEffect(() => {
    let isActive = true;

    const query = QueryString.stringify({
      populate: ["ApoyaIcons", "ApoyaIcons.Imagen", "EnlaceMiem", "EnlaceUnirseArtesano", "RedesSociales"],
    });

    const getData = async () => {

      const {
        InicioLabel,
        ArtesanosLabel,
        HistoriasLabel,
        NoticiasLabel,
        PremioNacionalArtesania,
        ArtesaniasCancha,
        CasasArtesania,
        PlanEstrategico,
        ActaComisiones,
        Dinapyme,
        Postulaciones,
        PreguntasFrecuentes,
        English,
        Espaniol,
        IngreseARegistroLabel,
        IrAlRegistro,
        TalleresDisenioLabel,
        FeriasYEventos,
        Apoya,
        RedesSociales,
        EnlaceMiem,
        EnlaceUnirseArtesano,
        ApoyaIcons,
        RegistroNacional,
        ParticipacionInternacional,
        AcercaDeAU,
        Indicadores
      } = (await getCollectionData("footers", query, estadoIdioma, 1)).data[0].attributes;

      setState({
        loading: false,
        data: {
          InicioLabel: InicioLabel,
          ArtesanosLabel: ArtesanosLabel,
          HistoriasLabel: HistoriasLabel,
          NoticiasLabel: NoticiasLabel,
          PremioNacionalArtesania: PremioNacionalArtesania,
          ArtesaniasCancha: ArtesaniasCancha,
          CasasArtesania: CasasArtesania,
          PlanEstrategico: PlanEstrategico,
          ActaComisiones: ActaComisiones,
          Dinapyme: Dinapyme,
          Postulaciones: Postulaciones,
          PreguntasFrecuentes: PreguntasFrecuentes,
          English: English,
          Espaniol: Espaniol,
          IngreseARegistroLabel: IngreseARegistroLabel,
          IrAlRegistro: IrAlRegistro,
          TalleresDisenioLabel: TalleresDisenioLabel,
          FeriasYEventos: FeriasYEventos,
          RedesSociales: RedesSociales,
          EnlaceMiem: EnlaceMiem,
          EnlaceUnirseArtesano: EnlaceUnirseArtesano,
          Apoya: Apoya,
          ApoyaIcons: ApoyaIcons,
          RegistroNacional,
          ParticipacionInternacional,
          AcercaDeAU,
          Indicadores
        }
      })
    }
    if (isActive) {
      getData();
    }
    return () => (isActive = false);
  }, [estadoIdioma]);

  return state;
};
