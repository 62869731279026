import { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { getEntityById } from "../../helpers/getEntityById";
import { MainLayout } from "../Layouts/MainLayout";
import { ArtesanoInfo } from "./LateralMenu/ArtesanoInfo";
import { GaleriaArtesano } from "./MainContent/GaleriaArtesano";
import { Paginacion } from "../shared/Paginacion/Paginacion";
import "./PerfilArtesano.css";
import { getCollectionData } from "../../helpers/getCollectionData";
import usePageTracking from "../../hooks/usePageTracking";
import { useTitle } from "../../hooks/useTitle";
import { LazySugerirArtesanos } from "./MainContent/components/SugerirArtesanosLazy";

export const PerfilArtesano = () => {
	const { artesanoId } = useParams();

	const [artesano, setArtesano] = useState({});

	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadImagenesAMostrar, setCantidadRegistrosAMostrar] = useState(6);
	const [skipState, setSkipState] = useState(1);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [mostrarMasActivado, setMostrarMasActivado] = useState(false);
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		RubroLabel: "",
		SobreAutoLabel: "",
		ContactoLabel: "",
		MostrarMasLabel: "",
	});

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { RubroLabel, SobreAutoLabel, ContactoLabel, MostrarMasLabel } = (
					await getCollectionData(
						"pagina-perfil-artesanos",
						"",
						estadoIdioma,
						1
					)
				).data[0].attributes;

				setDatosPagina({
					RubroLabel: RubroLabel,
					SobreAutoLabel: SobreAutoLabel,
					ContactoLabel: ContactoLabel,
					MostrarMasLabel: MostrarMasLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		let isMounted = true;

		if (isMounted) {
			const obtenerArtesano = async () => {
				const artesanoBD = await getEntityById(
					"artesanos",
					artesanoId,
					["Imagen", "Imagen.Imagen", "RedSocial", "categorias_artesano"],
					estadoIdioma
				);

				setArtesano(artesanoBD);

				const pags = Math.ceil(Number(artesanoBD.Imagen.length) / 8);
				setCantidadPaginas(pags);
			};

			obtenerArtesano();
		}

		return () => (isMounted = false);
	}, [artesanoId, estadoIdioma]);

	const {
		Nombre,
		Rubro,
		Descripcion,
		InfoContacto,
		Imagen,
		RedSocial,
		categorias_artesano,
	} = artesano ?? {};

	useTitle(Nombre ?? "");
	usePageTracking();

	const mostrarMasResultados = () => {
		setCantidadRegistrosAMostrar(cantidadImagenesAMostrar + 5);
		setMostrarMasActivado(true);
	};

	const cambiarPaginaActual = (pagina) => {
		if (mostrarMasActivado) {
			setCantidadRegistrosAMostrar(5);
			setMostrarMasActivado(false);
		}
		setSkipState(pagina);
		setPaginaActual(pagina);
	};

	return (
		<>
			<Container fluid='sm' className='links-container'>
				<Link to='/' className='link-component'>
					Inicio
				</Link>
				{" > "}
				<Link to='/artesanos' className='link-component'>
					Artesanos
				</Link>
				{" > "}
				<Link to='/artesanos' className='link-component'>
					{Nombre}
				</Link>
			</Container>

			<MainLayout
				title={Nombre}
				componentesLaterales={[
					<ArtesanoInfo
						Rubro={Rubro}
						Descripcion={Descripcion}
						InfoContacto={InfoContacto}
						RedesSociales={RedSocial}
						NombreArtesano={Nombre}
						RubroLabel={datosPagina.RubroLabel}
						SobreAutoLabel={datosPagina.SobreAutoLabel}
						ContactoLabel={datosPagina.ContactoLabel}
					/>,
				]}
				MainContent={
					<GaleriaArtesano
						Imagen={Imagen}
						maxResults={cantidadImagenesAMostrar}
						skipResults={skipState}
					/>
				}
			/>
			{paginaActual === 1 && cantidadPaginas === 1 ? null : (
				<Paginacion
					mostrarMasResultados={mostrarMasResultados}
					setPaginaActual={cambiarPaginaActual}
					paginaActual={paginaActual}
					cantidadPaginas={cantidadPaginas}
					mostrarMasLabel={datosPagina.MostrarMasLabel}
				/>
			)}
			<LazySugerirArtesanos
				artesanoActualId={artesanoId}
				categoriasArtesano={categorias_artesano}
				idioma={estadoIdioma}
			/>
		</>
	);
};
