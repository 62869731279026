import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetchCategoriasHistoriasConCantidad = () => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });
  const base_url = `${process.env.REACT_APP_BASE_URL}`;
  const qs = require("qs");
  const query = qs.stringify({
    populate: ["historias"],
  });
  const idiomaState = useSelector((state) => state.idioma);

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      const obtenerCategorias = async () => {
        const urlCompleta = `${base_url}/api/categorias-historias?${query}&locale=${idiomaState.code}`;
        const resp = await fetch(urlCompleta);
        const dataRest = await resp.json();

        if (dataRest.error) {
          setState({ data: [], loading: false });
        } else {
          setState({ data: dataRest.data, loading: false });
        }
      };

      obtenerCategorias();
    }
    return () => (isActive = false);
    // eslint-disable-next-line
  }, [idiomaState]);

  return state;
};
