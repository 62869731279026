import React from "react";
import { NoticiaItem } from "./NoticiaItem";

export const ListaNoticias = ({ Noticias = [] }) => {
  return (
    <>
      <div className="row">
        {Noticias.map((noticia) => (
          <NoticiaItem key={noticia.id} noticia={noticia} />
        ))}
      </div>
    </>
  );
};
