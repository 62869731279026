import React from "react";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

const responsive = {
	mobile: {
		breakpoint: { max: 576, min: 0 },
		items: 2,
		partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
	},
};
export const CarrouselLabels = ({ listaLabel = [] }) => {
	return (
		<Carousel
			partialVisible={true}
			responsive={responsive}
			arrows={false}
			infinite={true}
			autoPlay={false}
			shouldResetAutoplay={false}
		>
			{listaLabel.map((elemento, i) => (
				<div className='lateral-items-institucional' key={i}>
					<span className='lateral_intitucional_blanco'>
						<Link className='enlace-lateral' to={elemento.Ruta}>
							{elemento.Titulo}
						</Link>
					</span>
				</div>
			))}
		</Carousel>
	);
};
