import { useEffect, useState } from "react";

import { getIdiomas } from "../../../helpers/getIdiomas";

const useIdiomas = () => {
	const [idiomasDisponibles, setIdiomasDisponibles] = useState([]);

	const obtenerIdiomas = async () => {
		const { data } = await getIdiomas();
		setIdiomasDisponibles(data);
	};

	useEffect(() => {
		obtenerIdiomas();
	}, []);

	return {
		idiomasDisponibles,
		obtenerIdiomas,
	};
};

export default useIdiomas;
