import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { getCollectionData } from "../../../helpers/getCollectionData";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { GaleriaDeTaller } from "./GaleriaDeTaller";
import { useFetchTalleresDisenio } from "./hooks/useFetchTalleresDisenio";
import ReactMarkdown from "react-markdown";

import "./TalleresDisenio.css";
import { ModalListaImagenes } from "./ModalListaImagenes";
import { useTitle } from "../../../hooks/useTitle";
import usePageTracking from "../../../hooks/usePageTracking";

const responsive = {
	mobile: {
		breakpoint: { max: 576, min: 0 },
		items: 2,
		partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
	},
};

export const TalleresDeDisenio = () => {
	const [mostrarModal, setMostrarModal] = useState({
		mostrar: false,
		data: [],
	});
	const idiomaState = useSelector((state) => state.idioma);

	const currentSize = useWindowsSize();

	const [currentTaller, setCurrentTaller] = useState(null);

	const [listaTalleres, setListaTalleres] = useState([]);

	const { data, loading } = useFetchTalleresDisenio();
	const {
		Titulo,
		Fondo,
		Contenido,
		BotonAcercaDe,
		BotonAcercaDeTalleres,
		ContenidoAcercaDeTalleres,
	} = loading ? {} : data.attributes;

	useTitle(Titulo ?? "");
	usePageTracking();

	useEffect(() => {
		let isMounted = true;

		const qs = require("qs");
		const query = qs.stringify({
			sort: ["Fecha:asc"],
			fields: ["Nombre"],
		});

		if (isMounted) {
			const obtenerTalleres = async () => {
				const { data } = await getCollectionData(
					"talleres-de-disenios",
					query,
					idiomaState
				);

				let talleresProcesados = [];
				data.forEach((taller) =>
					talleresProcesados.push({
						Nombre: taller.attributes.Nombre,
						Id: taller.id,
					})
				);

				setListaTalleres(talleresProcesados);
			};
			obtenerTalleres();
		}

		return () => (isMounted = false);
	}, [idiomaState]);

	const direccionFondo = loading
		? ""
		: `${process.env.REACT_APP_BASE_URL}${Fondo.data.attributes.url}`;

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='contendor-general-inst'>
					<div
						className='institucional-imagen-fondo-mobile'
						style={{ backgroundImage: `url(${direccionFondo})` }}
					></div>
					<Container fluid='sm'>
						<div className='contenido-seccion'>
							<div className='links-container links-institucionales'>
								<Link to='/' className='link-component links-institucionales'>
									Inicio
								</Link>
								{" > "}
								<Link
									to={`/programas/TalleresDisenio`}
									className='link-component links-institucionales'
								>
									{Titulo}
								</Link>
							</div>

							<div>
								<h1 className='title-mobile titulo-institucional-mobile'>
									{Titulo}
								</h1>
								{listaTalleres.length > 1 ? (
									<Carousel
										partialVisible={true}
										responsive={responsive}
										arrows={false}
										infinite={true}
										autoPlay={false}
										shouldResetAutoplay={false}
									>
										<div
											className='lateral-items-institucional'
											key={"acercade"}
										>
											<span
												onClick={() => setCurrentTaller(null)}
												className='lateral_intitucional_blanco'
											>
												{BotonAcercaDe}
											</span>
										</div>
										<div
											className='lateral-items-institucional'
											key={"acercadetalleres"}
										>
											<span
												onClick={() =>
													setCurrentTaller({ Nombre: "acercaDeTalleres" })
												}
												className='lateral_intitucional_blanco'
											>
												{BotonAcercaDeTalleres}
											</span>
										</div>
										{listaTalleres.map((taller, i) => (
											<div className='lateral-items-institucional' key={i}>
												<span
													onClick={() => setCurrentTaller(taller)}
													className='lateral_intitucional_blanco'
												>
													{taller.Nombre}
												</span>
											</div>
										))}
									</Carousel>
								) : listaTalleres.length === 1 ? (
									<div className='lateral-items-talleres'>
										<span onClick={() => setCurrentTaller(listaTalleres[0])}>
											{listaTalleres[0].Nombre}
										</span>
									</div>
								) : (
									<></>
								)}

								<div className='wrapper institucional-row-mobile institucional-contenedor-texto-mobil'>
									<div className='main-text-institucional'>
										<div className='taller-disenio-contenedor'>
											{currentTaller ? (
												currentTaller.Nombre === "acercaDeTalleres" ? (
													<ReactMarkdown linkTarget='_blank'>
														{ContenidoAcercaDeTalleres}
													</ReactMarkdown>
												) : (
													<GaleriaDeTaller
														tallerId={currentTaller.Id}
														setMostrarModal={setMostrarModal}
													/>
												)
											) : (
												<ReactMarkdown linkTarget='_blank'>
													{Contenido}
												</ReactMarkdown>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{mostrarModal.mostrar ? (
							<ModalListaImagenes
								listaImagenes={mostrarModal.data}
								setMostrarModal={setMostrarModal}
								mostrarModal={mostrarModal.mostrar}
							/>
						) : (
							<></>
						)}
					</Container>
				</div>
			) : (
				<div className='contendor-general-inst'>
					<div
						className='imagen-fondo'
						style={{ backgroundImage: `url(${direccionFondo})` }}
					></div>

					<div className='contenido-seccion'>
						<Container
							className='links-container links-institucionales'
							fluid='sm'
						>
							<Link to='/' className='link-component links-institucionales'>
								Inicio
							</Link>
							{" > "}
							<Link
								to={`/programas/TalleresDisenio`}
								className='link-component links-institucionales'
							>
								{Titulo}
							</Link>
						</Container>

						<Container fluid='sm'>
							<h1 className='titulo-institucional'>{Titulo}</h1>
							<div className='wrapper'>
								<Col sm={2} className='lateral-menu'>
									{listaTalleres.map((taller, i) => (
										<div className='lateral-items-institucional' key={i}>
											<span
												onClick={() => setCurrentTaller(taller)}
												className='lateral_intitucional_blanco'
											>
												{taller.Nombre}
											</span>
										</div>
									))}
									<div className='lateral-items-institucional' key={"acercade"}>
										<span
											onClick={() => setCurrentTaller(null)}
											className='lateral_intitucional_blanco'
										>
											{BotonAcercaDe}
										</span>
									</div>
									<div
										className='lateral-items-institucional'
										key={"acercadetalleres"}
									>
										<span
											onClick={() =>
												setCurrentTaller({ Nombre: "acercaDeTalleres" })
											}
											className='lateral_intitucional_blanco'
										>
											{BotonAcercaDeTalleres}
										</span>
									</div>
								</Col>

								<Col sm={10} className='main-content'>
									<div className='main-text-institucional'>
										<div className='taller-disenio-contenedor'>
											{currentTaller ? (
												currentTaller.Nombre === "acercaDeTalleres" ? (
													<ReactMarkdown linkTarget='_blank'>
														{ContenidoAcercaDeTalleres}
													</ReactMarkdown>
												) : (
													<GaleriaDeTaller
														tallerId={currentTaller.Id}
														setMostrarModal={setMostrarModal}
													/>
												)
											) : (
												<ReactMarkdown linkTarget='_blank'>
													{Contenido}
												</ReactMarkdown>
											)}
										</div>
									</div>
								</Col>
							</div>
							{mostrarModal.mostrar ? (
								<ModalListaImagenes
									listaImagenes={mostrarModal.data}
									setMostrarModal={setMostrarModal}
									mostrarModal={mostrarModal.mostrar}
								/>
							) : (
								<></>
							)}
						</Container>
					</div>
				</div>
			)}
		</>
	);
};
