import { useEffect, useRef, useState } from "react";

export const useLazyLoad = () => {
    const elementRef = useRef(null);

    const [isNear, setIsNear] = useState(false);

    useEffect(() => {
        const onChange = (entries, observer) => {
            const element = entries[0];

            if (element.isIntersecting) {
                setIsNear(true);
                observer.disconnect(); //Disconnect observer when we intersect in the first time
            }
        };

        const observer = new IntersectionObserver(onChange, {
            rootMargin: "100px",
        });

        observer.observe(elementRef.current);

        return () => observer.disconnect();
    });

    return {
        isNear,
        elementRef,
    };
};