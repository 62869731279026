import React from "react";
import { useAccordionButton } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { agregarUrlAContenido } from "../../../helpers/agregarUrlAContenido";
import styles from "./Accordion.module.css";
import { SocialIcon } from "react-social-icons";

export const AccordionNoticia = ({
	contenido = "",
	Titulo = "",
	slug = "",
	CompartirLabel = "Compartir",
	eventKey,
}) => {
	const closeAccordion = useAccordionButton(eventKey, () =>
		window.scrollTo(0, 0)
	);

	return (
		<>
			<div className={`clase-rara-noticia ${styles.markdow_text}`}>
				<ReactMarkdown linkTarget='_blank'>
					{agregarUrlAContenido(contenido)}
				</ReactMarkdown>
				<div>
					<p className='titulo-compartir' style={{ marginTop: "30px" }}>
						{CompartirLabel}
					</p>
					<div className='socialIcon-container'>
						<SocialIcon
							url={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/noticias/${slug}`}
							bgColor={"black"}
							style={{ height: 24, width: 24, marginRight: 11 }}
						/>
						<SocialIcon
							url={`https://twitter.com/intent/tweet?text=${Titulo}&url=${window.location.origin}/noticias/${slug}`}
							bgColor={"black"}
							style={{
								height: 24,
								width: 24,
								marginRight: 11,
							}}
						/>
						<SocialIcon
							url={`https://api.whatsapp.com/send?text=${Titulo}%20-->${window.location.origin}/noticias/${slug}`}
							bgColor={"black"}
							style={{
								height: 24,
								width: 24,
								marginRight: 11,
							}}
						/>
					</div>
				</div>
			</div>
			<div
				onClick={closeAccordion}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					borderTop: "1px solid black",
					height: "45px",
					fontFamily: "GothicMainDemi",
					fontSize: "13px",
				}}
			>
				Cerrar
			</div>
		</>
	);
};
