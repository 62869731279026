import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import "./BotonMostrarMas.css";

export const BotonMostrarMas = ({
	mostrarMasResultados,
	mostrarMasLabel = "Mostrar más",
}) => {
	const windowSize = useWindowsSize();

	return (
		<>
			{windowSize === WindowSizeType.xs ? (
				<div>
					<button
						className='boton-mostrar-resultados-mobile'
						onClick={mostrarMasResultados}
					>
						{mostrarMasLabel}
					</button>
				</div>
			) : (
				<div>
					<button
						className='boton-mostrar-resultados'
						onClick={mostrarMasResultados}
					>
						{mostrarMasLabel}
					</button>
				</div>
			)}
		</>
	);
};
