export const obtenerPdfParaDescargar = async (id) => {
  const base_url = `${process.env.REACT_APP_BASE_URL}`;
  const qs = require("qs");

  const query = qs.stringify({
    populate: ["Pdf"],
    fields: ["locale"],
  });

  const urlCompleta = `${base_url}/api/edicion-libros/${id}?${query}`;
  const resp = await fetch(urlCompleta);
  const dataRest = await resp.json();

  return dataRest;
};
