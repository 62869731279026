import React, { Suspense } from "react";
import { useLazyLoad } from "../../../../hooks/useLazyLoad";

const SugerirArtesanos = React.lazy(() => import("./SugerirArtesanos"));

export const LazySugerirArtesanos = ({
	artesanoActualId = "",
	categoriasArtesano = [],
	idioma,
}) => {
	const { isNear, elementRef } = useLazyLoad();

	return (
		<div ref={elementRef}>
			<Suspense fallback={null}>
				{isNear ? (
					<SugerirArtesanos
						idioma={idioma}
						artesanoActualId={artesanoActualId}
						categoriasArtesano={categoriasArtesano}
					/>
				) : null}
			</Suspense>
		</div>
	);
};
