import React, { useState } from "react";

import styles from "./SearchBox.module.css";
import { SearchBar, SearchIcon, MobileSearchIcon } from "./SearchElements";
import { isWhite } from "../../whiteViews";
import searchBlack from "../../../../assets/icons/search/search-black.svg";
import { useWindowsSize } from "../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../types/WindowSizeType";

export const SearchBox = ({ placeholder, img, BuscarMobile, closeMenu }) => {
	const [isExpanded, setExpanded] = useState(false);

	const currentSize = useWindowsSize();

	const toggleSearch = () => {
		setExpanded(!isExpanded);
	};

	return (
		<>
			{currentSize === WindowSizeType.lg ? (
				<div className={styles.search_container}>
					<SearchIcon
						handleClick={toggleSearch}
						img={isWhite() ? searchBlack : img}
					/>

					{isExpanded && (
						<SearchBar
							mobile={false}
							placeholder={placeholder}
							handleClick={toggleSearch}
							handleKeyDown={toggleSearch}
						/>
					)}
				</div>
			) : (
				<MobileSearchBox
					placeholder={placeholder}
					img={img}
					BuscarMobile={BuscarMobile}
					handleClick={toggleSearch}
					cerrarMenu={closeMenu}
				/>
			)}
		</>
	);
};

export const MobileSearchBox = ({
	placeholder,
	img,
	cerrarMenu,
	BuscarMobile,
	handleClick,
}) => {
	const [isExpanded, setExpanded] = useState(false);

	const toggleSearch = () => {
		setExpanded(!isExpanded);
	};

	const closeMenu = () => {
		cerrarMenu();
		setExpanded(!isExpanded);
	};

	const handleSearchbarFocus = (focus) => {
		let progs = document.getElementById("navDropdown-Progs");
		let links = document
			.getElementById("navLinks")
			.getElementsByClassName("link-navbar");
		if (focus) {
			for (let i = 0; i < links.length; i++) {
				links[i].classList.add("keyboard");
			}
			progs.classList.add("keyboard");
		} else {
			for (let i = 0; i < links.length; i++) {
				links[i].classList.remove("keyboard");
			}
			progs.classList.remove("keyboard");
		}
	};
	return (
		<div className={styles.search_container}>
			{!isExpanded ? (
				<MobileSearchIcon
					handleClick={toggleSearch}
					img={img}
					BuscarMobile={BuscarMobile}
				/>
			) : (
				<>
					<MobileSearchIcon
						handleClick={toggleSearch}
						img={img}
						BuscarMobile={BuscarMobile}
					/>
					<SearchBar
						handleFocus={() => handleSearchbarFocus(true)}
						handleBlur={() => handleSearchbarFocus(false)}
						mobile={true}
						placeholder={placeholder}
						handleClick={closeMenu}
						handleKeyDown={closeMenu}
						cerrarMenu={cerrarMenu}
					/>
				</>
			)}
		</div>
	);
};
