import React from "react";
import spinner from "../../../assets/gif-carga/482.gif";

const LoadingSpinner = () => {
	return (
		<div>
			<img src={spinner} alt='Cargando...' />
		</div>
	);
};
export default LoadingSpinner;
