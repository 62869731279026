import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import Galeria from "./Galeria";
import "./Galleries.css";
import { getCollectionData } from "../../helpers/getCollectionData";

const GalleryNoticias = React.lazy(() => import("./Noticias/GalleryNoticias"));
const GalleryArtesanos = React.lazy(() =>
	import("./Artesanos/GalleryArtesanos")
);
const GalleryHistorias = React.lazy(() =>
	import("./Historias/GalleryHistorias")
);
const GalleryTemas = React.lazy(() => import("./Temas/GalleryTemas"));

export const Galleries = () => {
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosArtesanos, setDatosArtesanos] = useState({
		title: "",
		verTodo: "",
	});
	const [datosHistorias, setDatosHistorias] = useState({
		title: "",
		verTodo: "",
	});
	const [datosNoticias, setDatosNoticias] = useState({
		title: "",
		verTodo: "",
	});
	const [datosTemas, setDatosTemas] = useState({ title: "", verTodo: "" });

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const {
					SeccionArtesano_Titulo,
					SeccionArtesano_VerTodo,
					SeccionHistoria_Titulo,
					SeccionHistoria_VerTodo,
					SeccionNoticia_Titulo,
					SeccionNoticia_VerTodo,
					SeccionTema_Titulo,
				} = (await getCollectionData("pagina-homes", "", estadoIdioma, 1))
					.data[0]?.attributes;

				setDatosArtesanos({
					title: SeccionArtesano_Titulo,
					verTodo: SeccionArtesano_VerTodo,
				});
				setDatosHistorias({
					title: SeccionHistoria_Titulo,
					verTodo: SeccionHistoria_VerTodo,
				});
				setDatosNoticias({
					title: SeccionNoticia_Titulo,
					verTodo: SeccionNoticia_VerTodo,
				});
				setDatosTemas({
					title: SeccionTema_Titulo,
					verTodo: "",
				});
			};
			obtenerDatos();
		}

		return () => (isMounted = false);
	}, [estadoIdioma]);

	return (
		<Container fluid='sm' style={{ minHeight: 1200 }}>
			<Galeria
				title={datosArtesanos.title}
				gallery={<GalleryArtesanos />}
				vertodolink='/artesanos'
				varTodoLabel={datosArtesanos.verTodo}
			/>
			<Galeria
				title={datosHistorias.title}
				gallery={<GalleryHistorias />}
				vertodolink='/historias'
				varTodoLabel={datosHistorias.verTodo}
			/>
			<Galeria
				title={datosNoticias.title}
				gallery={<GalleryNoticias />}
				vertodolink='/noticias'
				varTodoLabel={datosHistorias.verTodo}
			/>
			<Galeria title={datosTemas.title} gallery={<GalleryTemas />} />
		</Container>
	);
};

export default Galleries;
