import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CheckDropdownItem } from "./CheckboxItem";
import { CheckboxMenu } from "./CheckboxMenu";
import "./CheckboxMenu.css";

export const CheckDropdownCategoriaNoticias = ({
	items = [],
	title = "",
	handleInputChange,
}) => {
	const handleChecked = (e, item) => {
		e.stopPropagation();
		handleInputChange(e, item);
	};
	const [flechaDropdown, setFlechaDropdown] = useState(false);

	return (
		<Dropdown onToggle={(nextToggle, e) => setFlechaDropdown(nextToggle)}>
			<Dropdown.Toggle className='todo-width'>
				<div>
					<span>{title}</span>
					{flechaDropdown ? (
						<span id='programasIcon' className='flecha-algo'>
							^
						</span>
					) : (
						<span id='programasIcon' className='arrow-flip-drop flecha-algo'>
							^
						</span>
					)}
				</div>
			</Dropdown.Toggle>

			<Dropdown.Menu as={CheckboxMenu} className='col-6'>
				{items.map((i) => (
					<Dropdown.Item
						key={i.id}
						as={CheckDropdownItem}
						id={i.id}
						onClick={(e) => handleChecked(e, i)}
						className='TextoEnlinea'
					>
						{i.attributes.Categoria} {`(${i.attributes.noticias.data.length})`}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
