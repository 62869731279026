import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import rightArrow from "../../../../../assets/icons/arrow-black-right/arrow-black-right.svg";
import leftArrow from "../../../../../assets/icons/arrow-black-left/arrow-black-left.svg";
import dwldBtn from "../../../../../assets/icons/arrow-black-right/arrow-black-right.svg";
import closeBtn from "../../../../../assets/icons/close-button-black/close-button-black.svg";
import expandBtn from "../../../../../assets/icons/cuadrado-expandir/cuadrado-expandir.svg";

const nI = <img src={rightArrow} alt='fleca siguiente' />;
const pI = <img src={leftArrow} alt='flecha anterior' />;
const DownArrow = (
	<img className='flecha-descargar ' src={dwldBtn} alt='flecha descargar' />
);
const closeButtonImage = <img src={closeBtn} alt='botón cerrar' />;
const cuadradoExpandir = <img src={expandBtn} alt='botón expandir' />;

export const IndicadorChico = ({
	descargarPDF,
	previousPage,
	nextPage,
	pageNumber,
	numPages,
	sampblePDF,
	handleCerrarLibro,
	onDocumentLoadSuccess,
	getPdfSize,
	setPdfExpandido,
	DescargarLabel = "",
}) => {
	const expandirPDF = () => {
		setPdfExpandido(true);
	};

	return (
		<div style={{ zIndex: 1, minHeight: 600 }}>
			<div className='row contenedor-flechas-pdf'>
				<div className='col-6'>
					<button className='botones-pdf' onClick={descargarPDF}>
						{DownArrow}
						{DescargarLabel}
					</button>
				</div>
				<div className='col-6'>
					<div className='float-end'>
						<button
							disabled={pageNumber <= 1}
							onClick={previousPage}
							className='botones-pdf botones-flechas-pdf'
						>
							{pI}
						</button>
						<button
							disabled={pageNumber >= numPages}
							onClick={(event) => nextPage(event)}
							className='botones-pdf botones-flechas-pdf'
						>
							{nI}
						</button>
						<button
							className='botones-pdf boton-expandir'
							onClick={expandirPDF}
						>
							{cuadradoExpandir}
						</button>
						<button
							className='botones-pdf boton-cerrar-pdf'
							onClick={handleCerrarLibro}
						>
							{closeButtonImage}
						</button>
					</div>
				</div>
			</div>
			<div className='contenedor-pdf'>
				<Document
					className='row PDF-document'
					file={sampblePDF}
					options={{ workerSrc: "/pdf.worker.js" }}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					<Page
						pageNumber={pageNumber}
						height={getPdfSize()}
						className='col pdf-page'
					/>
				</Document>
			</div>
		</div>
	);
};
