import React from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { format } from "date-fns";

import "./LibroItem.css";
import { useWindowsSize } from "../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../types/WindowSizeType";
import { obtenerPdfParaDescargar } from "./helpers/obtenerPdfParaDescargar";

export const LibroItem = ({ libro = {} }) => {
	const { Portada, Fecha } = libro.attributes;

	const rutaImagen = `${process.env.REACT_APP_BASE_URL}${Portada.data.attributes.url}`;

	const navigate = useNavigate();

	const navegarLibro = () => {
		navigate(`/programas/PremioNacionalArtesanias/EdicionesLibros/${libro.id}`);
	};

	const descargarPDF = async () => {
		const { Pdf } = (await obtenerPdfParaDescargar(libro.id)).data.attributes;
		saveAs(
			`${process.env.REACT_APP_BASE_URL}${Pdf.data.attributes.url}`,
			`${Pdf.data.attributes.alternativeText}`
		);
	};
	const currentSize = useWindowsSize();

	return (
		<div className='card card-libro'>
			<div className='libro-contenedor-imagen'>
				{currentSize === WindowSizeType.xs ? (
					<img
						fluid='true'
						src={rutaImagen}
						className='card-img-top img-portada'
						alt='...'
						onClick={descargarPDF}
					/>
				) : (
					<img
						fluid='true'
						src={rutaImagen}
						className='card-img-top img-portada'
						alt='...'
						onClick={navegarLibro}
					/>
				)}
			</div>

			<div className='card-body'>
				<div className='d-flex justify-content-between'>
					<span className='card-text card-items fecha-text'>
						{format(new Date(Fecha), "d MMM yyyy")
							.split(" ")
							.slice(2, 3)
							.join(" ")}
					</span>
					<button
						className='card-text card-items boton-pdf float-right'
						onClick={descargarPDF}
					>
						PDF
					</button>
				</div>
			</div>
		</div>
	);
};
