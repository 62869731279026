import React from "react";
import { SocialIcon } from "react-social-icons";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";

import "./FooterMobile.css";
import logo from "../../../assets/icons/logo-ministerio/logo-ministerio.svg";
import { cambiarIdiomaActual } from "../../../actions/Idioma";
import styles from "../NewFooter.module.css";
import { Rutas } from "../../../routes/Routes";

export const FooterMobile = ({ data, idiomas }) => {
	const obtenerTextoIdioma = (codigoIdioma = "") => {
		if (codigoIdioma.slice(0, 2) === "es") return "Español";
		else if (codigoIdioma.slice(0, 2) === "en") return "English";
		else return "";
	};

	const dispatch = useDispatch();

	const links_col_1 = [
		{ label: data.InicioLabel, link: "/" },
		{ label: data.ArtesanosLabel, link: "/artesanos" },
		{ label: data.HistoriasLabel, link: "/historias" },
		{ label: data.NoticiasLabel, link: "/noticias" },
		{
			label: data.PremioNacionalArtesania,
			link: "/programas/PremioNacionalArtesanias",
		},
		{ label: data.ArtesaniasCancha, link: "/programas/ArtesaniasALaCancha" },
		{ label: data.CasasArtesania, link: "/programas/CasasDeArtesania" },
		{ label: data.TalleresDisenioLabel, link: "/programas/TalleresDisenio" },
		{ label: data.RegistroNacional, link: "/RegistroNacional" },
	];

	const links_col_2 = [
		{
			label: data.ParticipacionInternacional,
			link: "/ParticipacionInternacional",
		},
		{ label: data.FeriaYEventos, link: "/FeriasyEventos" },
		{ label: data.Indicadores, link: Rutas.Indicadores },
		{ label: data.Postulaciones, link: "/Postulaciones" },
		{ label: data.PlanEstrategico, link: "/PlanEstrategico" },
		{ label: data.ActaComisiones, link: "/ActaDeComisiones" },
		{ label: data.PreguntasFrecuentes, link: "/PreguntasFrecuentes" },
		{ label: data.Dinapyme, link: "/Dinapyme" },
		{ label: data.AcercaDeAU, link: "/AcercaDeAU" },
	];

	return (
		/* Quizas todo esto (y tambien la navbar) deberian ir en un container*/
		<div>
			<div className='caja-negra-footer-mobile'>
				<Container fluid='sm'>
					<div id='to-register' className='footer-contenedor-texto-negro'>
						<p className='footer-register-title-mobile'>
							{data.IngreseARegistroLabel}
						</p>

						<a
							target='_blank'
							rel='noopener noreferrer'
							className={`unstyled-link footer-register-link-mobile ${styles.registro_footer}`}
							href={data.EnlaceUnirseArtesano.Enlace}
						>
							{data.IrAlRegistro}
							<img
								id='arrowReg'
								alt='whiteArrow'
								src={require("../../../assets/icons/arrow-white-right_2022-02-14/arrow-white-right.png")}
							/>
						</a>
					</div>
				</Container>
			</div>
			<div className='caja-gris-footer-mobile'>
				<div className='footer-contenedor-links-mobile'>
					<Container fluid='sm'>
						<div className='row'>
							<div className='col-12'>
								<div className='row'>
									<div className='col'>
										<ul className='list-unstyled'>
											{links_col_1.map((link, i) => (
												<li key={link.label + i} className='footer-li-mobile'>
													<a
														href={link.link}
														className='unstyled-link footer-texto-gris-link-mobile '
													>
														{link.label}
													</a>
												</li>
											))}
										</ul>
									</div>
									<div className='col'>
										<ul className='list-unstyled'>
											{links_col_2.map((link, i) => (
												<li key={link.label + i} className='footer-li-mobile'>
													<a
														href={link.link}
														className='unstyled-link footer-texto-gris-link-mobile '
													>
														{link.label}
													</a>
												</li>
											))}
										</ul>
										<ul className='footer-contenedor-redes-sociales-mobile'>
											{data.RedesSociales.map((red) => (
												<SocialIcon
													key={red.id}
													url={red.Enlace}
													bgColor={"white"}
													style={{
														height: "24px",
														width: "24px",
														marginRight: 10,
													}}
												/>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
				<div>
					<Container fluid='sm'>
						<div>
							{idiomas.map((idioma) => (
								<span
									key={idioma.id}
									className='unstyled-link pointer-footer footer-text-idiomas-mobile'
									onClick={() => dispatch(cambiarIdiomaActual(idioma.code))}
								>
									{obtenerTextoIdioma(idioma.code)}
								</span>
							))}
						</div>
						<hr className='footer-hr-mobile' />
					</Container>
				</div>
				<div>
					<Container fluid='sm'>
						<div className=' footer-contenedor-logo-mobile'>
							<ul className='list-unstyled'>
								<li>
									<a
										target='_blank'
										href={data.EnlaceMiem.Enlace}
										rel='noopener noreferrer'
									>
										<img src={logo} alt='AU Logo' className={"footerLogo"} />
									</a>
								</li>
								<li>
									<p className='footer-texto-datos-miem-mobile'>
										Calle Rincón 719 piso 2 - C.P. 11000 <br />
										Montevideo, República Oriental del Uruguay
										<br />
										(+598) 2840 1234 int: 3118 / 3138
									</p>
									<a
										href='http://www.dinapyme.gub.uy'
										target='_blank'
										rel='noopener noreferrer'
										className='unstyled-link footer-texto-datos-miem-mobile'
									>
										www.dinapyme.gub.uy
									</a>
								</li>
							</ul>
							<p className={styles.apoya_title}>{data.Apoya}</p>

							<div className={`${styles.contenedor_apoya}`}>
								{data.ApoyaIcons.map((icon, i) => (
									<div key={icon.Imagen.data.id}>
										<a
											target='_blank'
											href={icon.LinkVideo}
											rel='noopener noreferrer'
										>
											<img
												src={`${process.env.REACT_APP_BASE_URL}${icon.Imagen.data.attributes.url}`}
												alt={`Apoya ${i}`}
												style={{ height: 60 }}
											/>
										</a>
									</div>
								))}
							</div>
						</div>
					</Container>
				</div>
			</div>
		</div>
	);
};
