import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getEntityById } from "../../../helpers/getEntityById";
import { CarouselTallerista } from "./CarouselTallerista";
import "./GaleriaDeTaller.css";

export const GaleriaDeTaller = ({ tallerId, setMostrarModal }) => {
	const [listaTalleristas, setListaTalleristas] = useState([]);
	const idiomaState = useSelector((state) => state.idioma);
	const [descripcionTaller, setDescripcionTaller] = useState("");

	useMemo(() => {
		const obtenerTalleristas = async () => {
			const data = await getEntityById(
				"talleres-de-disenios",
				tallerId,
				[
					"Tallerista",
					"Tallerista.Portada",
					"Tallerista",
					"Tallerista.Imagenes",
					"Tallerista.Imagenes.Imagen",
				],
				idiomaState
			);

			setListaTalleristas(data.Tallerista);
			setDescripcionTaller(data.Descripcion);
		};

		obtenerTalleristas();
	}, [tallerId, idiomaState]);

	const handleMostrarImagenes = (id) => {
		const obtenerImagenes = async () => {
			const dataTallerista = await getEntityById(
				"talleres-de-disenios",
				tallerId,
				["Tallerista", "Tallerista.Imagenes", "Tallerista.Imagenes.Imagen"],
				idiomaState
			);

			window.scrollTo(0, 0);
			setMostrarModal({
				mostrar: true,
				data: dataTallerista.Tallerista.find(
					(tallerista) => tallerista.id === id
				).Imagenes,
			});
		};
		obtenerImagenes();
	};

	return (
		<>
			<h3 className='taller-titulo'>{descripcionTaller}</h3>

			<div className='row'>
				{listaTalleristas.map((tallerista, index) => {
					const { Nombre, Imagenes } = tallerista;
					return (
						<CarouselTallerista
							Imagenes={Imagenes}
							Nombre={Nombre}
							tallerista={tallerista}
							handleMostrarImagenes={handleMostrarImagenes}
							key={index}
						/>
					);
				})}
			</div>
		</>
	);
};
