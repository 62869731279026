import React, { useContext, useMemo } from "react";
import { useFetchCategoriasNoticiasConCantidad } from "../../../hooks/useFetchCategoriasNoticiasConCantidad";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { CheckDropdownCategoriaNoticias } from "../../shared/DropdownsItems/Checkbox/CheckDropdownCategoriaNoticias";
import { CategoriaSeleccionadasContext } from "../NoticiasContext";

export const CategoriasNoticias = () => {
	const { categoriasSeleccionadas, setCategoriasSeleccionadas } = useContext(
		CategoriaSeleccionadasContext
	);

	const { data, loading } = useFetchCategoriasNoticiasConCantidad();
	const categorias = useMemo(
		() =>
			loading ? [] : data.filter((x) => x.attributes.noticias.data.length > 0),
		[loading, data]
	);

	const handleInputChange = (e, cat) => {
		if (e.target.checked) {
			setCategoriasSeleccionadas([...categoriasSeleccionadas, cat.id]);
		} else {
			setCategoriasSeleccionadas(
				categoriasSeleccionadas.filter((cate) => cate !== cat.id)
			);
		}
	};

	const currentSize = useWindowsSize();

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				loading ? (
					<p>Loading</p>
				) : (
					<div className='col-6'>
						<CheckDropdownCategoriaNoticias
							items={categorias}
							title={"Categorías"}
							handleInputChange={handleInputChange}
						/>
					</div>
				)
			) : (
				<div className='component-box col-12'>
					<h6 className='box-title'>Categorías</h6>
					{loading ? (
						<p>Loading</p>
					) : (
						categorias.map((categoria) => (
							<div key={categoria.id} className='mb-2'>
								<input
									type='checkbox'
									onClick={(e) => handleInputChange(e, categoria)}
									checked={categoriasSeleccionadas.includes(categoria.id)}
								/>
								<label className='input-label'>
									{`${categoria.attributes.Categoria} (${categoria.attributes.noticias.data.length})`}
								</label>
							</div>
						))
					)}
				</div>
			)}
		</>
	);
};
