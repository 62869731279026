import React from "react";
import { Col, Container } from "react-bootstrap";

import "./MainLayout.css";
import "./LateralMenu.css";
import { LateralMenu } from "./LateralMenu";
import { useWindowsSize } from "../../hooks/useWindowsSize";
import { WindowSizeType } from "../../types/WindowSizeType";

export const MainLayout = ({
	title = "",
	componentesLaterales = [],
	isLoading = false,
	MainContent,
}) => {
	const currentSize = useWindowsSize();

	return (
		<Container fluid='sm' className='main_layout_container'>
			{currentSize === WindowSizeType.xs ? (
				<>
					<h2 className='title-mobile'>{title}</h2>
					<hr className='hr-main-layout' />
					<div>
						<LateralMenu listaComponentes={componentesLaterales} />
						{isLoading ? <p>Loading...</p> : <>{MainContent}</>}
					</div>
				</>
			) : (
				<>
					<h2 className='title'>{title}</h2>
					<hr className='hr-main-layout' />
					<div className='wrapper'>
						<Col sm={3} className='lateral-menu'>
							<LateralMenu listaComponentes={componentesLaterales} />
						</Col>
						{isLoading ? (
							<p>Loading...</p>
						) : (
							<Col sm={9} className='main-content'>
								{MainContent}
							</Col>
						)}
					</div>
				</>
			)}
		</Container>
	);
};
