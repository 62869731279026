import { useNavigate, useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import "./DescargarArchivo.css";
import { useSelector } from "react-redux";
import { getCollectionData } from "../../helpers/getCollectionData";

export const DescargarArchivo = () => {
	const { nombreArchivo } = useParams();
	const navigate = useNavigate();
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		DescripcionLabel: "",
		VolverLabel: "",
		Fondo: "",
		TituloLabel: "",
	});
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const qs = require("qs");

				const query = qs.stringify({
					populate: ["Fondo"],
				});
				const { DescripcionLabel, VolverLabel, Fondo, TituloLabel } = (
					await getCollectionData("pagina-descargas", query, estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					DescripcionLabel: DescripcionLabel,
					VolverLabel: VolverLabel,
					Fondo: Fondo,
					TituloLabel: TituloLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	const handleVolver = (e) => {
		e.preventDefault();
		navigate("/");
	};
	useEffect(() => {
		saveAs(
			`${process.env.REACT_APP_BASE_URL}/uploads/${nombreArchivo}`,
			`${nombreArchivo}`
		);
	}, [nombreArchivo]);

	return (
		<div className='contenedor-descarga'>
			<div
				className='imagen-fondo-descarga'
				style={{
					backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${datosPagina.Fondo.data?.attributes.url})`,
				}}
			>
				<div className='aviso-descarga'>
					<div className='texto-descarga'>
						<span className='mensaje-descarga'>
							{datosPagina.TituloLabel} <br />
						</span>
						<span className='sub-mensaje-descarga'>
							{datosPagina.DescripcionLabel}
							<br />
						</span>
					</div>
					<button className='black-button' onClick={handleVolver}>
						{datosPagina.VolverLabel}
					</button>
				</div>
			</div>
		</div>
	);
};
