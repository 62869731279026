import React from "react";

import { LibroItem } from "./LibroItem";

export const ListaLibros = ({ libros = [] }) => {
	return (
		<div className='row'>
			{libros.map((libro) => (
				<div className='col-lg-4 col-md-6 col-sm-6 col-6' key={libro.id}>
					<LibroItem libro={libro} />
				</div>
			))}
		</div>
	);
};
