export const getIdiomas = async () => {
  try {
    const base_url = `${process.env.REACT_APP_BASE_URL}`;
    const urlCompleta = `${base_url}/api/i18n/locales`;
    const response = await fetch(urlCompleta);
    const dataRest = await response.json();
    return {
      data: dataRest,
    };
  } catch (error) {

    return {
      data: [],
    };
  }
};
