import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { SimpleItem } from "./SimpleItem";
import { SimpleMenu } from "./SimpleMenu";
import "./SimpleMenu.css";

export const SimpleDropdown = ({ items = [], title = "" }) => {
	const [flechaDropdown, setFlechaDropdown] = useState(false);
	const [isShown, setIsShown] = useState(false);

	const flipArrow = () => {
		setFlechaDropdown(!flechaDropdown);
		setIsShown(!isShown);
	};

	return (
		<Dropdown show={isShown}>
			<Dropdown.Toggle className='todo-width'>
				<div onClick={flipArrow}>
					<span>{title}</span>
					{flechaDropdown ? (
						<span id='programasIcon' className='flecha-algo'>
							^
						</span>
					) : (
						<span id='programasIcon' className='arrow-flip-drop flecha-algo'>
							^
						</span>
					)}
				</div>
			</Dropdown.Toggle>

			<Dropdown.Menu as={SimpleMenu} className='col-6'>
				{items.map((item, i) => (
					<Dropdown.Item key={i} as={SimpleItem} id={i} onClick={item.onClick}>
						{item.texto}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
