export const Rutas = {
    Home: '/',
    Artesanos: '/artesanos',
    PerfilArtesano: '/artesanos/:artesanoId',
    Historias: '/historias',
    PerfilHistoria: '/historias/:historiaId',
    Noticias: '/noticias',
    PerfilNoticia: '/noticias/:noticiaId',

    //Busquedas
    BusquedaSimple: '/resultadosSimple/:busqueda',
    BusquedaTag: '/resultadosPorTag/:busqueda',
    BusquedaCategorias: '/resultadosPorCategoria/:busqueda',

    //Programas
    Programas: '/programas',

    PremioNacionalArtesania: '/programas/PremioNacionalArtesanias',
    Afiches: '/programas/PremioNacionalArtesanias/Afiches',
    PerfilAfiche: '/programas/PremioNacionalArtesanias/Afiches/:aficheId',
    EdicionesLibros: '/programas/PremioNacionalArtesanias/EdicionesLibros',
    PerfilLibro: '/programas/PremioNacionalArtesanias/EdicionesLibros/:libroId',

    ArtesaniasALaCancha: '/programas/ArtesaniasALaCancha',
    CasasDeArtesania: '/programas/CasasDeArtesania',
    TallerDeDisenio: '/programas/TalleresDisenio',
    ActaComisiones: '/ActaDeComisiones',
    Dinapyme: '/Dinapyme',
    Postulaciones: '/Postulaciones',
    PlanEstrategico: '/PlanEstrategico',
    PreguntasFrecuentes: '/PreguntasFrecuentes',
    FeriasYEventos: '/FeriasYEventos',
    RegistroNacional: '/RegistroNacional',
    ParticipacionInternacional: '/ParticipacionInternacional',
    Indicadores: '/Indicadores',
    PerfilIndicador: '/Indicadores/',
    AcercaDeAU: '/AcercaDeAU',

    //Descargas
    DescargarArchivo: '/descargar/:nombreArchivo',
    DescargarArchivoProgramas: '/programas/descargar/:nombreArchivo'
}