import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import QueryString from "qs";

import { MainLayout } from "../Layouts/MainLayout";
import { ListaArtesanos } from "./mainContent/ListaArtesanos";
import {
	TiposArtesanos,
	CategoriasArtesano,
	OrdenArtesanos,
	DepartamentoArtesanos,
} from "./menuLateral";
import {
	CategoriaSeleccionadasContext,
	OrdenamientoSeleccionadoContext,
	DepartamentosSeleccionadosContext,
} from "./ArtesanosContext";

import "./ArtesanosView.css";
import { ordenarArray } from "../../helpers/ordenarArray";
import { orderArrayArtesanosPorImagenes } from "../../helpers/orderArrayPorImagenes";
import { Paginacion } from "../shared/Paginacion/Paginacion";
import { useWindowsSize } from "../../hooks/useWindowsSize";
import { WindowSizeType } from "../../types/WindowSizeType";
import { getCollectionData } from "../../helpers/getCollectionData";
import { useTitle } from "../../hooks/useTitle";
import usePageTracking from "../../hooks/usePageTracking";
import { ordenamientos } from "../../types/ordenamientos";

export const ArtesanosView = () => {
	const datosPorPagina = 9;

	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "Artesanos",
		CategoriasLabel: "Categorias",
		FiltrarPorLabel: "Filtros",
		OrdenLabel: "Ordenar",
		MostrarMasLabel: "Mostrar más",
		DepartamentoLabel: "Departamento",
	});

	useTitle(datosPagina.TituloLabel);
	usePageTracking();

	const [artesanosState, setArtesanosState] = useState([]);
	const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState([]);
	const [departamentosSeleccionados, setDepartamentosSeleccionados] = useState(
		[]
	);
	const [tiposArtesanosSeleccionados, setTiposArtesanosSeleccionados] =
		useState([]);
	const [ordenamientoSeleccionado, setOrdenamientoSeleccionado] = useState(
		ordenamientos.MasReciente
	);
	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadArtesanosAMostrar, setCantidadArtesanosAMostrar] =
		useState(datosPorPagina);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [cantidadFilasAMostrar, setCantidadFilasAMostrar] = useState(3);

	const [cantidadMostrarMas, setCantidadMostrarMas] = useState(0);

	const estadoIdioma = useSelector((state) => state.idioma);

	const windowSize = useWindowsSize();

	//* Usamos un useEffect para que la consulta no se ahga cada vez que se reenderiza la página, sino cuando cambian los valores de filtros

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const {
					TituloLabel,
					CategoriasLabel,
					FiltrarPorLabel,
					OrdenLabel,
					MostrarMasLabel,
					DepartamentoLabel,
				} = (await getCollectionData("pagina-artesanos", "", estadoIdioma, 1))
					.data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					CategoriasLabel: CategoriasLabel,
					FiltrarPorLabel: FiltrarPorLabel,
					OrdenLabel: OrdenLabel,
					MostrarMasLabel: MostrarMasLabel,
					DepartamentoLabel: DepartamentoLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setCantidadFilasAMostrar(3);
			setCantidadArtesanosAMostrar(datosPorPagina);
			setPaginaActual(1);
			setCantidadMostrarMas(0);
		}
		return () => (isMounted = false);
	}, [
		categoriasSeleccionadas,
		tiposArtesanosSeleccionados,
		ordenamientoSeleccionado,
		departamentosSeleccionados,
	]);

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté

		const obtenerArtesanosDB = async () => {
			const ordenamiento = ordenarArray(ordenamientoSeleccionado, "Nombre");
			let paginasResponse = 0;

			const query = QueryString.stringify({
				sort: [ordenamiento],
				filters: {
					$or: [
						{
							categorias_artesano: {
								id: {
									$in: categoriasSeleccionadas,
								},
							},
						},
						{
							tipos_artesano: {
								id: {
									$in: tiposArtesanosSeleccionados,
								},
							},
						},
						{
							departamento_artesano: {
								id: {
									$in: departamentosSeleccionados,
								},
							},
						},
					],
				},
				pagination: {
					page: paginaActual,
					pageSize: cantidadArtesanosAMostrar,
				},
				populate: ["Imagen", "Imagen.Imagen", "categorias_artesano"],
				fields: ["Nombre"],
			});

			const { data, paginas } = await getCollectionData(
				"artesanos",
				query,
				estadoIdioma
			);

			paginasResponse = paginas;
			// const unicos = eliminaRepetidosArray(dataResponse);
			const unicos = data;

			let cantidadColumnasPorFila = 3;
			switch (windowSize) {
				case WindowSizeType.xs:
					cantidadColumnasPorFila = 2;
					break;
				case WindowSizeType.sm:
					cantidadColumnasPorFila = 2;
					break;
				case WindowSizeType.md:
					cantidadColumnasPorFila = 2;
					break;
				default:
					cantidadColumnasPorFila = 3;
					break;
			}

			const artesanosFinal =
				ordenamientoSeleccionado === ""
					? orderArrayArtesanosPorImagenes(unicos, cantidadColumnasPorFila)
					: unicos;

			setArtesanosState(artesanosFinal);
			if (cantidadMostrarMas === 0) setCantidadPaginas(paginasResponse);
		};
		obtenerArtesanosDB();
	}, [
		categoriasSeleccionadas,
		tiposArtesanosSeleccionados,
		ordenamientoSeleccionado,
		paginaActual,
		estadoIdioma,
		windowSize,
		cantidadArtesanosAMostrar,
		cantidadMostrarMas,
		departamentosSeleccionados,
	]);

	const mostrarMasResultados = () => {
		if (paginaActual < cantidadPaginas) {
			setCantidadArtesanosAMostrar((current) => current + datosPorPagina);

			setCantidadFilasAMostrar(cantidadFilasAMostrar + 3);
			setCantidadMostrarMas(cantidadMostrarMas + 1);
		}
	};

	const cambiarPaginaActual = (pagina) => {
		setCantidadFilasAMostrar(3);
		setCantidadArtesanosAMostrar(datosPorPagina);
		setPaginaActual(pagina);
		setCantidadMostrarMas(0);
	};

	return (
		<>
			<CategoriaSeleccionadasContext.Provider
				value={{
					categoriasSeleccionadas,
					setCategoriasSeleccionadas,
					tiposArtesanosSeleccionados,
					setTiposArtesanosSeleccionados,
				}}
			>
				<DepartamentosSeleccionadosContext.Provider
					value={{ departamentosSeleccionados, setDepartamentosSeleccionados }}
				>
					<OrdenamientoSeleccionadoContext.Provider
						value={{ ordenamientoSeleccionado, setOrdenamientoSeleccionado }}
					>
						<Container fluid='sm' className='links-container'>
							<Link to='/' className='link-component'>
								Inicio
							</Link>
							{" > "}
							<Link to='/artesanos' className='link-component'>
								Artesanos
							</Link>
						</Container>
						<MainLayout
							title={datosPagina.TituloLabel}
							componentesLaterales={[
								<TiposArtesanos subtitulo={datosPagina.FiltrarPorLabel} />,
								<CategoriasArtesano subtitulo={datosPagina.CategoriasLabel} />,
								<OrdenArtesanos subtitulo={datosPagina.OrdenLabel} />,
								<DepartamentoArtesanos
									subtitulo={datosPagina.DepartamentoLabel}
								/>,
							]}
							MainContent={<ListaArtesanos Artesanos={artesanosState} />}
						/>

						<Paginacion
							mostrarMasResultados={mostrarMasResultados}
							setPaginaActual={cambiarPaginaActual}
							paginaActual={paginaActual}
							cantidadPaginas={cantidadPaginas}
							mostrarMasLabel={datosPagina.MostrarMasLabel}
						/>
					</OrdenamientoSeleccionadoContext.Provider>
				</DepartamentosSeleccionadosContext.Provider>
			</CategoriaSeleccionadasContext.Provider>
		</>
	);
};
