import AUBlack from "../../assets/logo-au-black_2022-02-12/logo-au-black.svg";
import searchBlack from "../../assets/icons/search/search-black.svg";
import hambBlack from "../../assets/icons/mobileMenu/hamburguer-menu-black.svg";

const whiteViews = [
	"artesanos",
	"artesano",
	"historias",
	"noticias",
	"resultados",
	"EdicionesLibros",
	"Afiches",
	"resultadosSimple",
	"resultadosPorTag",
	"resultadosPorCategoria",
	"Indicadores",
];

export const isWhite = (location = "") => {
	return (
		whiteViews.includes(location.split("/")[1]) ||
		whiteViews.includes(location.split("/")[3])
	);
};

const blackViews = ["descargar"];

export const isBlack = (location = "") => {
	return blackViews.includes(location.split("/")[1]);
};

export const whiteNav = () => {
	let mobile = window.innerWidth < 992;

	if (!mobile) {
		let header = document.getElementById("navbar");
		let links = document.getElementById("navLinks");
		let logoAU = document.getElementById("navLogo");

		header.classList.add("scrolling-active");
		links.classList.add("scrolling-unactive-links");
		links.classList.add("scrolling-active-links");

		logoAU.src = AUBlack;
		let logoSearch = document.getElementById("searchIcon");
		logoSearch.src = searchBlack;
	} else {
		let header = document.getElementById("navbar");
		let links = document.getElementById("navLinks");
		let logoAU = document.getElementById("navLogo");

		header.classList.add("scrolling-active");
		links.classList.add("scrolling-unactive-links");
		links.classList.add("scrolling-active-links");

		logoAU.src = AUBlack;

		let hambMenu = document.getElementsByClassName("navbar-toggler-icon");
		let idiomas = document.getElementById("navDropdown-Lang");
		hambMenu[0].style.backgroundImage = `url(${hambBlack})`;
		idiomas.classList.add("languages-scrolling");
	}
};

export const blackNav = () => {
	let links = document.getElementById("navLinks");
	let logoAU = document.getElementById("navLogo");

	links.classList.add("scrolling-active-links");

	logoAU.src = AUBlack;
	let mobile = window.innerWidth < 992;

	if (!mobile) {
		let logoSearch = document.getElementById("searchIcon");
		logoSearch.src = searchBlack;
	} else {
		let hambMenu = document.getElementsByClassName("navbar-toggler-icon");
		let idiomas = document.getElementById("navDropdown-Lang");
		hambMenu[0].style.backgroundImage = `url(${hambBlack})`;
		idiomas.classList.add("languages-scrolling");
	}
};
