import { useContext, useMemo } from "react";

import { useFetchDepartamentosArtesanos } from "../../../hooks/useFetchDepartamentosArtesanos";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { CheckDropdownDepartamentoArtesano } from "../../shared/DropdownsItems/Checkbox/CheckDropdownDepartamentoArtesano";
import { DepartamentosSeleccionadosContext } from "../ArtesanosContext";

export const DepartamentoArtesanos = ({ subtitulo = "" }) => {
	const { departamentosSeleccionados, setDepartamentosSeleccionados } =
		useContext(DepartamentosSeleccionadosContext);

	const { data, loading } = useFetchDepartamentosArtesanos();
	const currentSize = useWindowsSize();

	const departamentos = useMemo(
		() =>
			loading ? [] : data.filter((x) => x.attributes.artesanos.data.length > 0),
		[loading, data]
	);

	const handleInputChange = (departamento) => {
		if (!departamentosSeleccionados.includes(departamento.id)) {
			setDepartamentosSeleccionados([
				...departamentosSeleccionados,
				departamento.id,
			]);
		} else {
			setDepartamentosSeleccionados(
				departamentosSeleccionados.filter((dep) => dep !== departamento.id)
			);
		}
	};

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='col-6'>
					<CheckDropdownDepartamentoArtesano
						items={departamentos}
						title={subtitulo}
						handleInputChange={handleInputChange}
					/>
				</div>
			) : (
				<div className='component-box col-12'>
					<h6 className='box-title'>{subtitulo}</h6>
					{loading ? (
						<p>Loading</p>
					) : (
						departamentos.map((departamento) => (
							<div
								key={departamento.id}
								onClick={() => handleInputChange(departamento)}
								className='mb-2'
							>
								<input
									type='checkbox'
									checked={departamentosSeleccionados.includes(departamento.id)}
									onChange={() => null}
								/>
								<label className='input-label'>
									{`${departamento.attributes.Departamento} (${departamento.attributes.artesanos.data.length})`}
								</label>
							</div>
						))
					)}
				</div>
			)}
		</>
	);
};
