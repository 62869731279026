import { getCollectionData } from "../../../../helpers/getCollectionData";

export const obtenerArtesanosPorCategoria = async (
	nombreCategoria = "",
	idioma
) => {
	const qs = require("qs");
	const query = qs.stringify({
		sort: ["createdAt:desc"],
		filters: {
			Categoria: { $containsi: nombreCategoria },
		},
		populate: ["artesanos", "artesanos.Imagen", "artesanos.Imagen.Imagen"],
	});

	const { data } = await getCollectionData(
		"categorias-artesanos",
		query,
		idioma
	);

	return {
		data,
	};
};
