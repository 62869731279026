import React from "react";
import { HeroSlider } from "../components/heroSlider/HeroSlider";
import usePageTracking from "../hooks/usePageTracking";
import { useTitle } from "../hooks/useTitle";
import Galleries from "./Galleries/Galleries";

const Home = () => {
	useTitle("Inicio");
	usePageTracking();

	return (
		<>
			<HeroSlider />
			<Galleries />
		</>
	);
};

export default Home;
