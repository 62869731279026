import { eliminaRepetidosArray } from "../../../../helpers/eliminaRepetidosArray";
import { searchData } from "./searchData";

export const obtenerHistorias = async (
  paginaActual = 1,
  cantidadResultados = 5,
  tituloBuscado = "",
  idioma
) => {
  const qs = require("qs");
  const query = qs.stringify({
    pagination: {
      sort: ["createdAt:desc"],
      page: paginaActual,
      pageSize: cantidadResultados,
    },
    filters: {
      $or: [
        {
          Titulo: { $containsi: tituloBuscado },
        },
        {
          Autor_Autora: { $containsi: tituloBuscado },
        },
      ],
    },
    populate: ["Portada.Imagen"],
    fields: ["Titulo", "publishedAt", "Autor_Autora", "Descripcion"],
  });

  const { data } = await searchData(
    "historias",
    query,
    idioma
  );

  const historiaTotalSinRepetidos = eliminaRepetidosArray(data);

  return {
    dataHistorias: historiaTotalSinRepetidos,
    pageCountHistorias: historiaTotalSinRepetidos.length / cantidadResultados,
  };
};
