import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import DesktopNavbar from "./DesktopNavbar";
import { isWhite } from "./whiteViews";
import { getCollectionData } from "../../helpers/getCollectionData";

const Navigationbar = () => {
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosNavbar, setDatosNavbar] = useState({});

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatosNavbar = async () => {
				const {
					ArtesanosLabel,
					HistoriasLabel,
					NoticiaLabel,
					Institucional,
					ProgramasLabel,
					ArtesaniasCanchasLabel,
					CasasArtesaniaLabel,
					PlanEntrategicoLabel,
					ActaComisionesLabel,
					DinapymeLabel,
					PostulacionesLabel,
					PreguntasFrecuentesLabel,
					PremioNacionalArtesaniaLabel,
					BuscadorTexto,
					BuscarMobile,
					TalleresDisenioLabel,
					FeriasYEventosLabel,
					RegistroNacionalLabel,
					ParticipacionInternacional,
					AcercaDeAU,
					Indicadores,
				} = (await getCollectionData("navigation-bars", "", estadoIdioma, 1))
					.data[0].attributes;

				setDatosNavbar({
					ArtesanosLabel: ArtesanosLabel,
					HistoriaLabel: HistoriasLabel,
					NoticiaLabel: NoticiaLabel,
					Institucional: Institucional,
					ProgramasLabel: ProgramasLabel,
					ArtesaniasCanchasLabel: ArtesaniasCanchasLabel,
					CasasArtesaniaLabel: CasasArtesaniaLabel,
					PlanEntrategicoLabel: PlanEntrategicoLabel,
					ActaComisionesLabel: ActaComisionesLabel,
					DinapymeLabel: DinapymeLabel,
					PostulacionesLabel: PostulacionesLabel,
					PreguntasFrecuentesLabel: PreguntasFrecuentesLabel,
					PremioNacionalArtesaniaLabel: PremioNacionalArtesaniaLabel,
					BuscadorTexto: BuscadorTexto,
					BuscarMobile: BuscarMobile,
					TalleresDisenioLabel: TalleresDisenioLabel,
					FeriasYEventosLabel: FeriasYEventosLabel,
					RegistroNacionalLabel,
					ParticipacionInternacional,
					AcercaDeAU,
					Indicadores,
				});
			};
			obtenerDatosNavbar();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	const [isWhiteNavbar, setIsWhiteNavbar] = useState(false);

	const onScrollNavbar = () => {
		setIsWhiteNavbar(window.scrollY > 0 || isWhite(location.pathname));
	};

	/* Navbar class handle */
	const location = useLocation();

	useEffect(() => {
		window.addEventListener("scroll", onScrollNavbar, true);
		setIsWhiteNavbar(isWhite(location.pathname));

		return () => {
			window.removeEventListener("scroll", onScrollNavbar, true);
		};
		// eslint-disable-next-line
	}, [location.pathname]);

	return (
		<DesktopNavbar datosNavbar={datosNavbar} isWhiteNavbar={isWhiteNavbar} />
	);
};

export default Navigationbar;
