export const searchData = async (collection, query = "", idioma) => {
  const inicialResponse = {
    data: [],
    page: 0,
    pageCount: 0,
    pageSize: 0,
    total: 0,
  };
  try {
    const base_url = `${process.env.REACT_APP_BASE_URL}`;
    const urlCompleta = `${base_url}/api/${collection}?${query}&locale=${idioma.code}`;
    const response = await fetch(urlCompleta);
    const dataRest = await response.json();

    if (!dataRest.error)
      return {
        data: dataRest?.data,
        ...dataRest?.meta.pagination,
      };
    return inicialResponse;
  } catch (error) {
    return inicialResponse;
  }
};
