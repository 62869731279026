export const getCollectionData = async (
  collection,
  query = "",
  idioma,
  datosPorPagina = 9
) => {
  try {
    const base_url = `${process.env.REACT_APP_BASE_URL}`;
    const urlCompleta = `${base_url}/api/${collection}?locale=${idioma.code}&${query}`;
    const response = await fetch(urlCompleta);
    const dataRest = await response.json();

    if (dataRest.error) {
      return {
        data: [],
        paginas: 0,
      };
    }

    return {
      data: dataRest?.data,
      paginas: dataRest?.meta.pagination.pageCount,
      pagination: dataRest?.meta.pagination
    };

  } catch (error) {

    return {
      data: [],
      paginas: 0,
    };
  }
};
