import React from "react";

import { useWindowsSize } from "../../../../../../hooks/useWindowsSize";
import { ordenamientos } from "../../../../../../types/ordenamientos";
import { WindowSizeType } from "../../../../../../types/WindowSizeType";
import { RadioButtonDropdown } from "../../../../../shared/DropdownsItems/radioButton/RadioButtonDropdown";

export const LateralMenu = ({
	setOrdenamiento,
	subtitulo = "",
	ordenamientoSeleccionado = "",
}) => {
	const ordenar = [
		ordenamientos.MasReciente,
		ordenamientos.MenosRecientes,
		ordenamientos.Aleatorio,
	];

	const handleInputChange = (e) => {
		setOrdenamiento(e.target.value);
	};

	const currentSize = useWindowsSize();

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='mb-4'>
					<div className='row mb-2'>
						<div className='col-6'>
							<RadioButtonDropdown
								handleInputChange={handleInputChange}
								items={ordenar}
								title={subtitulo}
								currentValue={ordenamientoSeleccionado}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className='lateral-menu'>
					<div className='component-box'>
						<h6 className='box-title'>{subtitulo}</h6>
						{ordenar.map((ord, i) => (
							<div key={i} className='mb-2'>
								<input
									type='radio'
									value={ord}
									name='orden'
									onChange={(e) => handleInputChange(e)}
									checked={ord === ordenamientoSeleccionado}
								/>
								<label className='input-label'>{ord}</label>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};
