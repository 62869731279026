export const eliminaRepetidosArray = (array = []) => {
  var arrayResponse = [];
  array.forEach((item) => {
    const elementoActual = item.id;

    if (arrayResponse.length > 0) {
      let sonIguales = false;

      for (
        let index = 0;
        index < arrayResponse.length && !sonIguales;
        index++
      ) {
        const itemComparar = arrayResponse[index].id;

        sonIguales = elementoActual === itemComparar;
      }

      if (!sonIguales) {
        arrayResponse.push(item);
      }
    } else {
      arrayResponse.push(item);
    }
  });
  return arrayResponse;
};
