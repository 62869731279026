import { obtenerArtesanosPorCategoria } from "./obtenerArtesanosPorCategoria";
import { obtenerHistoriasPorCategoria } from "./obtenerHistoriasPorCategoria";
import { obtenerNoticiasPorCategoria } from "./obtenerNoticiasPorCategoria";

export const obtenerResultadosDeBusqueda = async (
  nombreCategoria = "",
  idioma,
  cantidadResultados = 4
) => {
  const dataArtesanos = (
    await obtenerArtesanosPorCategoria(nombreCategoria, idioma)
  ).data;
  const artesanosProcesadas =
    dataArtesanos.length !== 0
      ? dataArtesanos[0].attributes.artesanos.data
      : [];

  const dataNoticias = (
    await obtenerNoticiasPorCategoria(nombreCategoria, idioma)
  ).data;
  const noticiasProcesadas =
    dataNoticias.length !== 0 ? dataNoticias[0].attributes.noticias.data : [];

  const dataHistorias = (
    await obtenerHistoriasPorCategoria(nombreCategoria, idioma)
  ).data;
  const historiasProcesadas =
    dataHistorias.length !== 0
      ? dataHistorias[0].attributes.historias.data
      : [];

  const pags = Math.ceil(
    (Number(artesanosProcesadas.length) +
      Number(noticiasProcesadas.length) +
      Number(historiasProcesadas.length)) /
      Number(cantidadResultados)
  );

  return {
    artesanosList: artesanosProcesadas,
    historiasList: historiasProcesadas,
    noticiasList: noticiasProcesadas,
    pages: pags,
  };
};
