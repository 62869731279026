import React, { useState, useMemo, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "../Results.css";
import { ListaResults } from "./ListaResults";
import { obtenerResultadosDeBusqueda } from "./helpers/obtenerResultadosDeBusqueda";
import { BotonMostrarMas } from "../../shared/BotonMostrarMas/BotonMostrarMas";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { getCollectionData } from "../../../helpers/getCollectionData";
import { useTitle } from "../../../hooks/useTitle";
import usePageTracking from "../../../hooks/usePageTracking";

export const BusquedaSimple = () => {
	const { busqueda } = useParams();

	useTitle(`Busqueda - ${busqueda}`);
	usePageTracking();

	const [resultsState, setResultsState] = useState();
	const [cantidadResultados, setCantidadResultados] = useState(5);
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		MostrarMasLabel: "",
	});

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const { TituloLabel, MostrarMasLabel } = (
					await getCollectionData(
						"pagina-resultados-busquedas",
						"",
						estadoIdioma,
						1
					)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					MostrarMasLabel: MostrarMasLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	const mostrarMasResultados = () => {
		setCantidadResultados(cantidadResultados + 5);
	};

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté
		const obtenerResultados = async () => {
			const {
				dataArtesanosCompleto,
				dataHistoriasCompleto,
				dataNoticiasCompleto,
			} = await obtenerResultadosDeBusqueda(
				1,
				cantidadResultados,
				busqueda,
				estadoIdioma
			);

			setResultsState({
				artesanos: dataArtesanosCompleto,
				historias: dataHistoriasCompleto,
				noticias: dataNoticiasCompleto,
			});
		};
		obtenerResultados();
	}, [busqueda, cantidadResultados, estadoIdioma]);

	const currentSize = useWindowsSize();

	const procesarTextoBusqueda = (texto = "") => {
		let response = texto;
		switch (currentSize) {
			case WindowSizeType.xs:
				response =
					texto.length > 20 ? texto.substring(0, 20).concat("...") : texto;
				break;
			case WindowSizeType.sm:
				response =
					texto.length > 20 ? texto.substring(0, 20).concat("...") : texto;
				break;

			case WindowSizeType.lg:
				response =
					texto.length > 100 ? texto.substring(0, 100).concat("...") : texto;
				break;

			default:
				response =
					texto.length > 60 ? texto.substring(0, 60).concat("...") : texto;
				break;
		}

		return response;
	};

	return (
		<Container fluid='sm' className='links-container'>
			<Link to='/' className='link-component'>
				Inicio
			</Link>
			{" > "}
			<Link to={`/resultadosSimple/${busqueda}`} className='link-component'>
				Resultados
			</Link>
			{" > "}
			<Link to={`/resultadosSimple/${busqueda}`} className='link-component'>
				"{procesarTextoBusqueda(busqueda)}"
			</Link>
			<div className='title'>
				<span>{datosPagina.TituloLabel}</span>
			</div>
			<hr className='hr-main-layout' />
			<div className='results'>
				<ListaResults
					Results={resultsState}
					cantidadResultados={cantidadResultados}
				/>
			</div>
			<div className='contenedor-boton-mostrar-mas'>
				<BotonMostrarMas
					mostrarMasResultados={mostrarMasResultados}
					mostrarMasLabel={datosPagina.MostrarMasLabel}
				/>
			</div>
		</Container>
	);
};
