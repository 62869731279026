export const orderArrayArtesanosPorImagenes = (
  array = [],
  espaciosEnFila = 3
) => {
  let responseArray = [];

  let espaciosDisponibles = espaciosEnFila;
  let horizontalesDescartadas = [];

  let index = 0;

  while (array.lenght !== 0 && index < array.length) {
    let valor = array[index].attributes.Imagen[0].Horizontal ? 2 : 1;

    //La imagen entra en el próximo lugar
    if (valor <= espaciosDisponibles) {
      //Guardo el valor
      responseArray = [...responseArray, array[index]];

      espaciosDisponibles = espaciosDisponibles - valor;
    } else {
      //Caso donde solo puede entrar una imagen vertical pero viene una horizontal

      //Guardo el valor
      horizontalesDescartadas = [...horizontalesDescartadas, array[index]];
    }

    //Caso donde llené toda la fila => la reinicio
    if (espaciosDisponibles === 0) {
      espaciosDisponibles = espaciosEnFila;
    }
    index++;
  }

  let indexArrayDescartados = 0;
  while (
    horizontalesDescartadas.lenght !== 0 &&
    indexArrayDescartados < horizontalesDescartadas.length
  ) {
    responseArray = [
      ...responseArray,
      horizontalesDescartadas[indexArrayDescartados],
    ];

    indexArrayDescartados++;
  }
  // }

  return responseArray;
};
