import React, { useMemo, useState } from "react";
import { Button, ButtonGroup, Container } from "react-bootstrap";

import "./Paginacion.css";
import rbarrow from "../../../assets/icons/arrow-black-right/arrow-black-right.svg";
import rlarrow from "../../../assets/icons/arrow-black-left/arrow-black-left.svg";
import { BotonMostrarMas } from "../BotonMostrarMas/BotonMostrarMas";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { useWindowsSize } from "../../../hooks/useWindowsSize";

export const Paginacion = ({
	paginaActual = 1,
	setPaginaActual,
	mostrarMasResultados,
	cantidadPaginas,
	mostrarMasLabel,
}) => {
	const [botonesPaginacion, setBotonesPaginacion] = useState([]);

	useMemo(() => {
		const nI = <img src={rbarrow} alt='next arrow' />;
		const pI = <img src={rlarrow} alt='previous arrow' />;

		//Agrego la flecha y la pagina 1
		let items = [
			<Button
				className='boton-paginacion button-start ps-0'
				variant='link'
				key={0}
				onClick={() => setPaginaActual(paginaActual - 1)}
				disabled={paginaActual <= 1}
				name='previous button'
			>
				{pI}
			</Button>,
			<Button
				className={
					1 === paginaActual
						? "me-2 boton-paginacion-active"
						: "me-2 boton-paginacion"
				}
				variant='link'
				key={1}
				onClick={() => setPaginaActual(1)}
				name='button 1'
			>
				{1}
			</Button>,
		];

		if (cantidadPaginas > 1) {
			if (paginaActual < 5) {
				//Caso donde tenemos menos de 5 páginas
				for (
					let i = 2;
					i <= cantidadPaginas && i < 6 && i !== cantidadPaginas;
					i++
				) {
					items = [
						...items,
						<Button
							className={
								i === paginaActual
									? "me-2 boton-paginacion-active"
									: "me-2 boton-paginacion"
							}
							variant='link'
							key={i}
							onClick={() => setPaginaActual(i)}
							name={`button ${i}`}
						>
							{i}
						</Button>,
					];
				}

				if (cantidadPaginas - paginaActual > 5)
					items = [
						...items,
						<Button key={cantidadPaginas - 1} className='me-2 boton-paginacion'>
							{"..."}
						</Button>,
					];

				items = [
					...items,
					<Button
						className={
							cantidadPaginas === paginaActual
								? "me-2 boton-paginacion-active"
								: "me-2 boton-paginacion"
						}
						variant='link'
						key={cantidadPaginas}
						onClick={() => setPaginaActual(cantidadPaginas)}
						name={`button ${cantidadPaginas}`}
					>
						{cantidadPaginas}
					</Button>,
				];
			} else {
				items = [
					...items,
					<Button key={2} className='me-2 boton-paginacion'>
						{"..."}
					</Button>,
				];
				//Caso donde tenemos mas de 5 paginas

				//Recorro los valores dos lugares atrás del actual
				for (let i = 2; 1 < paginaActual - i && i > 0; i--) {
					items = [
						...items,
						<Button
							className='me-2 boton-paginacion'
							variant='link'
							key={paginaActual - i}
							onClick={() => setPaginaActual(paginaActual - i)}
							name={`button ${paginaActual - i}`}
						>
							{paginaActual - i}
						</Button>,
					];
				}

				items = [
					...items,
					<Button
						className={"me-2 boton-paginacion-active"}
						variant='link'
						key={paginaActual}
						name={`button ${paginaActual}`}
					>
						{paginaActual}
					</Button>,
				];

				//Recorro dos lugares despues del actual
				for (let i = 1; i < cantidadPaginas - paginaActual && i < 3; i++) {
					items = [
						...items,
						<Button
							className={"me-2 boton-paginacion"}
							variant='link'
							key={paginaActual + i}
							onClick={() => setPaginaActual(paginaActual + i)}
							name={`button ${paginaActual + i}`}
						>
							{paginaActual + i}
						</Button>,
					];
				}

				if (cantidadPaginas - paginaActual > 3)
					items = [
						...items,
						<Button key={cantidadPaginas - 1} className='me-2 boton-paginacion'>
							{"..."}
						</Button>,
					];

				if (cantidadPaginas !== paginaActual)
					items = [
						...items,
						<Button
							className={
								cantidadPaginas === paginaActual
									? "me-2 boton-paginacion-active"
									: "me-2 boton-paginacion"
							}
							variant='link'
							key={cantidadPaginas}
							onClick={() => setPaginaActual(cantidadPaginas)}
							name={`button ${cantidadPaginas}`}
						>
							{cantidadPaginas}
						</Button>,
					];
			}
		}

		items = [
			...items,
			<Button
				className='boton-paginacion pe-0 button-end'
				variant='link'
				key={cantidadPaginas + 15}
				onClick={() => setPaginaActual(paginaActual + 1)}
				disabled={paginaActual >= cantidadPaginas}
				name={`next button`}
			>
				{nI}
			</Button>,
		];

		setBotonesPaginacion(items);
	}, [paginaActual, cantidadPaginas, setPaginaActual]);

	const windowSize = useWindowsSize();

	return (
		<Container fluid='sm' className='componente-paginacion'>
			{windowSize === WindowSizeType.xs ? (
				<>
					<div className='row'>
						<ButtonGroup className='col-12 '>{botonesPaginacion}</ButtonGroup>
						<div className='col-12 mt-4 contenedor-mostrar-mas-mobile '>
							<BotonMostrarMas
								mostrarMasResultados={mostrarMasResultados}
								mostrarMasLabel={mostrarMasLabel}
							/>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='row'>
						<div className='col-3'></div>
						<div className='col-md-9 col-12 d-flex p-0'>
							<div className='col'>
								<BotonMostrarMas
									mostrarMasResultados={mostrarMasResultados}
									mostrarMasLabel={mostrarMasLabel}
								/>
							</div>
							<ButtonGroup className='col contenedor-paginacion float-right'>
								{botonesPaginacion}
							</ButtonGroup>
						</div>
					</div>
				</>
			)}
		</Container>
	);
};
