import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import { CheckDropdownItem } from "../../../../shared/DropdownsItems/Checkbox/CheckboxItem";
import { CheckboxMenu } from "../../../../shared/DropdownsItems/Checkbox/CheckboxMenu";

export const CheckboxDropdown = ({
	items = [],
	title = "",
	handleInputChange,
}) => {
	const handleChecked = (e, item) => {
		e.stopPropagation();
		handleInputChange(e, item);
	};

	const [flechaDropdown, setFlechaDropdown] = useState(false);

	const [isShown, setIsShown] = useState(false);

	const flipArrow = () => {
		setFlechaDropdown(!flechaDropdown);
		setIsShown(!isShown);
	};

	return (
		<Dropdown show={isShown}>
			<Dropdown.Toggle className='todo-width'>
				<div onClick={flipArrow}>
					<span>{title}</span>
					{flechaDropdown ? (
						<span id='programasIcon' className='flecha-algo'>
							^
						</span>
					) : (
						<span id='programasIcon' className='arrow-flip-drop flecha-algo'>
							^
						</span>
					)}
				</div>
			</Dropdown.Toggle>

			<Dropdown.Menu as={CheckboxMenu} className='col-6'>
				{items.map((i) => (
					<Dropdown.Item
						key={i.id}
						as={CheckDropdownItem}
						id={i.id}
						onClick={(e) => handleChecked(e, i)}
					>
						{i.attributes.Categoria}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};
