import React, { useMemo } from "react";
import { ResultadoItem } from "../ResultItem";

export const ListaResultados = ({
	Results = [],
	pagina = 1,
	cantidadResultados = 5,
}) => {
	const resultadosParseados = useMemo(() => {
		if (Results.length > 0) {
			const res = Results[0].attributes;

			const artesanosParseados = res.artesanos.data.map((artesano) => ({
				title: artesano.attributes.Nombre,
				descripcion: artesano.attributes.Descripcion,
				date: artesano.attributes.publishedAt,
				link: `/artesanos/${artesano.id}`,
				img: `${process.env.REACT_APP_BASE_URL}${artesano.attributes.Imagen[0].Imagen.data.attributes.url}`,
			}));

			const historiasParseados = res.historias.data.map((historia) => ({
				title: historia.attributes.Titulo,
				descripcion: historia.attributes.Descripcion,
				date: historia.attributes.publishedAt,
				link: `/historias/${historia.id}`,
				img: `${process.env.REACT_APP_BASE_URL}${historia.attributes.Portada.Imagen.data.attributes.url}`,
			}));

			const noticiasParseados = res.noticias.data.map((noticia) => ({
				title: noticia.attributes.Titulo,
				descripcion:
					noticia.attributes.Resumen ||
					noticia.attributes.Contenido.substring(0, 100),
				date: noticia.attributes.Fecha,
				link: `/noticias/${noticia.attributes.slug || noticia.id}`,
				img: `${process.env.REACT_APP_BASE_URL}${noticia.attributes.Portada.Imagen.data.attributes.url}`,
			}));

			return [
				...noticiasParseados,
				...historiasParseados,
				...artesanosParseados,
			].sort((a, b) => b.date.localeCompare(a.date));
		}
	}, [Results]);

	return (
		<>
			{resultadosParseados
				?.slice((pagina - 1) * cantidadResultados, pagina * cantidadResultados)
				.map((resultado, index) => (
					<ResultadoItem key={resultado.title + index} resultado={resultado} />
				))}
		</>
	);
};
