import React from "react";

import nI from "../../../assets/icons/arrow-white-right_2022-02-14/arrow-white-right.svg";
import pI from "../../../assets/icons/arrow-white-left_2022-02-14/arrow-white-left.svg";
import "../HeroSlider.css";

export const HeroControls = ({ handleClickPrev, handleClickNext }) => {
	return (
		<div id='heroControls'>
			<div>
				<img
					className='carouselArrowPrev'
					onClick={handleClickPrev}
					src={pI}
					alt='flecha anterior'
				/>
			</div>
			<div>
				<img
					className='carouselArrowNext'
					onClick={handleClickNext}
					src={nI}
					alt='flecha siguiente'
				/>
			</div>
		</div>
	);
};

export default HeroControls;
