import React, { useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";

import "./Footer.css";
import styles from "./NewFooter.module.css";
import LoadingSpinner from "../shared/LoadingSpinner/LoadingSpinner";
import logo from "../../assets/icons/logo-ministerio/logo-ministerio.svg";
import { useFetchFooter } from "../../hooks/useFetchFooter";
import { getIdiomas } from "../../helpers/getIdiomas";
import { cambiarIdiomaActual } from "../../actions/Idioma";
import { useWindowsSize } from "../../hooks/useWindowsSize";
import { WindowSizeType } from "../../types/WindowSizeType";
import { FooterMobile } from "./Mobile/FooterMobile";
import { Rutas } from "../../routes/Routes";

function Footer() {
	const { data, loading } = useFetchFooter();

	const [idiomas, setIdiomas] = useState([]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerIdiomas = async () => {
				const idiomasBD = (await getIdiomas()).data;

				setIdiomas(idiomasBD);
			};
			obtenerIdiomas();
		}
		return () => (isMounted = false);
	}, []);

	const obtenerTextoIdioma = (codigoIdioma = "") => {
		if (codigoIdioma.slice(0, 2) === "es") return "Español";
		else if (codigoIdioma.slice(0, 2) === "en") return "English";
		else return "";
	};

	const dispatch = useDispatch();
	const windowSize = useWindowsSize();

	const links_col_1 = [
		{ label: data.InicioLabel, link: "/" },
		{ label: data.ArtesanosLabel, link: "/artesanos" },
		{ label: data.HistoriasLabel, link: "/historias" },
		{ label: data.NoticiasLabel, link: "/noticias" },
		{
			label: data.PremioNacionalArtesania,
			link: "/programas/PremioNacionalArtesanias",
		},
		{ label: data.ArtesaniasCancha, link: "/programas/ArtesaniasALaCancha" },
		{ label: data.CasasArtesania, link: "/programas/CasasDeArtesania" },
		{ label: data.TalleresDisenioLabel, link: "/programas/TalleresDisenio" },
		{ label: data.RegistroNacional, link: "/RegistroNacional" },
		{
			label: data.ParticipacionInternacional,
			link: "/ParticipacionInternacional",
		},
	];

	const links_col_2 = [
		{ label: data.Indicadores, link: Rutas.Indicadores },
		{ label: data.Postulaciones, link: "/Postulaciones" },
		{ label: data.FeriaYEventos, link: "/FeriasyEventos" },
		{ label: data.PlanEstrategico, link: "/PlanEstrategico" },
		{ label: data.ActaComisiones, link: "/ActaDeComisiones" },
		{ label: data.Dinapyme, link: "/Dinapyme" },
		{ label: data.PreguntasFrecuentes, link: "/PreguntasFrecuentes" },
		{ label: data.AcercaDeAU, link: "/AcercaDeAU" },
	];

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					{windowSize === WindowSizeType.xs ? (
						<>
							<FooterMobile data={data} idiomas={idiomas} />
						</> /* Quizas todo esto (y tambien la navbar) deberian ir en un container*/
					) : (
						<div className='main-footer col-12'>
							<Container fluid='sm'>
								<div className='row'>
									{/* Column1 */}
									<div className='col-4' id='minLogo'>
										<ul className='list-unstyled'>
											<li>
												<a
													target='_blank'
													href={data.EnlaceMiem.Enlace}
													rel='noopener noreferrer'
												>
													<img
														src={logo}
														alt='AU Logo'
														className={"footerLogo"}
													/>
												</a>
											</li>
											<li>
												<p>
													Calle Rincón 719 piso 2 - C.P. 11000 <br />
													Montevideo, República Oriental del Uruguay
													<br />
													(+598) 2840 1234 int: 3118 / 3138
													<br />
													<a
														href='http://www.dinapyme.gub.uy'
														target='_blank'
														rel='noopener noreferrer'
														className='unstyled-link'
													>
														www.dinapyme.gub.uy
													</a>
												</p>
											</li>
										</ul>
										<p className={styles.apoya_title}>{data.Apoya}</p>

										<div className={`${styles.contenedor_apoya}`}>
											{data.ApoyaIcons.map((icon, i) => (
												<div key={icon.Imagen.data.id}>
													<a
														target='_blank'
														href={icon.LinkVideo}
														rel='noopener noreferrer'
													>
														<img
															src={`${process.env.REACT_APP_BASE_URL}${icon.Imagen.data.attributes.url}`}
															alt={`Apoya ${i}`}
															style={{ height: 60 }}
														/>
													</a>
												</div>
											))}
										</div>
									</div>
									{/* Column2 */}
									<div className='col-4'>
										<div className='row'>
											<div className='col'>
												<ul className='list-unstyled'>
													{links_col_1.map((link, i) => (
														<li key={link.label + i}>
															<a
																className={`unstyled-link ${styles.content_link}`}
																href={link.link}
															>
																{link.label}
															</a>
														</li>
													))}
												</ul>
											</div>
											<div className='col d-flex flex-column'>
												<ul className='list-unstyled'>
													{links_col_2.map((link, i) => (
														<li key={link.label + i}>
															<a
																className={`unstyled-link ${styles.content_link}`}
																href={link.link}
															>
																{link.label}
															</a>
														</li>
													))}

													{idiomas.map((idioma) => (
														<li key={idioma.id}>
															<span
																className={`unstyled-link pointer-footer ${styles.content_link}`}
																onClick={() =>
																	dispatch(cambiarIdiomaActual(idioma.code))
																}
															>
																{obtenerTextoIdioma(idioma.code)}
															</span>
														</li>
													))}
												</ul>

												<div className='d-flex'>
													{data.RedesSociales.map((red) => (
														<SocialIcon
															key={red.id}
															url={red.Enlace}
															bgColor={"white"}
															style={{
																height: "24px",
																width: "24px",
																marginRight: 10,
															}}
														/>
													))}
												</div>
											</div>
										</div>
									</div>

									{/* Column3 */}

									<div id='to-register' className='col-4'>
										<div className='registerDiv'>
											<p>{data.IngreseARegistroLabel}</p>
											<ul className='list-unstyled'>
												<li>
													<a
														target='_blank'
														rel='noopener noreferrer'
														className={`unstyled-link ${styles.registro_footer}`}
														href={data.EnlaceUnirseArtesano.Enlace}
													>
														{data.IrAlRegistro}
														<img
															id='arrowReg'
															src={require("../../assets/icons/arrow-white-right_2022-02-14/arrow-white-right.png")}
															alt='whiteArrow'
														/>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</Container>{" "}
						</div>
					)}
				</>
			)}
		</>
	);
}

export default Footer;
