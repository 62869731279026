import styles from "./SugerirDatos.module.css";

export const SugerirDatos = ({ title = "", children }) => {
	return (
		<div className='container-sm mb-5'>
			<hr className={styles.hr} />
			<h2 className={styles.title}>{title}</h2>
			{children}
		</div>
	);
};
