import React, { Suspense } from "react";
import "./Galleries.css";
import arrow from "../../assets/icons/arrow-black-right/arrow-black-right.svg";
import { useLazyLoad } from "../../hooks/useLazyLoad";

export const Galeria = ({
	title = "",
	gallery,
	vertodolink = "#",
	varTodoLabel = "",
}) => {
	const { isNear, elementRef } = useLazyLoad();

	return (
		<div className='gallery' ref={elementRef}>
			<Suspense fallback={<div style={{ minHeight: 300 }}>Cargando....</div>}>
				{isNear && (
					<>
						<span>
							<a href={vertodolink} className='title_link_home'>
								{title}
							</a>
						</span>
						<div style={{ marginTop: 50 }}>{gallery}</div>

						<div className='ver-todo'>
							<a className='ver-todo' href={vertodolink}>
								<p>{varTodoLabel}</p>
								<img alt='flecha' src={arrow} />
							</a>
						</div>
					</>
				)}
			</Suspense>
		</div>
	);
};

export default Galeria;
