import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import { useFetchPremioNacionalArtesania } from "../../../hooks/useFetchPremioArtesania";
import { useGetLabelsInstitucionales } from "../../../hooks/useGetLabelsInstitucionales";
import usePageTracking from "../../../hooks/usePageTracking";
import { useTitle } from "../../../hooks/useTitle";
import { useWindowsSize } from "../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../types/WindowSizeType";
import { CarrouselLabels } from "../../shared/CarrouselLabelInstitucional/CarrouselLabels";

export const PremioNacionalArtesania = () => {
	const { data, loading } = useFetchPremioNacionalArtesania();
	const { seccionesInstitucionales } = useGetLabelsInstitucionales();

	const { Contenido, Fondo, Titulo, AfichesLabel, EdicionesLibrosLabel } =
		loading ? { Contenido: "", Fondo: "" } : data[0]?.attributes;

	useTitle(Titulo ?? "");
	usePageTracking();

	const direccionFondo = loading
		? ""
		: `${process.env.REACT_APP_BASE_URL}${Fondo.data.attributes.url}`;

	const navigate = useNavigate();

	const navegarLibros = () => {
		navigate("/programas/PremioNacionalArtesanias/EdicionesLibros");
	};

	const navegarAfiches = () => {
		navigate("/programas/PremioNacionalArtesanias/Afiches");
	};

	const currentSize = useWindowsSize();

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<div className='contendor-general-inst'>
					<div
						className='imagen-fondo-mobile'
						style={{ backgroundImage: `url(${direccionFondo})` }}
					></div>
					<Container fluid='sm'>
						<div className='contenido-seccion'>
							<div className='links-container links-institucionales'>
								<Link to='/' className='link-component links-institucionales'>
									Inicio
								</Link>
								{" > "}
								<Link
									to='/programas/PremioNacionalArtesanias'
									className='link-component links-institucionales'
								>
									{Titulo}
								</Link>
							</div>

							<div>
								<h1 className='title-mobile titulo-institucional-mobile'>
									{Titulo}
								</h1>
								<CarrouselLabels
									listaLabel={[
										{
											Titulo: EdicionesLibrosLabel,
											Ruta: "/programas/PremioNacionalArtesanias/EdicionesLibros",
										},
										{
											Titulo: AfichesLabel,
											Ruta: "/programas/PremioNacionalArtesanias/Afiches",
										},
										...seccionesInstitucionales,
									]}
								/>
								<div className='wrapper institucional-row-mobile'>
									<div className='main-text-institucional'>
										<ReactMarkdown linkTarget='_blank'>
											{Contenido}
										</ReactMarkdown>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
			) : (
				<div className='contendor-general-inst'>
					<div
						className='imagen-fondo'
						style={{ backgroundImage: `url(${direccionFondo})` }}
					></div>

					<div className='contenido-seccion'>
						<Container
							className='links-container links-institucionales'
							fluid='sm'
						>
							<Link to='/' className='link-component links-institucionales'>
								Inicio
							</Link>
							{" > "}
							<Link
								to='/programas/PremioNacionalArtesanias'
								className='link-component links-institucionales'
							>
								{Titulo}
							</Link>
						</Container>

						<Container fluid='sm'>
							<h1 className='titulo-institucional'>{Titulo}</h1>
							<div className='wrapper'>
								<Col sm={3} className='lateral-menu'>
									<div className='lateral-items-institucional'>
										<span
											onClick={navegarLibros}
											className='lateral_intitucional_negro'
										>
											{EdicionesLibrosLabel}
										</span>
									</div>
									<div className='lateral-items-institucional'>
										<span
											onClick={navegarAfiches}
											className='lateral_intitucional_negro'
										>
											{AfichesLabel}
										</span>
									</div>
									<div className='mt-5'>
										{seccionesInstitucionales.map((seccion, i) => (
											<div className='lateral-items-institucional' key={i}>
												<span className='lateral_intitucional_blanco'>
													<Link className='enlace-lateral' to={seccion.Ruta}>
														{seccion.Titulo}
													</Link>
												</span>
											</div>
										))}
									</div>
								</Col>

								<Col sm={7} className='main-content'>
									<div className='main-text-institucional'>
										<ReactMarkdown linkTarget='_blank'>
											{Contenido}
										</ReactMarkdown>
									</div>
								</Col>
							</div>
						</Container>
					</div>
				</div>
			)}
		</>
	);
};
