import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import "./HeroSlider.css";
import { getCollectionData } from "../../helpers/getCollectionData";
import { useWindowsSize } from "../../hooks/useWindowsSize";
import { WindowSizeType } from "../../types/WindowSizeType";
import { HeroMobile } from "./Mobile/HeroMobile";
import { HeroDesktop } from "./Desktop/HeroDesktop";

export const HeroSlider = () => {
	const navigate = useNavigate();

	const [destacadasState, setDestacadasState] = useState([]);
	const estadoIdioma = useSelector((state) => state.idioma);

	const currentSize = useWindowsSize();

	const [max, setMax] = useState(1);

	useEffect(() => {
		let isMounted = true;

		const qs = require("qs");

		const query = qs.stringify({
			populate: [
				"destacada.Portada",
				"destacada.PortadaMobile",
				"destacada.historia",
				"destacada.noticia",
				"destacada.artesano",
			],
			pagination: {
				page: 1,
				pageSize: 1,
			},
		});

		if (isMounted) {
			const obtenerDestacadas = async () => {
				const destacadas = (
					await getCollectionData("destacadas", query, estadoIdioma)
				).data;

				if (
					destacadas.length > 0 &&
					destacadas[0].attributes.destacada.length > 0
				) {
					const destacadasFiltradas = destacadas[0].attributes.destacada.slice(
						0,
						5
					);
					setDestacadasState(destacadasFiltradas);
					setMax(destacadasFiltradas.length - 1);
				} else {
					setDestacadasState([
						{
							demo: true,
							Portada: {
								data: {
									attributes: { url: "/imgs/hero_desktop_expo-pna23.jpg" },
								},
							},
							PortadaMobile: {
								data: {
									attributes: { url: "/imgs/expo-pna23.jpg" },
								},
							},
							Titulo: "EXPOSICIÓN DEL PREMIO NACIONAL DE ARTESANÍA 2023",
							Subtitulo: "21 de noviembre 2023 - 16 de febrero 2024",
							noticia: { data: { id: 77 } },
						},
					]);
					setMax(0);
				}
			};

			obtenerDestacadas();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	const getTipoId = (destacada) => {
		if (destacada.historia.data != null) {
			return {
				collection: "historias",
				id: destacada.historia.data.id,
			};
		} else if (destacada.artesano.data != null) {
			return {
				collection: "artesanos",
				id: destacada.artesano.data.id,
			};
		} else if (destacada.noticia.data != null) {
			return {
				collection: "noticias",
				id: destacada.noticia.data.id,
			};
		} else {
			return null;
		}
	};

	const navegarDestacada = (destacada) => {
		let tipoId = getTipoId(destacada);
		tipoId === null
			? console.log("La destacada no tiene un item asociado")
			: navigate(`/${tipoId.collection}/${tipoId.id}`);
	};

	/* Custom Controls for Carousel */
	const [index, setIndex] = useState(0);
	const min = 0;
	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	const onPrevClick = () => {
		index === min ? handleSelect(max) : handleSelect(index - 1);
	};
	const onNextClick = () => {
		index === max ? handleSelect(0) : handleSelect(index + 1);
	};

	return (
		<>
			{currentSize === WindowSizeType.xs ? (
				<>
					<HeroMobile
						ActiveIndex={index}
						destacadasState={destacadasState}
						handleSelect={handleSelect}
						onNextClick={onNextClick}
						onPrevClick={onPrevClick}
						navegarDestacada={navegarDestacada}
					/>
				</>
			) : (
				<HeroDesktop
					ActiveIndex={index}
					destacadasState={destacadasState}
					handleSelect={handleSelect}
					onNextClick={onNextClick}
					onPrevClick={onPrevClick}
					navegarDestacada={navegarDestacada}
				/>
			)}
		</>
	);
};
