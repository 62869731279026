import React, { useEffect, useMemo, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { LateralMenu } from "./LateralMenu/LateralMenu";
import { ordenarArrayLibros } from "../../../../helpers/ordenarArray";
import { getCollectionData } from "../../../../helpers/getCollectionData";
import { Paginacion } from "../../../shared/Paginacion/Paginacion";
import { ListaAfiches } from "./ListaAfiches";
import { aleatorizarArray } from "../../../../helpers/aleatorizarArray";
import { useWindowsSize } from "../../../../hooks/useWindowsSize";
import { WindowSizeType } from "../../../../types/WindowSizeType";
import { ordenamientos } from "../../../../types/ordenamientos";
import { useTitle } from "../../../../hooks/useTitle";
import usePageTracking from "../../../../hooks/usePageTracking";
import { CategoriasSeleccionadoContext } from "./AfichesContext";

export const Afiches = () => {
	const currentSize = useWindowsSize();
	const cantidadRegistros = useMemo(
		() => (currentSize === WindowSizeType.xs ? 8 : 9),
		[currentSize]
	);

	const [afichesState, setAfichesState] = useState([]);
	const [ordenamientoSeleccionado, setOrdenamientoSeleccionado] = useState(
		ordenamientos.MasReciente
	);
	const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState([]);
	const [paginaActual, setPaginaActual] = useState(1);
	const [cantidadAfichesAMostrar, setCantidadAfichesAMostrar] =
		useState(cantidadRegistros);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [mostrarMasActivado, setMostrarMasActivado] = useState(false);
	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		OrdenLabel: "",
		EdicionesLibrosLabel: "",
		PremioNacionalArtesania: "",
		MostrarMasLabel: "",
	});

	useTitle(datosPagina.TituloLabel);
	usePageTracking();

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const {
					TituloLabel,
					OrdenLabel,
					EdicionesLibrosLabel,
					PremioNacionalArtesania,
					MostrarMasLabel,
					TextoResumen,
				} = (await getCollectionData("pagina-afiches", "", estadoIdioma, 1))
					.data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					OrdenLabel: OrdenLabel,
					EdicionesLibrosLabel: EdicionesLibrosLabel,
					PremioNacionalArtesania: PremioNacionalArtesania,
					MostrarMasLabel: MostrarMasLabel,
					TextoResumen: TextoResumen,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté

		const obtenerAfiches = async () => {
			const ordenamiento = ordenarArrayLibros(ordenamientoSeleccionado);
			const qs = require("qs");

			const query = qs.stringify({
				sort: [ordenamiento],
				filters: {
					categorias_afiche: {
						id: {
							$in: categoriasSeleccionadas,
						},
					},
				},
				pagination: {
					page: paginaActual,
					pageSize: cantidadAfichesAMostrar,
				},
				populate: "Portada",
				fields: ["locale", "Titulo"],
			});
			const { data, paginas } = await getCollectionData(
				"afiches",
				query,
				estadoIdioma
			);

			if (ordenamientoSeleccionado === ordenamientos.Aleatorio)
				setAfichesState(aleatorizarArray(data));
			else setAfichesState(data);

			if (!mostrarMasActivado) setCantidadPaginas(paginas);
		};
		obtenerAfiches();
	}, [
		ordenamientoSeleccionado,
		paginaActual,
		cantidadAfichesAMostrar,
		estadoIdioma,
		categoriasSeleccionadas,
		mostrarMasActivado,
	]);

	const mostrarMasResultados = () => {
		setCantidadAfichesAMostrar(cantidadAfichesAMostrar + 9);
		setMostrarMasActivado(true);
	};

	const cambiarPaginaActual = (pagina) => {
		if (mostrarMasActivado) {
			setCantidadAfichesAMostrar(9);
			setMostrarMasActivado(false);
		}
		setPaginaActual(pagina);
	};

	return (
		<CategoriasSeleccionadoContext.Provider
			value={{
				categoriasSeleccionadas,
				setCategoriasSeleccionadas,
			}}
		>
			<div className='contenedor-general-libros'>
				<Container fluid='sm'>
					<div>
						<Link to='/' className='link-component '>
							Inicio
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias'
							className='link-component'
						>
							{currentSize === WindowSizeType.xs
								? "Premio Nac..."
								: "Premio Nacional de Artesanía"}
						</Link>
						{" > "}
						<Link
							to='/programas/PremioNacionalArtesanias/Afiches'
							className='link-component'
						>
							{datosPagina.TituloLabel}
						</Link>
					</div>
				</Container>

				{currentSize === WindowSizeType.xs ? (
					<>
						<Container fluid='sm'>
							<h2 className='title-mobile'>{datosPagina.TituloLabel}</h2>
							<p className='p-unstyled my-4 texto-resumen-afiche'>
								{datosPagina.TextoResumen}
							</p>
							<hr className='hr-main-layout' />

							<LateralMenu
								setOrdenamiento={setOrdenamientoSeleccionado}
								subtitulo={datosPagina.OrdenLabel}
								EdicionesLibrosLabel={datosPagina.EdicionesLibrosLabel}
								PremioNacionalArtesania={datosPagina.PremioNacionalArtesania}
								tituloDropdown={datosPagina.TituloLabel}
								ordenamientoSeleccionado={ordenamientoSeleccionado}
							/>

							<ListaAfiches afiches={afichesState} />
						</Container>

						<Paginacion
							cantidadPaginas={cantidadPaginas}
							mostrarMasResultados={mostrarMasResultados}
							setPaginaActual={cambiarPaginaActual}
							paginaActual={paginaActual}
							mostrarMasLabel={datosPagina.MostrarMasLabel}
						/>
					</>
				) : (
					<>
						<Container fluid='sm'>
							<h2 className='title'>{datosPagina.TituloLabel}</h2>
							<div className=' wrapper contenedor-libros'>
								<Col sm={3} className='lateral-menu'>
									<LateralMenu
										setOrdenamiento={setOrdenamientoSeleccionado}
										subtitulo={datosPagina.OrdenLabel}
										EdicionesLibrosLabel={datosPagina.EdicionesLibrosLabel}
										PremioNacionalArtesania={
											datosPagina.PremioNacionalArtesania
										}
										ordenamientoSeleccionado={ordenamientoSeleccionado}
									/>
								</Col>

								<Col sm={9} className='main-content'>
									<p className='col-9 p-unstyled mb-4 texto-resumen-afiche'>
										{datosPagina.TextoResumen}
									</p>
									<ListaAfiches afiches={afichesState} />
								</Col>
							</div>
							<Paginacion
								cantidadPaginas={cantidadPaginas}
								mostrarMasResultados={mostrarMasResultados}
								setPaginaActual={cambiarPaginaActual}
								paginaActual={paginaActual}
								mostrarMasLabel={datosPagina.MostrarMasLabel}
							/>
						</Container>
					</>
				)}
			</div>
		</CategoriasSeleccionadoContext.Provider>
	);
};
