import React, { useState, useMemo, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "../Results.css";
import { obtenerResultadosDeBusqueda } from "./helpers/obtenerResultadosDeBusqueda";
import { Paginacion } from "../../shared/Paginacion/Paginacion";
import { ListaResultados } from "./ListaResultados";
import { getCollectionData } from "../../../helpers/getCollectionData";
import { useTitle } from "../../../hooks/useTitle";
import usePageTracking from "../../../hooks/usePageTracking";

export const BusquedaCategoriasHome = () => {
	const { busqueda } = useParams();

	useTitle(`Busqueda - ${busqueda}`);
	usePageTracking();

	const [artesanos, setArtesanos] = useState([]);
	const [historias, setHistorias] = useState([]);
	const [noticias, setNoticias] = useState([]);

	const [cantidadResultados, setcantidadResultados] = useState(5);
	const [cantidadPaginas, setCantidadPaginas] = useState();
	const [paginaActual, setPaginaActual] = useState(1);

	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		TituloLabel: "",
		MostrarMasLabel: "",
	});

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			const obtenerDatos = async () => {
				const { TituloLabel, MostrarMasLabel } = (
					await getCollectionData(
						"pagina-resultados-busquedas",
						"",
						estadoIdioma,
						1
					)
				).data[0].attributes;

				setDatosPagina({
					TituloLabel: TituloLabel,
					MostrarMasLabel: MostrarMasLabel,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	useMemo(() => {
		//Creamos una flag que nos permite controlar si el componente está montado o no, para no hacer la consulta fetch cuando no lo esté
		const obtenerResultados = async () => {
			const { artesanosList, historiasList, noticiasList, pages } =
				await obtenerResultadosDeBusqueda(
					busqueda,
					estadoIdioma,
					cantidadResultados
				);

			setArtesanos(artesanosList);
			setHistorias(historiasList);
			setNoticias(noticiasList);
			setCantidadPaginas(pages);
		};
		obtenerResultados();
	}, [busqueda, cantidadResultados, estadoIdioma]);

	const mostrarMasResultados = () => {
		setcantidadResultados(cantidadResultados + 5);
	};

	const cambiarPaginaActual = (pagina) => {
		setcantidadResultados(5);
		setPaginaActual(pagina);
		window.scrollTo(0, 0);
	};

	const procesarTextoBusqueda = (texto = "") => {
		return texto.length > 20 ? texto.substring(0, 20).concat("...") : texto;
	};

	return (
		<>
			<Container fluid='sm' className='links-container'>
				<Link to='/' className='link-component'>
					Inicio
				</Link>
				{" > "}
				<Link
					to={`/resultadosPorCategoria/${busqueda}`}
					className='link-component'
				>
					Resultados
				</Link>{" "}
				{" > "}
				<Link
					to={`/resultadosPorCategoria/${busqueda}`}
					className='link-component'
				>
					"{procesarTextoBusqueda(busqueda)}"
				</Link>
			</Container>
			<Container fluid='sm'>
				<div className='title'>
					<span>{datosPagina.TituloLabel}</span>
				</div>
				<hr className='hr-main-layout' />

				<div className='results'>
					<ListaResultados
						artesanosList={artesanos}
						historiasList={historias}
						noticiasList={noticias}
						cantidadResultados={cantidadResultados}
						pagina={paginaActual}
					/>
				</div>
			</Container>

			<Paginacion
				mostrarMasResultados={mostrarMasResultados}
				setPaginaActual={cambiarPaginaActual}
				paginaActual={paginaActual}
				cantidadPaginas={cantidadPaginas}
				mostrarMasLabel={datosPagina.MostrarMasLabel}
			/>
		</>
	);
};
