import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./Page404.css";
import { getCollectionData } from "../../../helpers/getCollectionData";
import usePageTracking from "../../../hooks/usePageTracking";
import { useTitle } from "../../../hooks/useTitle";

export const Page404 = () => {
	const navigate = useNavigate();
	const handleVolverHome = () => {
		navigate("/");
	};

	const estadoIdioma = useSelector((state) => state.idioma);
	const [datosPagina, setDatosPagina] = useState({
		DescripcionLabel: "",
		VolverHomeLabel: "",
		Fondo: "",
		Texto404: "",
	});

	useTitle("404");
	usePageTracking();

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const obtenerDatos = async () => {
				const qs = require("qs");

				const query = qs.stringify({
					populate: ["Fondo"],
				});
				const { DescripcionLabel, VolverHomeLabel, Fondo, Texto404 } = (
					await getCollectionData("pagina404s", query, estadoIdioma, 1)
				).data[0].attributes;

				setDatosPagina({
					DescripcionLabel: DescripcionLabel,
					VolverHomeLabel: VolverHomeLabel,
					Fondo: Fondo,
					Texto404: Texto404,
				});
			};
			obtenerDatos();
		}
		return () => (isMounted = false);
	}, [estadoIdioma]);

	return (
		<div>
			<div className='error-404'>
				<img
					className='fondo-error w-100 h-100'
					src={`${process.env.REACT_APP_BASE_URL}${datosPagina.Fondo.data?.attributes.url}`}
					alt='404'
				></img>
			</div>
			<div className='contenedor-texto-404'>
				<h1 className='texto404'>{datosPagina.Texto404}</h1>
				<div className='contenedor-parrafo-error'>
					<p className='texto-error-404'>{datosPagina.DescripcionLabel}</p>
				</div>
				<div className='contenedor-boton-volver'>
					<button className='boton-volver-home' onClick={handleVolverHome}>
						{datosPagina.VolverHomeLabel}
					</button>
				</div>
			</div>
		</div>
	);
};
