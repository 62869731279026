import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import "./RadioMenu.css";

export const RadioButtonDropdown = ({
	items = [],
	title = "",
	handleInputChange,
	currentValue,
}) => {
	const handleChecked = (e, value) => {
		e.stopPropagation();
		handleInputChange(e, value);
	};

	const [flechaDropdown, setFlechaDropdown] = useState(false);

	return (
		<Dropdown onToggle={(nextToggle, e) => setFlechaDropdown(nextToggle)}>
			<Dropdown.Toggle className='boton-dropdown-mobile col'>
				<div>
					<span>{title}</span>
					{flechaDropdown ? (
						<span id='programasIcon' className='flecha-algo'>
							^
						</span>
					) : (
						<span id='programasIcon' className='arrow-flip-drop flecha-algo'>
							^
						</span>
					)}
				</div>
			</Dropdown.Toggle>

			<Dropdown.Menu as={RadioButtonMenu}>
				{items.map((value) => (
					<Dropdown.Item
						key={value + value.id}
						as={RadioButtonItem}
						id={value.id}
						onClick={(e) => handleChecked(e, value)}
						onChange={() => null}
						checked={currentValue === value}
					>
						{value}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

const RadioButtonMenu = React.forwardRef(
	({ children, style, className }, ref) => {
		return (
			<div ref={ref} style={style} className={`${className} RadioButtonMenu`}>
				<div
					className='d-flex flex-column'
					style={{ maxHeight: "calc(100vh)", overflow: "none" }}
				>
					<ul
						className='list-unstyled flex-shrink mb-0'
						style={{ overflow: "auto" }}
					>
						{children}
					</ul>
				</div>
			</div>
		);
	}
);

const RadioButtonItem = React.forwardRef(
	({ children, id, onClick, checked }, ref) => {
		return (
			<Form.Group
				ref={ref}
				className='dropdown-item mb-0 texto-radio-mobile boton-radio-mobile'
				controlId={id}
				onClick={onClick}
			>
				<input
					type='radio'
					name='orden'
					style={{ backgroundColor: "#333" }}
					checked={checked}
					onChange={() => null}
				/>
				<label className='input-label'>{children}</label>
			</Form.Group>
		);
	}
);
