import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetchCategoriasArtesanosConCantidad = () => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });
  const base_url = `${process.env.REACT_APP_BASE_URL}`;
  const qs = require("qs");
  const query = qs.stringify({
    populate: ["artesanos"],
  });

  const idiomaState = useSelector((state) => state.idioma);

  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      const urlCompleta = `${base_url}/api/categorias-artesanos?${query}&locale=${idiomaState.code}`;
      const resp = await fetch(urlCompleta);
      const dataRest = await resp.json();
      setState({ data: dataRest.data, loading: false });
    }

    if (isActive) {
      fetchData()
    }
    return () => (isActive = false);
  }, [idiomaState, query, base_url]);

  return state;
};
