import { useEffect } from "react"

export const useTitle = (title) => {
    useEffect(() => {
        const prevTitle = document.title;
        if (title !== "" && prevTitle !== ("Artesania Uruguay - " + title)) {
            document.title = "Artesania Uruguay - " + title;
        }

    }, [title])
}