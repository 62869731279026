import React from "react";

import { ArtesanoItem } from "./ArtesanoItem";

export const ListaArtesanos = ({ Artesanos = [] }) => {
	return (
		<div className='row'>
			{Artesanos.map((artesano, i) => (
				<ArtesanoItem
					artesano={artesano}
					key={i}
					horizontalStyle='col-lg-8 col-md-12 col-sm-12 col-12'
					verticalStyle='col-lg-4 col-md-6 col-sm-6 col-6'
				/>
			))}
		</div>
	);
};
